import React, { useState } from "react";
import themeConfig from "../../configs/themeConfig";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Footer from "./components/shared/footer/Footer";
import NavigationMain from "./components/navigation/NavigationMain";
import AppBarLayout from "./components/appbar/AppBar";
import { useEffect } from "react";

//** Styling
const DefaultLayoutWrapper = styled("div")({
  height: "100vh",
  display: "flex",
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const DefaultLayout = (props) => {
  const { settings, children, scrollToTop } = props;
  const { contentWidth } = settings;
  const navWidth = themeConfig.navigationSize;

  const [navVisible, setNavVisible] = useState(false);

  const toggleNavVisibility = () => setNavVisible(!navVisible);

  useEffect(() => {
    console.log("Nav width ", navWidth);
  }, []);

  return (
    <>
      <DefaultLayoutWrapper className="layout-wrapper">
        <NavigationMain
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          toggleNavVisibility={toggleNavVisibility}
          {...props}
        />
        <MainContentWrapper className="layout-content-wrapper">
          <AppBarLayout toggleNavVisibility={toggleNavVisibility} {...props} />
          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === "boxed" && {
                mx: "auto",
                "@media (min-width:1440px)": { maxWidth: 1440 },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              }),
            }}
          >
            {children}
          </ContentWrapper>
          <Footer {...props} />
        </MainContentWrapper>
      </DefaultLayoutWrapper>

      {/* <DefaultLayoutWrapper className="layout-wrapper">
        <NavigationMain
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          toggleNavVisibility={toggleNavVisibility}
          {...props}
        />
        <MainContentWrapper className="layout-content-wrapper">
          <AppBarLayout toggleNavVisibility={toggleNavVisibility} {...props} />
          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === "boxed" && {
                mx: "auto",
                "@media (min-width:1440px)": { maxWidth: 1440 },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              }),
            }}
          >
            {children}
          </ContentWrapper>
          <Footer {...props} />
        </MainContentWrapper>
      </DefaultLayoutWrapper> */}
    </>
  );
};

export default DefaultLayout;
