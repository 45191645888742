import {
  Avatar,
  Badge,
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { CogOutline } from "mdi-material-ui";
import { styled } from "@mui/material/styles";
import AccountOutline from "mdi-material-ui/AccountOutline";
import LogoutVariant from "mdi-material-ui/LogoutVariant";
import React, { Fragment, useEffect, useState } from "react";
import useLogOut from "../../../../../hooks/useLogOut";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiPackage, mdiAccount, mdiLogout } from "@mdi/js";
import useNextPageRedirect, {
  USER_PROFILE_UPDATE,
  VIEW_MY_ORDERS,
} from "../../../../../util/AppUrls";
import { useSessionStateValue } from "../../../../../context/SessionContext";

const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props) => {
  const logout = useLogOut();

  const [{ user }, dispatch] = useSessionStateValue();
  const [anchorEl, setAnchorEl] = useState(null);
  const redirect = useNextPageRedirect();

  // ** Hooks
  //const router = useRouter()

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      //router.push(url)
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      fontSize: "1.375rem",
      color: "text.secondary",
    },
  };

  const userLogOut = () => redirect("LOGOUT");

  return (
    <Fragment>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: "pointer" }}
        // badgeContent={<BadgeContentSpan />}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          alt={user?.firstName}
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src={user?.profile || "/images/avatars/1.png"}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, marginTop: 4 } }}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              // badgeContent={<BadgeContentSpan />}
              // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar
                alt={user?.firstName}
                src={user?.profile || "/images/avatars/1.png"}
                sx={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Badge>
            <Box
              sx={{
                display: "flex",
                marginLeft: 3,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {user?.firstName + " " + user?.lastName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.8rem", color: "text.disabled" }}
              ></Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Link to={USER_PROFILE_UPDATE}>
            <Box sx={styles}>
              <Icon path={mdiAccount} size={1} />
              &nbsp; Profile
            </Box>
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Link to={VIEW_MY_ORDERS}>
            <Box sx={styles}>
              <Icon path={mdiPackage} size={1} /> &nbsp; Orders
            </Box>
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={userLogOut}>
          <Icon path={mdiLogout} size={1} />
          &nbsp; Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
