import { Button, Grid } from '@mui/material'
import {React, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import OrdersList from '../../../components/order/OrdersList'
import useAxiosProtected from '../../../hooks/useAxiosProtected'
import { FETCH_ORDERS_LIST } from '../../../util/ApiUrls'
import { BROWSE_PLANS } from '../../../util/AppUrls'

const MyOrders = () => {
  const [orders, setOrders] = useState([])
  const axiosProtected = useAxiosProtected()


  const loadOrders = async () => {
    try {
      await axiosProtected.get(
        FETCH_ORDERS_LIST,
      ).then(response => {
        console.log(response)
        setOrders(response?.data)
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    loadOrders()
  }, [])
  return (
    <Grid className='content-center'>
        <Grid container marginBottom={5} direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item direction={'column'}>
            <h2>My Orders</h2>
          </Grid>
          <Grid item>
            <Link to={BROWSE_PLANS} >
              <Button size="small" sx={{ mr: 5 }}>Browse More Plans</Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <OrdersList orders={orders} />
          </Grid>
        </Grid>
      </Grid>
  )
}

export default MyOrders