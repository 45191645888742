import React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Logo from "../../../../components/Logo";

const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: theme.spacing(4.5),
  transition: "padding .25s ease-in-out",
  minHeight: theme.mixins.toolbar.minHeight,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
}));

const StyledLink = styled("a")({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const NavHeader = (props) => {
  const { navMenuBranding: userNavMenuBranding } = props;
  const theme = useTheme();

  return (
    <MenuHeaderWrapper
      className="nav-header"
      sx={{ pl: 6, display: "flex", justifyContent: "center" }}
    >
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <Logo width="75" height="75" />
              {/* <Typography
                variant="h6"
                sx={{
                  nl: 3,
                  lineHeight: 1,
                  fontWeight: 600,
                  fontSize: "1.5rem !important",
                }}
              >
                MYRX
              </Typography> */}
            </Box>
          </Grid>
        </>
      )}
    </MenuHeaderWrapper>
  );
};

export default NavHeader;
