import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Icon from "@mdi/react";
import {
  mdiPrescription,
  mdiEmoticonSickOutline,
  mdiStethoscope,
  mdiDoctor,
  mdiPillMultiple,
  mdiClipboardPulseOutline,
  mdiNeedle,
} from "@mdi/js";

const WizardHeader = styled("Grid")({
  padding: 15,
  flexGrow: 1,
  textAlign: "center",
  borderBottom: "5px solid grey",
  marginRight: 5,
  marginBottom: 5,
  minWidth: 120,
  maxWidth: 180,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

const active = {
  borderBottom: "8px solid #FF3158",
  color: "#FF3158",
};
const PrescriptionLayout = (props) => {
  const { children, currentStep, config, modules } = props;
  return (
    <>
      <Grid container spacing={2} justifyContent={"left"} sx={{ py: 5 }}>
        {config?.tabs?.map((configModule, index) => {
          return modules?.map((module, index) => {
            if (module.value == configModule.tab) {
              return (
                <WizardHeader
                  item
                  key={index}
                  style={currentStep === index ? active : {}}
                  onClick={() => props.changeStep(index)}
                >
                  {module.icon}
                  {module.title}
                </WizardHeader>
              );
            }
          });
        })}
      </Grid>
      <Grid container justifyContent={"center"} alignItems="center">
        {children}
      </Grid>
    </>
  );
};

export default PrescriptionLayout;
