import React, { useContext } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
// ** Icons Imports
import Menu from "mdi-material-ui/Menu";
import Magnify from "mdi-material-ui/Magnify";
import { CartContext } from "../../context/ShoppingCartProvider";
import { Link } from "react-router-dom";
import { SHOPPING_CART } from "../../util/AppUrls";
import UserDropdown from "../../core/layouts/components/shared/user/UserDropdown";
import AppCart from "../../core/layouts/components/shared/AppCart";
import Logo from "../../components/Logo";

const AppBarContent = (props) => {
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  const hiddenSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const cart = useContext(CartContext);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        {hidden ? (
          <>
            <IconButton
              color="inherit"
              onClick={toggleNavVisibility}
              sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
            >
              <Menu />
            </IconButton>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Logo width="75" height="75" /> &nbsp;
                {/* <Typography
                            variant='h6'
                            sx={{ nl: 3, lineHeight: 1, fontWeight: 600, fontSize: '1.5rem !important' }}>
                            MyRx
                        </Typography> */}
              </Box>
            </Grid>
          </>
        ) : null}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <AppCart cart={cart} />
        <UserDropdown />
      </Box>
    </Box>
  );
};

export default AppBarContent;
