import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const LanguageSelector = (props) => {
  const t = (mesage) => mesage;
  const [language, setLanguage] = React.useState("");
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    setLanguage(event.target.value);
    changeLanguageHandler(event.target.value);
  };

  const changeLanguageHandler = (lang) => {
    // i18n.changeLanguage("de");
  };
  return (
    <FormControl>
      <InputLabel id="translation-language-select-label">Language</InputLabel>
      <Select
        labelId="translation-language-select-label"
        id="translation-language-select"
        value={i18n.language}
        label="Language"
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        size="small"
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"hi"}>Hindi</MenuItem>
        <MenuItem value={"ar"}>Arabic</MenuItem>
        <MenuItem value={"be"}>Bengali</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
