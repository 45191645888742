import { Button, Grid, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect } from "react";
import { useState } from "react";
import { createCountDown } from "../../util/CommonUtils";

const AAdharOTPInput = ({ handleOtpVerify }) => {
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState("");
  const [countdownMinute, setCountdownMinute] = useState(1.3);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [countdownCompleted, setCountdownCompleted] = useState(false);
  const [otpSent, setOtpSent] = useState(true);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const startCountdown = () => {
    counter && clearInterval(counter) && setCounter(undefined);
    const min = Math.floor(countdownMinute);
    const secs = Math.floor((countdownMinute % 1) * 100);
    setCounter(createCountDown(min, secs, setMinutes, setSeconds));
  };

  useEffect(() => {
    minutes === 0 && seconds === 0 && setCountdownCompleted(true);
  }, [minutes, seconds]);

  useEffect(() => {
    otpSent && startCountdown();
  }, [otpSent]);
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body">
          We have sent a verification OTP on mobile number <b></b>
          <br />
        </Typography>
        <Typography variant="body" sx={{ mb: 5 }}>
          Please enter the OTP below
        </Typography>
        <MuiOtpInput
          value={otp}
          gap={2}
          onChange={handleChange}
          onComplete={handleOtpVerify}
          length={6}
          TextFieldsProps={{ disabled: false, placeholder: "" }}
        />
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          justifyContent={"center"}
          sx={{ mt: 10 }}
        >
          {minutes === 0 && seconds === 0 ? (
            <Button fullWidth variant="contained">
              Resend OTP
            </Button>
          ) : (
            <Button onClick={startCountdown} fullWidth variant="outlined">
              Wait {minutes < 10 ? "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds} for resend otp
            </Button>
          )}
          {/* <Button onClick={sendOTP} fullWidth variant="outlined">
            SEND OTP
          </Button> */}
        </Grid>
      </Grid>
    </>
  );
};

export default AAdharOTPInput;
