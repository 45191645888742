export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = matchIsString(text);
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

//can check for string
export const checkIfNumeric = (text) => {
  const isString = matchIsString(text);
  return text !== "" && !isNaN(Number(text));
};

export function matchIsString(text) {
  return text.match(/[a-z]/i);
}

export const createCountDown = (min, seconds, minSetter, secSetter) => {
  const count = setInterval(() => {
    if (parseInt(min) < 0) {
      clearInterval(count);
    } else {
      minSetter(Math.floor(min));
      secSetter(Math.floor(seconds));
      if (seconds == 0) {
        min--;
        seconds = 59;
      }
      seconds--;
    }
  }, 1000);
  return count;
};

export const secondsCountdown = (seconds, secSetter) => {
  const count = setInterval(() => {
    if (seconds < 0) {
      clearInterval(count);
    } else {
      secSetter(seconds--);
    }
  }, 1000);
  return count;
};


export const processFullName =(fullName)=>{ 
  const names = fullName.split(" ");
  const vet = name => name ? name : "";
  return {
      first:  vet(names.shift()),
      last:   vet(names.pop()),
      middle: vet(names.join(" "))
  };
}