import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Icon from "@mdi/react";
import { mdiCloseThick } from "@mdi/js";
import ButtonClose from "../button/ButtonClose";

const OptionsCreater = (props) => {
  const { addOptions } = props;
  const [options, setOptions] = useState(props?.options || []);
  const [newOption, setNewOption] = useState();
  const removeOption = (element) => {
    setOptions((current) => current.filter((el) => el !== element));
  };
  const addNewOption = () => {
    if (newOption) setOptions((prev) => [...prev, newOption]);
    setNewOption("");
  };
  useEffect(() => {
    addOptions(options);
  }, [options]);
  return (
    <Grid>
      <Grid container alignItems={"center"}>
        <DialogContentText sx={{ mb: 2 }}>
          Input some options here and click on Add options
        </DialogContentText>
        <FormControl>
          <TextField
            label="Write new option"
            size="small"
            onChange={(e) => setNewOption(e?.target?.value)}
            value={newOption}
          />
        </FormControl>
        <FormControl>
          <Button onClick={addNewOption}>Add Option</Button>
        </FormControl>
      </Grid>
      {options.length > 0 && (
        <DialogContentText sx={{ mb: 2 }}>Your Added options</DialogContentText>
      )}
      {options.map((element, index) => {
        return (
          <Grid container alignItems={"center"} sx={{ mb: 2 }}>
            <ButtonClose
              label={element}
              key={index}
              onRemoveElement={() => removeOption(element)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const VitalFieldAdd = (props) => {
  const { addFormFieldDetail, model, dialogOpened, setDialogOpened } = props;

  const [isNumeric, setIsNumeric] = useState(false);
  const [showOptionsAdd, setShowOptionsAdd] = useState(false);
  const [values, setValues] = useState();

  const handleChanges = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChecked = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleAddOptions = (options) => {
    setValues({ ...values, ["options"]: options });
  };

  const handleInputTypeChange = (e) => {
    const selected = e?.target?.value;
    if (selected == "number") {
      setIsNumeric(true);
      setShowOptionsAdd(false);
    } else if (
      selected == "dropdown" ||
      selected == "radio" ||
      selected == "checkbox"
    ) {
      setIsNumeric(false);
      setShowOptionsAdd(true);
    } else {
      setIsNumeric(false);
      setShowOptionsAdd(false);
    }
    setValues({ ...values, ["inputtype"]: selected });
  };

  const fetchDialogResponse = () => {
    //creating a new vital
    const vital = {
      name: values?.name,
      value: "custom",
      selected: true,
      type: values?.inputtype,
      min: -100,
      max: 300,
      precision: 0.01,
      options: values?.options,
      unit: {
        type: "label",
        value: values?.unit,
      },
    };
    addFormFieldDetail(vital);
    setValues({});
  };

  return (
    <Grid container>
      <Dialog open={dialogOpened} onClose={() => setDialogOpened(false)}>
        <DialogTitle>Let's add a new vital</DialogTitle>
        <DialogContent>
          <Divider sx={{ mb: 5 }} />
          <Grid container spacing={2} flexDirection="column">
            <FormControl fullWidth sx={{ mb: 5 }}>
              <DialogContentText sx={{ mb: 2 }} sx={{ mb: 2 }}>
                Write the name for vital you want to add
              </DialogContentText>
              <TextField
                labelId="vital-name-input"
                label="Vital Name"
                defaultValue={model?.name}
                onChange={handleChanges("name")}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <DialogContentText sx={{ mb: 2 }}>
                Write the unit for vital e.g. Kg for Weight
              </DialogContentText>
              <TextField
                labelId="vital-unit-input"
                label="Vital Unit"
                defaultValue={model?.unit?.value}
                onChange={handleChanges("unit")}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <DialogContentText sx={{ mb: 2 }}>
                Select the type of input you want to show on prescription writer
                for the Vital
              </DialogContentText>
              <InputLabel id="vital-input-type-label"></InputLabel>
              <Select
                id="vital-input-type"
                labelId="vital-input-type-label"
                label={"Vital Input Type"}
                defaultValue={model?.type}
                onChange={handleInputTypeChange}
              >
                <MenuItem value={"text"}>Text</MenuItem>
                <MenuItem value={"number"}>Number</MenuItem>
                <MenuItem value={"dropdown"}>Drop Down</MenuItem>
                <MenuItem value={"radio"}>Radio Buttons</MenuItem>
                <MenuItem value={"checkbox"}>CheckBoxes</MenuItem>
              </Select>
            </FormControl>
            {isNumeric && (
              <>
                <FormGroup sx={{ mb: 5 }}>
                  <DialogContentText sx={{ mb: 2 }}>
                    Select this button if you want to save the vital value in
                    decimal places
                  </DialogContentText>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Is double precision"
                    onChange={handleChecked("doubleprecision")}
                  />
                </FormGroup>
                <FormGroup sx={{ mb: 5 }}>
                  {/* <TextField label="Min" size="small" value={model?.min} />
                  <TextField label="Max" size="small" value={model?.max} /> */}
                </FormGroup>
              </>
            )}
            {showOptionsAdd && (
              <OptionsCreater
                options={model?.values}
                addOptions={handleAddOptions}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => fetchDialogResponse()}>Add Vital</Button>
          <Button onClick={() => setDialogOpened(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default VitalFieldAdd;
