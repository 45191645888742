import React from "react";
import { useEffect } from "react";
import useLogOut from "../../hooks/useLogOut";

const Logout = () => {
  const logout = useLogOut();
  const userLogOut = () => {
    logout();
  };
  useEffect(() => {
    userLogOut();
  }, []);
  return <div></div>;
};

export default Logout;
