import { deepmerge } from "@mui/utils"
import palette from './palette'
import spacing from './spacing/Spacing'
import Shadows from './shadow/Shadows'
import breakpoints from './breakpoint/breakpoints'
import MyRxPalette from "./palette/MyRxPalette"

const themeOptions = settings => {
    const { mode, themeColor } = settings

    const themeConfig = {
        // palette: palette(mode, themeColor),
        palette: MyRxPalette(mode, themeColor),
        typography: {
            fontFamily: [
                'Inter',
                'sans-serif',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(',')
        },
        shadows: Shadows(mode),
        ...spacing,
        breakpoints: breakpoints(),
        shape: {
            borderRadius: 6
        },
        mixins: {
            toolbar: {
                minHeight: 64
            }
        }
    }
    return deepmerge(themeConfig, {
        palette: {
          primary: {
            ...themeConfig.palette[themeColor]
          }
        }
      })
}
export default themeOptions