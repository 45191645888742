import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import React from 'react'

import themeOptions from './ThemeOptions'
import Overrides from './overrides'
import typography from './typography'
import themeConfig from '../../configs/themeConfig'
import { CssBaseline, GlobalStyles } from '@mui/material'
import GlobalStyling from './globalStyles'

const ThemeComponent = (props) => {
    const { settings, children } = props

    console.log('settings TC')
    console.log(settings)

    const coreThemeConfig = themeOptions(settings)

    let theme = createTheme(coreThemeConfig)

    theme = createTheme(theme, {
        components: { ...Overrides(theme) },
        typography: { ...typography(theme) }
    })

    if (themeConfig.responsiveFontSizes) {
        theme = responsiveFontSizes(theme)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={() => GlobalStyling(theme)} />
            {children}
        </ThemeProvider>
    )
}

export default ThemeComponent