import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AdviseConfig from "../AdviseConfig";

const AdviseConfigStep = (props) => {
  const [symptomOptions, setSymptomOptions] = useState([]);
  const _validate = () => {
    props.afterValid("CONFIG_DATA_ADVISE");
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader
              title="We have already done some configurations for you"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Grid container>
                <Grid item md={12} sx={{ mb: 3 }}>
                  <Typography variant="body2">
                    We have already done some config work for you. Review these
                    and click continue
                  </Typography>
                </Grid>
                <Grid container sx={{ mb: 3 }}></Grid>
                <Grid container sx={{ mb: 3 }}>
                  <Grid item lg={8} md={8} sm={6} xs={12} sx={{ mb: 3 }}>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, marginBottom: 1.5 }}
                    >
                      Advise
                    </Typography>
                  </Grid>
                  <Grid item md={12} sx={{ mb: 3 }}>
                    <AdviseConfig {...props} />
                  </Grid>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{ marginBottom: 7 }}
                onClick={_validate}
              >
                Continue
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AdviseConfigStep;
