import React from "react";
import {
  Link as MLink,
  Button,
  Alert,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useState } from "react";
import { validateAadhar } from "../../util/Verhoeff";
import { Link } from "react-router-dom";
import { checkIfNumeric } from "../../util/CommonUtils";
import { useEffect } from "react";

const AadharInput = (props) => {
  const { aadhar, setAadhar, submitAadhar } = props;
  const [aadharValidated, setAadharValidated] = useState(true);
  const [consent, setConsent] = useState(true);
  const [formValidated, setFormValidated] = useState();

  const t = (mesage) => mesage;

  const handleConsent = (event) => {
    const newValue = event.target.checked;
    setConsent(newValue);
  };

  const handleAadharInpChange = (event) => {
    const newValue = event.target.value;
    (checkIfNumeric(newValue) || !newValue) && setAadhar(newValue);
    setAadharValidated(validateAadhar(newValue));
  };

  const sendAadharOtp = (event) => {
    console.log("validation to start");
  };

  useEffect(() => {
    setFormValidated(
      aadhar && checkIfNumeric(aadhar) && aadharValidated && consent
    );
  }, [aadharValidated, consent]);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="caption" sx={{ mb: 10 }}>
        You can create your ABHA number using Aadhaar instantaneously. Please
        ensure that Aadhaar is linked to a Mobile Number as an OTP
        authentication will follow. If you do not have a Mobile Number linked,
        visit the nearest{" "}
        <Link to="https://facility.ndhm.gov.in/">
          ABDM participating facility
        </Link>{" "}
        and seek assistance.
      </Typography>
      <TextField
        error={!aadharValidated}
        value={aadhar}
        onChange={handleAadharInpChange}
        // onBlur={(event) => validateAadharNumber(event.target.value)}
        label="12 digit Aadhar"
        placeholder="Enter your 12 digit aadhar"
        sx={{ mb: 4 }}
        helperText={
          !aadharValidated && "Please enter correct 12 digit aadhar number"
        }
      />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={consent} onChange={handleConsent} />}
          label="I agree "
        />
      </FormGroup>
      <Grid maxHeight={150} sx={{ overflowY: "scroll", height: 150, mb: 4 }}>
        <Typography variant="caption">{t("abha.create.consent")}</Typography>
      </Grid>
      <Button
        variant="contained"
        disabled={!formValidated}
        onClick={submitAadhar}
      >
        Create ABHA Number
      </Button>
      {aadhar && checkIfNumeric(aadhar) && aadharValidated && consent}
    </Grid>
  );
};

export default AadharInput;
