import React from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";

import "../patient/MyQueries.scss";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import { useState } from "react";
import {
  GET_CHAT_HISTORY_MESSAGES,
  RECENT_CHATS_LIST,
  SEND_CHAT_MESSAGE,
} from "../../util/ApiUrls";
import { useEffect } from "react";
import { format } from "react-string-format";

const MyQueries2 = () => {
  const axiosProtected = useAxiosProtected();
  const [recentChats, setRecentChats] = useState([]);
  const [recentMessage, setRecentMessage] = useState([]);
  const [message, setMessage] = useState();
  const [selectedChat, setSelectedChat] = useState();

  const loadRecentChats = async () => {
    await axiosProtected
      .get(RECENT_CHATS_LIST)
      .then((result) => {
        setRecentChats(result?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openChatforUser = (selectedChatId) => {
    const selectedChatProfile = recentChats.filter(
      (chat) => chat?.chatId == selectedChatId
    );
    if (selectedChatProfile && selectedChatProfile[0]) {
      setSelectedChat(selectedChatProfile[0]);
      loadRecentMessages(selectedChatProfile[0]);
    }
  };

  const loadRecentMessages = async (selectedChatProfile) => {
    if (selectedChatProfile?.chatId) {
      await axiosProtected
        .get(format(GET_CHAT_HISTORY_MESSAGES, selectedChatProfile?.chatId))
        .then((result) => {
          setRecentMessage(result?.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const sendMessage = async () => {
    if (message) {
      const payload = {
        message: message,
        to: selectedChat?.chatId,
      };
      await axiosProtected
        .post(SEND_CHAT_MESSAGE, payload)
        .then((result) => {
          setMessage("");
          loadRecentMessages(selectedChat);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    loadRecentChats();
  }, []);

  return (
    <>
      <Grid className="content-center">
        <Grid
          container
          marginBottom={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item direction={"column"}>
            <h2>Queries</h2>
            <Typography></Typography>
          </Grid>
          <Grid container className="chat-outer-div">
            <Grid
              className="recent-chat-outer"
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
            >
              <aside>
                <header>
                  <input type="text" placeholder="search" />
                </header>
                <ul>
                  {recentChats.map((chat) => {
                    return (
                      chat?.name && (
                        <li
                          key={chat?.chatId}
                          onClick={() => openChatforUser(chat?.chatId)}
                        >
                          <img src={chat?.profile} alt="" />
                          <div>
                            <h2>{chat?.name}</h2>
                            <h3>
                              <span className="status orange"></span>
                              {/* offline */}
                            </h3>
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              </aside>
            </Grid>
            <Grid className="messages-outer" item xs={12} sm={6} md={8} lg={8}>
              <header>
                <img src={selectedChat && selectedChat?.profile} alt="" />
                <div>
                  <h2>Query with {selectedChat?.name}</h2>
                  {/* <h3>already 1902 messages</h3> */}
                </div>
                {/* <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_star.png"
                  alt=""
                /> */}
              </header>
              <ul id="chat">
                {recentMessage.toReversed().map((message, index) => {
                  const isSent = message?.to == selectedChat?.chatId;
                  return (
                    <li className={isSent ? "me" : "you"}>
                      <div className="entete">
                        <span className="status green"></span>
                        {/* <h2>Vincent</h2> */}
                        {/* <h3>10:12AM, Today</h3> */}
                      </div>
                      <div className="triangle"></div>
                      <div className="message">{message?.message}</div>
                    </li>
                  );
                })}
              </ul>
              <footer>
                <textarea
                  placeholder="Type your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {/* <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_picture.png"
                  alt=""
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_file.png"
                  alt=""
                /> */}
                <a href="#" onClick={sendMessage}>
                  Send
                </a>
              </footer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyQueries2;
