import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useSettings } from "../core/hooks/useSettings";

import DefaultLayout from "../core/layouts/DefaultLayout";
import navigationItems from "../navigation/Navigation";
import AppBarContent from "./components/AppBarContent";
import { Outlet } from "react-router-dom";
import SessionLayout from "./SessionLayout";
import useReloadSessionContext from "../hooks/useReloadSessionContext";
import { useSessionStateValue } from "../context/SessionContext";

const UserLayoutDefault = ({ children }) => {
  const { settings, saveSettings } = useSettings();

  const reloadSessionContext = useReloadSessionContext();

  const [{ user }, dispatch] = useSessionStateValue();

  //below will hide the sidebar to hamburger menu
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  //const hidden = true
  return (
    <SessionLayout>
      <DefaultLayout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
        navItems={navigationItems}
        appBarContent={(props) => (
          <AppBarContent
            hidden={hidden}
            settings={settings}
            saveSettings={saveSettings}
            toggleNavVisibility={props.toggleNavVisibility}
          />
        )}
      >
        <Outlet context={{ user, reloadSessionContext }} />
      </DefaultLayout>
    </SessionLayout>
  );
};

export default UserLayoutDefault;
