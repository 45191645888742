import axios from "axios";
//const BASE_URL = '/myrx'
const BASE_URL = process.env.REACT_APP_MYRX_CONTEXT;
const ABHA_BASE_URL = process.env.REACT_APP_ABHA_CONTEXT;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosProtected = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosABHA = axios.create({
  baseURL: ABHA_BASE_URL,
  withCredentials: false,
});
