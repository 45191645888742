import React from "react";
import useAxiosProtected from "./useAxiosProtected";
import { useSessionStateValue } from "../context/SessionContext";
import { GET_USER_PROFILE } from "../util/ApiUrls";
import { useSettings } from "../core/hooks/useSettings";

const useReloadSessionContext = () => {
  const axiosProtected = useAxiosProtected();
  const [, dispatch] = useSessionStateValue();
    const { settings, saveSettings, setIsLoading } = useSettings();
  //   const router = useRouter()
  const reloadSessionContext = async () => {
    console.log("user data reloading");
    setIsLoading(true)
    axiosProtected
      .get(GET_USER_PROFILE)
      .then((response) => {
        // console.log(response)
        setIsLoading(false)
        console.log("user data reloaded" + JSON.stringify(response));
        dispatch({
          type: "updateUser",
          userDetail: response?.data?.data,
        });
        // if(!response?.data?.lastHandlerId && response?.data?.roleName==USER_ROLE_NAME){
        //   router.push((router.query.from && decodeURIComponent(router.query.from)) ?? '/welcome')
        // }
      })
      .catch((error) => {console.log(error); setIsLoading(false)});
  };

  return reloadSessionContext;
};

export default useReloadSessionContext;
