import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Icon from "@mdi/react";
import {
  mdiPrescription,
  mdiEmoticonSickOutline,
  mdiStethoscope,
  mdiDoctor,
  mdiPillMultiple,
  mdiClipboardPulseOutline,
  mdiNeedle,
} from "@mdi/js";

const WizardHeader = styled("Grid")({
  padding: 15,
  flexGrow: 1,
  textAlign: "center",
  borderBottom: "5px solid grey",
  marginRight: 5,
  marginBottom: 5,
  minWidth: 120,
  maxWidth: 180,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

const active = {
  borderBottom: "8px solid #FF3158",
  color: "#FF3158",
};

const ConfigWizardUpdateLayout = (props) => {
  const { children, currentStep } = props;

  return (
    <>
      <Grid container spacing={2} justifyContent={"left"} sx={{ py: 5 }}>
        <WizardHeader
          item
          style={currentStep === 1 ? active : {}}
          onClick={() => props.changeStep(1)}
        >
          <Icon path={mdiPrescription} size={1.2} />
          Start
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 2 ? active : {}}
          onClick={() => props.changeStep(2)}
        >
          <Icon path={mdiEmoticonSickOutline} size={1.2} />
          Symptoms
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 3 ? active : {}}
          onClick={() => props.changeStep(3)}
        >
          <Icon path={mdiStethoscope} size={1.2} />
          Diagnosis
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 4 ? active : {}}
          onClick={() => props.changeStep(4)}
        >
          <Icon path={mdiNeedle} size={1.2} />
          Procedure/Tests
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 5 ? active : {}}
          onClick={() => props.changeStep(5)}
        >
          <Icon path={mdiDoctor} size={1.2} />
          Advise
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 6 ? active : {}}
          onClick={() => props.changeStep(6)}
        >
          <Icon path={mdiPillMultiple} size={1.2} />
          Medicine
        </WizardHeader>
        <WizardHeader
          item
          style={currentStep === 7 ? active : {}}
          onClick={() => props.changeStep(7)}
        >
          <Icon path={mdiClipboardPulseOutline} size={1.2} />
          Finish
        </WizardHeader>
      </Grid>
      <Grid container justifyContent={"center"} alignItems="center">
        {children}
      </Grid>
    </>
  );
};

export default ConfigWizardUpdateLayout;
