import {
  Alert,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OTPInput from "../../components/common/OTPInput";
import { useSessionStateValue } from "../../context/SessionContext";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import {
  MOBILE_VERIFY_OTP_SEND,
  MOBILE_VERIFY_OTP_VERIFY,
} from "../../util/ApiUrls";
import useNextPageRedirect, {
  HOME_PAGE,
  USER_VERIFY_EMAIL,
} from "../../util/AppUrls";

const VerifyPhone = () => {
  const [{ user }, dispatch] = useSessionStateValue();
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const axiosProtected = useAxiosProtected();
  const redirect = useNextPageRedirect();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";

  const sendVerificationOTP = async () => {
    try {
      await axiosProtected
        .post(MOBILE_VERIFY_OTP_SEND)
        .then((response) => {
          console.log(response);
          if ("SUCCESS" === response?.data?.data) {
            setOtpSent(true);
            setProcessing(false);
          }
        })
        .catch((err) => {
          if (!err?.response) {
            setErrMsg("No server response");
          } else if (
            err.response?.status === 503 ||
            err.response?.status === 401
          ) {
            setErrMsg(err?.response?.data?.error?.message);
          } else {
            setErrMsg("Error occured While Sending OTP");
          }
          setProcessing(false);
          setToastOpen(true);
        });
    } catch (err) {
      console.log(err);
      setErrMsg("Exception occured While Sending OTP");
      setProcessing(false);
      setToastOpen(true);
    }
  };

  const verifyVerificationOTP = async (value) => {
    setProcessing(true);
    try {
      const data = {
        inputOTP: value,
      };
      await axiosProtected
        .post(MOBILE_VERIFY_OTP_VERIFY, data)
        .then((response) => {
          console.log(response);
          if (response?.status === 200) {
            const redirectUrl = redirect("REDIRECT_HOME");
            navigate(redirectUrl || from, { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
          if (!err?.response) {
            setErrMsg("No server response");
          } else if (
            err.response?.status === 503 ||
            err.response?.status === 401
          ) {
            setErrMsg(err?.response?.data?.error?.message);
          } else {
            setErrMsg("Error occured While Verifying OTP");
          }
          setProcessing(false);
          setToastOpen(true);
        });
    } catch (err) {
      console.log(err);
      setErrMsg("Exception occured While Verifying OTP");
      setProcessing(false);
      setToastOpen(true);
    }
  };

  useEffect(() => {
    setToastOpen(false);
    setErrMsg("");
  }, [otp, otpSent]);

  useEffect(() => {
    console.log(user);
    sendVerificationOTP();
  }, [user]);

  return (
    <Grid>
      <Grid>
        {user?.phoneVerified ? (
          <Grid>
            Your Phone is Already Verified.
            {!user?.emailVerified && (
              <Link to={USER_VERIFY_EMAIL}>
                {" "}
                Click here to verify your Email.
              </Link>
            )}
            OR <Link to={HOME_PAGE}> Click here to Go back.</Link>
          </Grid>
        ) : (
          <Grid container direction={"column"}>
            <Snackbar
              open={toastOpen}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {errMsg}
              </Alert>
            </Snackbar>
            <Typography variant="body2" color="text.secondary" marginBottom={5}>
              Verifying your Phone
              <br />
            </Typography>
            {processing ? (
              <LinearProgress />
            ) : (
              <Grid container justifyContent={"center"}>
                <OTPInput
                  user={user}
                  sendOTP={sendVerificationOTP}
                  setOTP={setOtp}
                  otpSent={otpSent}
                  handleOtpVerify={verifyVerificationOTP}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default VerifyPhone;
