import React, { useState } from "react";
import { Box, TextField, Typography, Button, FormControlLabel, Checkbox, Alert } from '@mui/material';
import { checkIfNumeric } from "../../util/CommonUtils";
import { validateAadhar } from "../../util/Verhoeff";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ABHA_AADHAR_VERIFY } from "../../util/ApiUrls";
import { axiosABHA } from "../../api/axios";

export default function AadhaarInput({ onSubmit }) {

    const [error, setError] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [aadharValidated, setAadharValidated] = useState(true);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (checkIfNumeric(newValue) || !newValue) {
            setAadhaarNumber(newValue);
            setError('');
        };
        setAadharValidated(validateAadhar(newValue));
    };

    const handleTermsChange = (event) => {
        setAcceptedTerms(event.target.checked);
        setError('');
    };

    const handleSubmit = () => {
        if (!aadharValidated) {
            setError('Please enter a valid 12-digit Aadhaar number.');
        } else if (!acceptedTerms) {
            setError('You must accept the Terms and Conditions to proceed.');
        }
        else {
            setError('');
            // console.log(`Aadhaar Number Entered: ${aadhaarNumber}`);
            // axiosABHA
            //     .post(ABHA_AADHAR_VERIFY, {
            //         // aadhaar: aadhar,
            //     })
            //     .then((reponse) => {
            //         console.log(reponse);
            //     });
            onSubmit(true);
        }
    };

    return (
        <Box sx={{p:6}}>
            <Typography variant="h6" gutterBottom>
                Enter Aadhaar Number <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
                label="Aadhaar Number"
                variant="outlined"
                value={aadhaarNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 12, pattern: '[0-9]*' }}
                error={!!error}
                helperText={error && error !== 'You must accept the Terms and Conditions to proceed.' ? error : ''}
                sx={{ mb: 2 }}
            />
            <Typography variant="caption" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <InfoOutlinedIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                Please ensure that mobile number is linked with Aadhaar as it will be required for OTP authentication.
            </Typography>

            <Box
                sx={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    height: '100px',
                    overflowY: 'scroll',
                    padding: '10px',
                }}
            >
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                    Terms and Conditions
                    {"\n"}
                    I, hereby declare that I am voluntarily sharing my Aadhaar number and demographic
                    information issued by UIDAI, with National Health Authority (NHA) for the sole purpose
                    of creation of ABHA number. I understand that my ABHA number can be used and shared
                    for purposes as may be notified by ABDM from time to time including provision of
                    healthcare services. Further, I am aware that my personal identifiable information
                    (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available
                    to the entities working in the National Digital Health Ecosystem (NDHE) which inter
                    alia includes stakeholders and entities such as healthcare professionals (e.g. doctors),
                    facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes),
                    which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM),
                    and various processes there under. I authorize NHA to use my Aadhaar number for
                    performing Aadhaar based authentication with UIDAI as per the provisions of the
                    Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services)
                    Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details,
                    or response of “Yes” with NHA upon successful authentication. I have been duly informed
                    about the option of using other IDs apart from Aadhaar; however, I consciously choose to
                    use Aadhaar number for the purpose of availing benefits across the NDHE. I am aware that
                    my personal identifiable information excluding Aadhaar number / VID number can be used
                    and shared for purposes as mentioned above. I reserve the right to revoke the given consent
                    at any point of time as per provisions of Aadhaar Act and Regulations.
                </Typography>
            </Box>
            <Box sx={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                mb: 6
            }}>
                <FormControlLabel
                    control={
                        <Checkbox checked={acceptedTerms} onChange={handleTermsChange} />
                    }
                    label="I accept the Terms and Conditions"
                />
            </Box>

            {error && error === 'You must accept the Terms and Conditions to proceed.' && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    You must accept the Terms and Conditions to proceed.
                </Alert>
            )}

            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!aadharValidated || !acceptedTerms}>
                Submit
            </Button>
        </Box>
    );
}
