import React, { useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { SHORT_FORM } from "../../util/MyRxConstants";
import { Slide } from "@material-ui/core";
import AddProductService from "./AddProductService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getPlannerHeader = (services) => {
  return services?.map((service, index) => {
    return (
      <TableCell key={index} align={"center"} sx={{ minWidth: 170 }}>
        {service?.type == "P" ? service?.name : service?.serviceName}
      </TableCell>
    );
  });
};

const initialServices = [
  {
    id: 1,
    serviceName: "service 1",
  },
  {
    id: 2,
    serviceName: "service 2",
  },
  {
    id: 3,
    serviceName: "service 3",
  },
];

const initialRows = [
  {
    id: 1,
    durationType: "W",
    duration: 1,
    services: [
      {
        serviceId: 1,
        times: 1,
      },
      {
        serviceId: 2,
        times: 1,
      },
      {
        serviceId: 3,
        times: 1,
      },
    ],
  },
  {
    id: 2,
    durationType: "W",
    duration: 2,
    services: [
      {
        serviceId: 1,
        times: 1,
      },
      {
        serviceId: 2,
        times: 0,
      },
      {
        serviceId: 3,
        times: 1,
      },
    ],
  },
];

const getNewBlankRow = (services, lastPlan) => {
  const row = {};
  row.durationType = (lastPlan && lastPlan[0]?.durationType) || "W";
  row.duration =
    lastPlan && lastPlan[0]?.duration ? lastPlan[0]?.duration + 1 : 0 || 0;
  row.services = [];
  services.map((item) => {
    row.services.push({
      serviceProductId: item?.type == "P" ? item?.id : item?.serviceId,
      serviceType: item?.type,
      times: 0,
    });
  });
  return row;
};

const CareDetailsPlanner = (props) => {
  const [services, setServices] = useState(initialServices);
  const [selectedServiceProduct, setSelectedServiceProduct] = useState([]);
  const [careDetails, setCareDetails] = useState(initialRows);
  const [editRow, setEditRow] = useState({});
  const [addServiceOpen, setAddServiceOpen] = useState(false);

  const handleClickAddServiceOpen = () => {
    setAddServiceOpen(true);
  };

  const handleClickAddServiceClose = (save) => {
    if (save) {
      setServices([...services, ...selectedServiceProduct]);
    }
    setAddServiceOpen(false);
  };

  const addNewBlankRow = () => {
    const blankRow = getNewBlankRow(services, careDetails.slice(-1));
    setCareDetails([...careDetails, blankRow]);
  };

  const editPlanDetails = (record, index) => {
    record.index = index;
    setEditRow(record);
  };

  const savePlanDetails = () => {
    setCareDetails(
      careDetails
        .map((item, i) => {
          if (i == editRow?.index) {
            return editRow;
          }
          return item;
        })
        .sort(carePlanSorter)
    );
    setEditRow({});
  };

  const deleteRow = (index) => {
    setCareDetails(
      careDetails.filter((item, ind) => ind !== index).sort(carePlanSorter)
    );
  };

  const cancelEditPlanDetails = () => {
    setEditRow({});
  };

  const handleEditChange = (data, isService) => (e) => {
    if (isService) {
      const newServices = editRow?.services?.map((o) => {
        if (o.serviceId == data) {
          return {
            ...o,
            times: e.target.value,
          };
        }
        return o;
      });
      setEditRow({ ...editRow, ["services"]: newServices });
    } else {
      setEditRow({ ...editRow, [data]: e.target.value });
    }
  };

  const sortCarePlans = () => {
    setCareDetails(careDetails.sort(carePlanSorter));
  };

  const carePlanSorter = (plan1, plan2) => {
    const day1 = getDaysFromDuration(plan1);
    const day2 = getDaysFromDuration(plan2);
    if (day1 < day2) {
      return -1;
    }
    if (day1 > day2) {
      return 1;
    }
    return 0;
  };

  const getDaysFromDuration = (plan) => {
    if (plan?.durationType == "W") {
      return plan?.duration * 7;
    } else if (plan?.durationType == "M") {
      return plan?.duration * 30;
    } else if (plan?.durationType == "Y") {
      return plan?.duration * 365;
    } else {
      return plan?.duration;
    }
  };

  const getPlannerRowDesign = () => {
    return careDetails?.map?.((care, index) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
          {editRow?.index !== index ? (
            <>
              <TableCell align={"center"}>
                {SHORT_FORM[care?.durationType] + " " + care?.duration}
              </TableCell>
              {services?.map((service) => {
                const details = care?.services?.find(
                  (o) => o.serviceId == service?.id
                );
                //return JSON.stringify(details);
                return (
                  <TableCell key={service?.id} align={"center"}>
                    {details?.times}
                  </TableCell>
                );
              })}
              <TableCell align={"center"}>
                <EditIcon
                  sx={{ mr: 5 }}
                  onClick={() => editPlanDetails(care, index)}
                />
                <DeleteIcon onClick={() => deleteRow(index)} />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell align={"center"}>
                <Grid container>
                  <Grid item md={6}>
                    <FormControl size="small">
                      <InputLabel id={"duration-type-select-label-" + index}>
                        Type
                      </InputLabel>
                      <Select
                        labelId={"duration-type-select-label-" + index}
                        id={"duration-type-select-" + index}
                        value={editRow?.durationType}
                        label="Type"
                        onChange={handleEditChange("durationType")}
                        sx={{ width: 100, mr: 2 }}
                      >
                        <MenuItem value={"D"}>Day</MenuItem>
                        <MenuItem value={"W"}>Week</MenuItem>
                        <MenuItem value={"M"}>Month</MenuItem>
                        <MenuItem value={"Y"}>Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      size="small"
                      type="number"
                      placeholder=""
                      label="Duration"
                      value={editRow?.duration}
                      onChange={handleEditChange("duration")}
                      sx={{ width: 100 }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              {services?.map((service) => {
                const details = editRow?.services?.find((o) => {
                  return service?.type == "P"
                    ? o.id == service?.id
                    : o.serviceId == service?.id;
                });
                //return JSON.stringify(details);
                return (
                  <TableCell key={service?.id} align={"center"}>
                    <TextField
                      size="small"
                      type="number"
                      label="Quantity"
                      placeholder=""
                      value={details?.times}
                      onChange={handleEditChange(details?.serviceId, true)}
                      sx={{ maxWidth: 120 }}
                    />
                  </TableCell>
                );
              })}
              <TableCell align={"center"}>
                <SaveIcon sx={{ mr: 5 }} onClick={() => savePlanDetails()} />
                <CancelIcon onClick={cancelEditPlanDetails} />
              </TableCell>
            </>
          )}
        </TableRow>
      );
    });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={addServiceOpen}
        onClose={handleClickAddServiceClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClickAddServiceClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Service/Product
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleClickAddServiceClose(true)}
            >
              Add selected Products/Services
            </Button>
          </Toolbar>
        </AppBar>
        <AddProductService
          selectedServiceProduct={selectedServiceProduct}
          setSelectedServiceProduct={setSelectedServiceProduct}
        />
      </Dialog>
      <Grid>
        {JSON.stringify(services)}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={"center"} sx={{ minWidth: 170 }}>
                  <Button>Week</Button>
                </TableCell>
                {getPlannerHeader(services)}
                <TableCell align={"center"} sx={{ minWidth: 170 }}>
                  <Button onClick={handleClickAddServiceOpen}>
                    Add Service
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getPlannerRowDesign()}

              {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id]

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })} */}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid hover role="checkbox" tabIndex={-1}>
          <Button onClick={addNewBlankRow}>Add New Row</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CareDetailsPlanner;
