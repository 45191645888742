import React, { useState } from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function AadhaarCommunication({ onSubmit }) {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleChange = (event) => {
        setEmail(event.target.value);
        setError("");
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleVerify = () => {
        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
        } else {
            setError("");
            console.log(`Email Entered: ${email}`);
            onSubmit(true);
        }
    };

    const handleSkip = () => {
        onSubmit(true);
    };

    return (
        <Box sx={{ p: 6 }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#d4edda",
                    padding: "10px",
                    borderRadius: "5px",
                    mb: 3,
                }}
            >
                <CheckCircleIcon sx={{ color: "#28a745", mr: 1 }} />
                <Typography variant="body1" sx={{ color: "#155724", fontWeight: "bold" }}>
                    Mobile Number has been saved and will be used for all your ABHA communication.
                </Typography>
            </Box>

            <Typography variant="h6" gutterBottom>
                Enter Email Address (Optional)
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleChange}
                    error={!!error}
                    helperText={error}
                    sx={{ mb: 4, flexGrow: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVerify}
                    sx={{ ml: 2, textTransform: "none" }}
                >
                    Verify
                </Button>
            </Box>
            <Typography variant="caption" gutterBottom sx={{ display: 'flex', mb: 2 }}>
                <InfoOutlinedIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                This Email address will be used for all communication related to ABDM.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSkip}
                    sx={{ textTransform: "none" }}
                >
                    Skip For Now
                </Button>
            </Box>
        </Box>
    );
}
