import {
  Link as MLink,
  Button,
  Alert,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useRef, useState } from "react";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCard from "@mui/material/Card";
import "react-phone-number-input/style.css";

import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import Google from "mdi-material-ui/Google";
import themeConfig from "../../configs/themeConfig";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import axios from "../../api/axios";
import { LOGIN_URL_GOOGLE, SIGN_UP_URL } from "../../util/ApiUrls";
import useAuth from "../../hooks/useAuth";
import { MYRX_SITE } from "../../util/MyRxConstants";
import PhoneInput from "react-phone-number-input";
import { LOGIN_URL } from "../../util/AppUrls";
import { useTranslation } from "react-i18next";
import MuiPhone from "../../components/common/MuiPhone";

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const LinkStyled = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const { setAuth, persist, setPersist } = useAuth();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [errMsg, setErrMsg] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [userInvalid, setUserInvalid] = useState(false);
  const [passInvalid, setPassInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation(["translation", "login"]);
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/updateprofile";

  useEffect(() => {
    setToastOpen(false);
    setErrMsg("");
  }, [values]);

  useEffect(() => {
    userRef.current.focus();
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_MYRX_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleloginDiv"),
        {
          theme: "outline",
          text: "continue_with",
          shape: "rectangular",
        }
      );
    } else {
      console.log("google not initialized");
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleChangeMobile = (value) => {
    setValues({ ...values, 'mobile': value });
  }
  const handleChecked = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateSignUpResponse = (response) => {
    console.log(response);
    const accessToken = response?.data?.access_token;
    const roles = response?.data?.user_role || ["enhance"];
    const user = response?.data?.user;
    setAuth({ user, roles, accessToken });
    navigate(from, { replace: true });
  };

  const handleGoogle = async (response) => {
    console.log(response);
    const authStr = "Google " + response?.credential;
    try {
      const response = await axios.post(
        LOGIN_URL_GOOGLE,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authStr,
          },
          withCredentials: true,
        }
      );
      updateSignUpResponse(response);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response");
      } else if (err.response?.status === 400 || err.response?.status === 401) {
        setErrMsg(err?.response?.data?.error?.message);
      } else {
        setErrMsg("Error occured While Signing you in");
      }
      setToastOpen(true);
    }
  };

  const validateFormValues = (data) => {
    if (!values?.fullname) {
      setUserInvalid(false);
      return false;
    }
    if (!values?.email) {
      setEmailInvalid(false);
      return false;
    }
  };

  const handleSignUp = async () => {
    const fullname = values?.fullname;
    const nameArr = fullname.split(" ");

    const data = {
      firstName: nameArr[0],
      lastName: nameArr[1],
      email: values?.email,
      mobile: values?.mobile,
      password: values?.password,
    };

    try {
      const response = await axios
        .post(SIGN_UP_URL, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          updateSignUpResponse(response);
        })
        .catch((err) => {
          console.log(err);
          if (!err?.response) {
            setErrMsg("No server response");
          } else if (
            err.response?.status === 400 ||
            err.response?.status === 401
          ) {
            setErrMsg(err?.response?.data?.error?.message);
          } else {
            setErrMsg("Error occured While Signing you up");
          }
          setToastOpen(true);
        });
    } catch (err) {
      console.log(err);

      setErrMsg("Something unexpected occurred. Please try again later");
      setToastOpen(true);
    }
  };

  return (
    <Grid>
      <Grid container justifyContent={"space-between"} sx={{ px: 20, py: 5 }}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logo width="50" height="50" href={MYRX_SITE} /> &nbsp;
            {/* <Typography
                            variant='h6'
                            sx={{ nl: 3, lineHeight: 1, fontWeight: 600, fontSize: '1.5rem !important' }}>
                            MyRx
                        </Typography> */}
          </Box>
        </Grid>
        <Grid item>
          {t("login:alreadyhaveaccount")} &nbsp;
          <Link to={LOGIN_URL}>
            <LinkStyled>{t("login:signin")}</LinkStyled>
          </Link>
        </Grid>
      </Grid>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errMsg}
        </Alert>
      </Snackbar>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          container
          lg={10}
          md={12}
          sm={12}
          xs={12}
          justifyContent={"space-evenly"}
          alignItems="center"
          paddingX={5}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            container
            direction="column"
            paddingX={10}
            sx={{ backgroundColor: "white", borderRadius: 5 }}
          >
            <Box sx={{ mb: 6 }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, marginBottom: 1.5 }}
              >
                {t("login:createfreeaccount")}
              </Typography>
              <Typography variant="body2">{t("login:fewclickstocreate")}</Typography>
            </Box>
            <form
              noValidate
              autoComplete={"off"}
              onSubmit={(e) => e.preventDefault()}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div id="googleloginDiv"></div>
              </Box>
              <Divider sx={{ my: 3 }}>Or</Divider>
              <Grid
                container
                spacing={4}
                justifyContent={"space-evenly"}
                alignItems="center"
                marginBottom={3}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    autoComplete={"off"}
                    id="name"
                    label="Your Full Name"
                    onChange={handleChange("fullname")}
                    ref={userRef}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    autoComplete={"off"}
                    type="email"
                    id="email"
                    label="Your Email"
                    onChange={handleChange("email")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <PhoneInput id='mobile' 
                                        label='Your Mobile'
                                         defaultCountry="IN" 
                                         onChange={() => { }} /> */}
                  {/* <TextField
                    fullWidth
                    autoComplete={"off"}
                    id="mobile"
                    label="Your Mobile"
                    onChange={handleChange("mobile")}
                  /> */}
                  <MuiPhone onChange={handleChangeMobile} fullWidth label="Your Mobile" defaultCountry="in" value={values?.mobile} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="auth-login-password">
                      {t("password")}
                    </InputLabel>
                    <OutlinedInput
                      label="Choose a Password"
                      autoComplete={"new-password"}
                      value={values.password}
                      id="auth-login-password"
                      onChange={handleChange("password")}
                      type={values.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            aria-label="toggle password visibility"
                          >
                            {values.showPassword ? (
                              <EyeOutline />
                            ) : (
                              <EyeOffOutline />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText id="form-layouts-basic-password-helper">
                      Use 8 or more characters with a mix of letters, numbers &
                      symbols
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Box marginBottom={3}>
                <span>
                  By clicking on <b>Next</b> you agree to <b>MyRx</b>{" "}
                </span>
                <MLink href="https://www.myrx.in/privacypolicy">
                  <LinkStyled onClick={(e) => e.preventDefault()}>
                    Privacy Policy & Terms
                  </LinkStyled>
                </MLink>
              </Box>

              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginBottom: 7 }}
                onClick={handleSignUp}
              >
                Next
              </Button>
            </form>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textShadow: "4px 2px 9px rgba(256,256,256,0.76)",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/myrx-signup.png"}
              height="350"
              width="350"
            />
            <span>
              <strong>4000+</strong> doctors are using MyRx Prescription Writer
              for 7 Years
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          md={6}
          xs={10}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            variant="body2"
            sx={{ textShadow: "4px 2px 9px rgba(256,256,256,0.76)" }}
          >
            We’re committed to your privacy. MyRx uses the information you
            provide to us to contact you about our relevant content, products,
            and services. You may unsubscribe from these communications at any
            time. For more information, check out our Privacy Policy
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Register;
