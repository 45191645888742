import { Grid, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Icon from "@mdi/react";
import { mdiPlusBox, mdiSelectionRemove } from "@mdi/js";
import Reorder from "react-reorder";

const WizardHeader = styled("div")({
  padding: 15,
  flexGrow: 1,
  textAlign: "center",
  borderBottom: "5px solid grey",
  marginRight: 1,
  marginBottom: 5,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  userSelect: "none",
});

const active = {
  borderBottom: "8px solid #FF3158",
  color: "#FF3158",
};

const addnew = {
  border: "2px solid grey",
  color: "grey",
  borderRadius: "5px",
  padding: 5,
  width: 180,
};

const PrescriptionConfigLayout = (props) => {
  const {
    children,
    currentStep,
    selectedModules,
    onReorder,
    onAddModuleButton,
    onRemoveModule,
  } = props;
  return (
    <>
      <Grid
        item
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems="flex-start"
        lg={3}
        md={3}
        sm={4}
        xs={12}
        sx={{ p: 5 }}
      >
        <Typography variant="body2" sx={{ mb: 5 }}>
          <i>Select, hold and drag any component to rearrange</i>
        </Typography>
        <Reorder
          reorderId="my-list" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          //getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          component="div"
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={400} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
          placeholder={
            <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
          }
        >
          {selectedModules.map((module, index) => {
            return (
              <Grid container key={module?.value}>
                <WizardHeader
                  item
                  style={currentStep === index ? active : {}}
                  onClick={() => props.changeStep(index)}
                >
                  <span>{module?.icon}</span>
                  <span>{module?.title}</span>
                  {module.removable ? (
                    <span>
                      <Tooltip title="Remove Tab">
                        <Icon
                          path={mdiSelectionRemove}
                          size={1}
                          color={"red"}
                          onClick={() => onRemoveModule(module.value)}
                        />
                      </Tooltip>
                    </span>
                  ) : (
                    <span>
                      <Tooltip title="This tab cannot be removed.">
                        <Icon
                          path={mdiSelectionRemove}
                          size={1}
                          color={"lightgrey"}
                        />
                      </Tooltip>
                    </span>
                  )}
                </WizardHeader>
              </Grid>
            );
          })}
        </Reorder>
        <WizardHeader
          item
          style={addnew}
          onClick={onAddModuleButton}
          sx={{ mt: 5 }}
        >
          <Icon path={mdiPlusBox} size={1.2} />
          Add Module
        </WizardHeader>
      </Grid>
      <Grid
        item
        lg={9}
        md={9}
        sm={8}
        xs={12}
        justifyContent={"center"}
        alignItems="center"
      >
        {children}
      </Grid>
    </>
  );
};

export default PrescriptionConfigLayout;
