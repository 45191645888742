import { Typography, Grid, Card, CardContent, CardActions, Button, CardMedia, styled } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../../context/ShoppingCartProvider';
import useAxiosProtected from '../../../hooks/useAxiosProtected';
import { FETCH_SUBSCRIPTION_PLANS } from '../../../util/ApiUrls';
import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';
import { mdiCloseThick } from '@mdi/js';
import { Link } from 'react-router-dom';
import { SHOPPING_CART } from '../../../util/AppUrls';

const active = {
    borderBottom: '8px solid #FF3158',
}

const CardMediaHeader = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}))


const BrowsePlans = () => {
    const [prescriptionPlans, setPrescriptionPlans] = useState([])
    const [moduleSelected, setModuleSelected] = useState('')

    const axiosProtected = useAxiosProtected()
    const cart = useContext(CartContext)

    const checkIfPlanAlreadyInCart = (searchPlanId) => {
        console.log('cart checking', cart)
        const index = cart?.items?.find(e => e.planId === searchPlanId)
        console.log('plan', searchPlanId)
        console.log('index', index)
        return index
    }

    const handleChange = (panel) => {
        if (moduleSelected === panel) {
            setModuleSelected('')
        } else {
            setModuleSelected(panel)
            switch (panel) {
                case 'panelPrescWriter':
                    loadPrescriptionWriterPlans()
                    break;

                default:
                    break;
            }
        }
    };

    const loadPrescriptionWriterPlans = async () => {
        try {
            await axiosProtected.get(
                FETCH_SUBSCRIPTION_PLANS.replace('modulename', 'PRESCRIPTION'),
            ).then(response => {
                console.log(response)
                setPrescriptionPlans(response?.data?.data)
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const addPlanToCart = (planId) => {
        console.log('Added to cart', planId)
        cart.addPlanToCart(planId)
        console.log(cart)
    }

    const removePlanFromCart = (planId) => {
        cart.removePlanFromCartByPlan(planId)
        console.log(cart)
    }

    useEffect(() => {
        console.log(cart)
    }, [])

    return (
        <>
            <Grid className='content-center'>
                <Grid container direction={'column'} marginBottom={10}>
                    <h2>Select a module to view plans</h2>
                    <p>We have different plans to complete your prescrition writer needs </p>
                    <Link to={SHOPPING_CART}>
                        <Button variant='contained' >Continue to Cart</Button>
                    </Link>
                </Grid>
                <Grid container spacing={10} marginBottom={10}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card onClick={() => handleChange('panelPrescWriter')} style={moduleSelected === 'panelPrescWriter' ? active : {}}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Prescription Writer
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    about prescription writer
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">View more details</Button> */}
                                Click to view plans
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card onClick={() => handleChange('panelWebsite')}>
                            {/* <CardMedia
                                sx={{ height: 140 }}
                                image="/static/images/cards/contemplative-reptile.jpg"
                                title="green iguana"
                            /> */}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Website
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    about Website
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">View more details</Button> */}
                                Coming Soon
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card onClick={() => handleChange('panelWebsite')}>
                            {/* <CardMedia
                                sx={{ height: 140 }}
                                image="/static/images/cards/contemplative-reptile.jpg"
                                title="green iguana"
                            /> */}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    SEO
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    about SEO
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">View more details</Button> */}
                                Coming Soon
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                {moduleSelected === 'panelPrescWriter' &&
                    <Grid container spacing={10} marginBottom={10}>
                        {prescriptionPlans.map(plan => {
                            return (
                                <Grid item lg={3} md={4} sm={6} xs={12} key={plan.planId}>
                                    <Card variant="outlined">
                                        <CardMediaHeader  >
                                            <Typography sx={{ fontSize: 20, fontWeight: 'bold', color:'white', padding:2 }} gutterBottom>
                                                {plan.planName}
                                            </Typography>
                                        </CardMediaHeader>
                                        <React.Fragment>
                                            <CardContent>

                                                <Typography variant="h5" component="div">
                                                </Typography>
                                                <Typography color="text.primary">
                                                    Prescription Writer
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">Amount : &#8377; {plan.amount}</Typography>
                                                <Typography variant="body2" color="text.secondary">Points : {plan.creditPoints}</Typography>
                                                <Typography variant="body2" color="text.secondary">Bonus : {plan.bonusPoints}</Typography>
                                                <Typography variant="body2" color="text.secondary">Validity : {plan.validity} days</Typography>
                                                <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">Per Prescription Price : {plan?.prescriptionModuleVO?.perPrescriptionPrice}</Typography>
                                                <Typography>
                                                    Features
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">Know About Medicine : <Icon path={plan?.prescriptionModuleVO?.knowAboutMedicine ? mdiCheckBold : mdiCloseThick} size={0.8} /></Typography>
                                                <Typography variant="body2" color="text.secondary">Customised Patient Profile : <Icon path={plan?.prescriptionModuleVO?.customisedPatientProfile ? mdiCheckBold : mdiCloseThick} size={0.8} /></Typography>
                                                <Typography variant="body2" color="text.secondary">Prescription Automation : <Icon path={plan?.prescriptionModuleVO?.prescriptionAutomation ? mdiCheckBold : mdiCloseThick} size={0.8} /></Typography>
                                            </CardContent>
                                            <CardActions>
                                                {checkIfPlanAlreadyInCart(plan?.planId) ?
                                                    <Button variant='outlined' color="secondary" size="small" onClick={() => removePlanFromCart(plan.planId)}>Remove from Cart</Button>
                                                    :
                                                    <Button variant='contained' size="small" onClick={() => addPlanToCart(plan.planId)}>Add to Cart</Button>
                                                }
                                            </CardActions>
                                        </React.Fragment>
                                    </Card>
                                </Grid>)
                        })}
                    </Grid>
                }
                {moduleSelected &&
                    <Link to={SHOPPING_CART}>
                        <Button variant='contained' size="small" >Continue to Cart</Button>
                    </Link>
                }
            </Grid>

        </>
    )
}

export default BrowsePlans