import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import {
  FETCH_SPECIALIZATION_LIST,
  UPDATE_FIRST_TIME_PROFILE,
} from "../../../util/ApiUrls";
import axios from "../../../api/axios";
import useAxiosProtected from "../../../hooks/useAxiosProtected";

const UpdateSpecialization = (props) => {
  const t = (mesage) => mesage;
  const { setAuth, persist, setPersist } = useAuth();

  const { userProfile } = props;
  const [values, setValues] = useState({
    ["firstName"]: "",
    ["lastName"]: "",
    ["specialization"]: "",
    ["regnum"]: "",
    ["exptotal"]: "",
    ["mobile"]: "",
    ["email"]: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [specOptions, setSpecOptions] = useState([]);

  const axiosProtected = useAxiosProtected();

  const previousController = useRef();

  const _validate = () => {
    props.afterValid();
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleAutoSuggest = (prop, val) => {
    setValues({ ...values, [prop]: val });
  };

  useEffect(() => {
    setValues({
      ...values,
      ["firstName"]: userProfile?.firstName,
      ["lastName"]: userProfile?.lastName,
      ["regnum"]: userProfile?.doctor?.registraionNumber,
      ["exptotal"]: userProfile?.doctor?.yearsOfPractice,
      ["mobile"]: userProfile?.mobile,
      ["email"]: userProfile?.email,
    });
    console.log(userProfile);
  }, [userProfile]);

  const callUpdateProfile = () => {
    //profile.refreshUser()
    //console.log(profile.getUserProfile());
    console.log(userProfile);
  };
  const getSpecialitiesData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    const response = axios
      .get(FETCH_SPECIALIZATION_LIST + searchTerm, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        const newOptions = response?.data?.data.map(function (item) {
          return { value: item };
        });
        setSpecOptions(newOptions);
      })
      .catch((err) => {
        console.log(err);
        if (!err?.response) {
          setErrMsg("No server response");
        } else if (err?.response?.status == 400) {
          setErrMsg(err?.response?.data?.error?.message);
        } else {
          setErrMsg("Login Failed");
        }
      });
  };

  const onSpecInputChange = (event, value, reason) => {
    if (value) {
      getSpecialitiesData(value);
    } else {
      setSpecOptions([]);
    }
  };

  const updateUserDetails = async () => {
    const data = {
      specialization: values?.specialization,
      registraionNumber: values?.regnum,
      yearsOfPractice: values?.exptotal,
      contactDetails: [
        {
          phone: values?.mobile,
        },
      ],
    };

    try {
      await axiosProtected
        .put(UPDATE_FIRST_TIME_PROFILE, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          _validate();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* <button onClick={callUpdateProfile}>Update Profile</button> */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 1.5 }}>
          Hello! Dr. {userProfile?.firstName} {userProfile?.lastName}
        </Typography>
        <Typography variant="h6">Please fill these basic details</Typography>
        {/* UserProfile: {JSON.stringify(userProfile)} */}
      </Box>
      {errMsg}
      <form
        noValidate
        autoComplete={"off"}
        onSubmit={(e) => e.preventDefault()}
      >
        {!userProfile?.mobile?.trim() && (
          <TextField
            autoFocus
            fullWidth
            autoComplete={"off"}
            id="mobile"
            label="Phone number"
            onChange={handleChange("mobile")}
            sx={{ mb: 3 }}
            value={values?.mobile?.trim()}
          />
        )}
        {!userProfile?.email?.trim() && (
          <TextField
            autoFocus
            fullWidth
            autoComplete={"off"}
            id="email"
            label="Email"
            onChange={handleChange("email")}
            sx={{ mb: 3 }}
            readOnly={userProfile?.email?.trim() && true}
            value={values?.email?.trim()}
          />
        )}
        <Autocomplete
          disablePortal
          id="specialization"
          sx={{ mb: 3 }}
          onChange={(e, v) => {
            handleAutoSuggest("specialization", v.value);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Specialization" />
          )}
          options={specOptions}
          onInputChange={onSpecInputChange}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(opt, val) => opt.value == val}
        />
        {/* <TextField autoFocus fullWidth autoComplete={"off"} id='specialization' label='Specialization' onChange={handleChange('specialization')} sx={{ mb: 3 }} /> */}
        <TextField
          autoFocus
          fullWidth
          autoComplete={"off"}
          id="regno"
          label="Registration No."
          onChange={handleChange("regnum")}
          sx={{ mb: 3 }}
          value={values?.regnum ? values?.regnum : ""}
        />
        <TextField
          autoFocus
          fullWidth
          autoComplete={"off"}
          id="totExp"
          label="Total Experience in Years"
          type={"number"}
          onChange={handleChange("exptotal")}
          sx={{ mb: 3 }}
          value={values?.exptotal || ""}
        />
      </form>
      <Button
        fullWidth
        size="large"
        variant="contained"
        sx={{ marginBottom: 7 }}
        onClick={updateUserDetails}
      >
        Next
      </Button>
    </div>
  );
};

export default UpdateSpecialization;
