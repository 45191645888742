import React from 'react'
import useAxiosProtected from '../hooks/useAxiosProtected'
import { TEST_URL } from '../util/ApiUrls';

const Test = () => {
    const axiosProtected = useAxiosProtected();
    // const { userProfile, setUserProfile } = useUserProfile()
  
    const controller = new AbortController();
  
    const testAPIProtected = async() =>{
      try{
        const response = await axiosProtected.get(TEST_URL, {
          signal: controller.signal,
        });
        console.log(JSON.stringify(response));
      }catch (err) {
        console.log(err);
      }
    }
  
    const callUpdateProfile=()=>{
      // updateProfile(()=>{
      //   console.log('completed')
      // })
    }
  
    const printLog =()=>{
      console.log('completed')
      
    }
    
    return (
      <div>GetStarted
        {/* Hello {userProfile.firstName} */}
        <button onClick={testAPIProtected}>This is test</button>
        <button onClick={callUpdateProfile}>This is test</button>
      </div>
    )
}

export default Test