import React from "react";
import {
  Paper,
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import PrescriptionVitalsList from "../../../components/prescription/PrescriptionVitalsList";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

const getVitalRenderer = (vital, index, suggestedVitals, props) => {
  console.log(vital);

  //search from suggestedVital
  const item = suggestedVitals.filter((e) => e.value == vital.value);
  const selectedVital = item.length > 0 ? item[0] : vital;
  if (selectedVital.value === "showVitalBP") {
    return getRenderForBPInput(selectedVital, props);
  } else if (vital?.unit?.value) {
    //Update to label in case of not label
    if (selectedVital?.unit?.type !== "label") {
      const unitLabel = selectedVital?.unit?.values?.filter(
        (e) => e.value == vital?.unit?.value
      );
      if (unitLabel?.length > 0) {
        const newUnit = {
          type: "label",
          value: unitLabel[0].label,
        };
        selectedVital.unit = newUnit;
      }
    }
  }
  return getFieldRenderForVital(selectedVital, index, props);
};

const getRenderForBPInput = (vital, props) => {
  const { control } = props;
  return (
    <Grid item sx={{ display: "flex", justifyContent: "space-around" }}>
      <Grid item lg={6}>
        <Controller
          name={vital?.value + ".systolic"}
          control={control}
          render={({ field }) => (
            <TextField {...field} type="number" label="Systolic" />
          )}
        />
      </Grid>
      <Grid item lg={6} sx={{ ml: 2 }}>
        <Controller
          name={vital?.value + ".diastolic"}
          control={control}
          render={({ field }) => (
            <TextField {...field} type="number" label="Diastolic" />
          )}
        />
      </Grid>
    </Grid>
  );
};

const getFieldRenderForVital = (vital, index, props) => {
  const { control } = props;
  return (
    <>
      {getFieldBasedOnType(vital, control)}
      <Controller
        name={vital?.value + ".vitalUnit"}
        control={control}
        render={({ field }) => (
          <>
            <input type="hidden" {...field} value={vital?.unit?.value} />
          </>
        )}
      />
    </>
  );
};

const getFieldBasedOnType = (vital, control) => {
  switch (vital?.type) {
    case "text":
      return (
        <Controller
          name={vital?.value + ".vitalVal"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={vital?.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {vital?.unit?.value}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      );
    case "number":
      return (
        <Controller
          name={vital?.value + ".vitalVal"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label={vital?.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {vital?.unit?.value}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      );
    case "radio":
      return (
        <Controller
          name={vital?.value + ".vitalVal"}
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>
                {vital?.name} ({vital?.unit?.value})
              </FormLabel>
              <RadioGroup
                {...field}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={vital?.options?.[0]}
                row
              >
                {vital?.options?.map((item) => {
                  return (
                    <FormControlLabel
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          )}
        />
      );
    case "checkbox":
      return (
        <FormControl>
          <FormLabel>
            {vital?.name} ({vital?.unit?.value})
          </FormLabel>
          <FormGroup aria-labelledby="demo-radio-buttons-group-label" row>
            {vital?.options?.map((item) => {
              return (
                <Controller
                  name={vital?.value + "." + item}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      value={item}
                      control={<Checkbox {...field} />}
                      label={item}
                    />
                  )}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      );
    case "dropdown":
      return (
        <Controller
          name={vital?.value + ".vitalVal"}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <InputLabel id={vital?.value}>
                {vital?.name} ({vital?.unit?.value})
              </InputLabel>
              <Select
                {...field}
                labelId={vital?.value}
                label={vital?.name + "(" + vital?.unit?.value + ")"}
                fullWidth
              >
                {vital?.options?.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
          )}
        />
      );

    default:
      return <div>Type not configured</div>;
  }
};

const VitalDetails = (props) => {
  const [suggestedVitals, setSuggestedVitals] = useState(
    PrescriptionVitalsList
  );
  const { config } = props;

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
  } = useForm();

  const onSubmit = (data) => console.log(data);

  return (
    <Paper elevation={3} sx={{ p: 5, width: "100%" }}>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Vitals:
      </Typography>

      <Divider sx={{ mb: 5 }} />
      <Button onClick={handleSubmit(onSubmit)}>button</Button>
      <Grid container spacing={5} sx={{ mb: 5 }}>
        {config?.vitals?.map((configItem, index) => {
          return (
            <Grid item lg={4} md={4} sm={6} xs={12}>
              {getVitalRenderer(configItem, index, suggestedVitals, {
                control: control,
              })}
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default VitalDetails;
