import {
  Alert,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Link as Mlink,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCard from "@mui/material/Card";

import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGIN_URL_GOOGLE,
} from "../../util/ApiUrls";
import axios from "../../api/axios";
import { encode as base64_encode } from "base-64";
import Logo from "../../components/Logo";
import useNextPageRedirect, { MADHAVBAUG_DOCTOR_SIGN_UP, MADHAVBAUG_PCE_SIGN_UP } from "../../util/AppUrls";
import { client } from "../../util/MyRxConstants";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const LinkStyled = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(["translation", "login"]);
  const redirect = useNextPageRedirect();

  const from = location.state?.from?.pathname || "/";

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [errMsg, setErrMsg] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState();
  const [forgotpassword, setForgotPassword] = useState(false);
  const [forgotpasswordMailSent, setForgotPasswordMailSent] = useState(false);
  const [powermapRedirect, setPowermapRedirect] = useState(false)

  useEffect(() => {
    setToastOpen(false);
    setErrMsg("");
  }, [values]);

  useEffect(() => {
    setPersist((prev) => !prev);
  }, [values?.rememberme]);

  useEffect(() => {
    localStorage.setItem("myrx_persist", persist);
  }, [persist]);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_MYRX_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleloginDiv"),
        {
          theme: "outline",
          text: "continue_with",
          shape: "rectangular",
        }
      );
    } else {
      console.log("google not initialized");
    }
  }, [forgotpassword]);

  useEffect(() => {
    // const script = document.createElement('script');

    // script.src = "https://myrx.authlink.me/js/sdk/otpless.js";
    // script.async = true;

    // document.body.appendChild(script);

    window["otpless"] = () => loginWithWhatsapp();

    // return () => {
    //     document.body.removeChild(script);
    // }
  }, []);

  const loginWithWhatsapp = () => {
    //@ts-ignore

    const waId = window.otplessWaId?.();

    //Once you signup/sign a user, you can redirect the user to your signup/signin flow.

    //history.push("/home.html")
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChecked = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const saveAuthToken = (response) => {
    // console.log(response);
    const accessToken = response?.data?.access_token;
    const role = response?.data?.user_role || ["enhance"];
    const privileges = response?.data?.privileges || [];
    const user = response?.data?.user;
    const redirecTo = response?.data?.redirect_url;
    const redirectUrl = redirect(redirecTo);
    setAuth({ user, role, accessToken, privileges });
    // profile.refreshUser();
    navigate(redirectUrl || from, { replace: true });
  };

  const handleGoogle = async (response) => {
    console.log(response);
    const authStr = "Google " + response?.credential;
    try {
      const response = await axios.post(
        LOGIN_URL_GOOGLE,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authStr,
          },
          withCredentials: true,
        }
      );
      saveAuthToken(response);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response");
      } else if (err.response?.status === 400 || err.response?.status === 401) {
        setErrMsg(err?.response?.data?.error?.message);
      } else {
        setErrMsg("Error occured While Logging you in");
      }
      setToastOpen(true);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const credentials = base64_encode(
      values?.username + ":" + values?.password
    );
    const basicAuth = "Basic " + credentials;
    console.log(basicAuth);
    try {
      const response = await axios.post(
        LOGIN_URL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: basicAuth,
          },
          withCredentials: true,
        }
      );

      saveAuthToken(response);
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg("No server response");
      } else if (
        err.response?.status === 400 ||
        err.response?.status === 401 ||
        err.response?.status === 403
      ) {
        setErrMsg(err?.response?.data?.error?.message);
        setToastOpen(true);
      } else if (err.response?.status == 301 && err.response?.data?.error?.status == "POWERMAP_REDIRECT") {
        setPowermapRedirect(true)
      } else {
        setErrMsg("Error occured While Logging you in");
        setToastOpen(true);
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const credentials = base64_encode(values?.username + ":" + " ");
    const basicAuth = "Basic " + credentials;
    console.log(basicAuth);
    try {
      const response = await axios
        .post(
          FORGOT_PASSWORD_URL,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: basicAuth,
            },
            withCredentials: true,
          }
        )
        .then((resp) => {
          if ("SUCCESS" == resp?.data) {
            setForgotPasswordMailSent(true);
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response");
      } else if (err.response?.status === 400 || err.response?.status === 401) {
        setErrMsg(err?.response?.data?.error?.message);
      } else {
        setErrMsg("Error occured While Logging you in");
      }
      setToastOpen(true);
    }
  };

  return (
    <Grid>
      <Grid container justifyContent={"space-between"} sx={{ px: 20, py: 5 }}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logo width="50" height="50" href={process.env.REACT_APP_MYRX_SITE} /> &nbsp;{" "}
            <strong>{t("myrx.app_name_" + client)}</strong>
          </Box>
        </Grid>
        <Grid item>
          {t("login:donthaveaccount_" + client)} &nbsp;
          {client == 'MYRX' &&
            <Typography variant="body2">
              <Link to="/register">
                <LinkStyled>{t("login:createanaccount")}</LinkStyled>
              </Link>
            </Typography>
          }
          {client == 'POWERMAP' && <>
            <Typography variant="body2">
              <Link to={MADHAVBAUG_PCE_SIGN_UP}>
                <LinkStyled>{t("login:createanaccount")} for PCE</LinkStyled>
              </Link>
            </Typography>
            <Typography>
              <Link to={MADHAVBAUG_DOCTOR_SIGN_UP}>
                <LinkStyled>{t("login:createanaccount")} for Doctors</LinkStyled>
              </Link>
            </Typography>
          </>
          }
        </Grid>
      </Grid>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errMsg}
        </Alert>
      </Snackbar>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card sx={{ zIndex: 1 }}>
            <CardContent
              sx={{
                padding: (theme) => `${theme.spacing(3, 9, 3)} !important`,
              }}
            >
              <Box
                sx={{
                  mb: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Logo width="50" height="50" /> &nbsp;
              </Box>
              <Box sx={{ mb: 8 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1.5,
                    textAlign: "center",
                  }}
                >
                  {t("login:login_to_account") + t("myrx.app_name_" + client)}
                </Typography>
                {/* <Typography variant='body2'>{t('login_to_account')}</Typography> */}
              </Box>
              {powermapRedirect ? (
                <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item>
                    <strong>POWERMAP is Live now</strong><br />
                    Kindly login to <Link to="https://powermap.in/app/login">www.powermap.in</Link> for all your activities
                  </Grid>
                </Grid>
              )
                : (forgotpassword ? (
                  <>
                    {forgotpasswordMailSent ? (
                      <>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: 1.5, textAlign: "center" }}
                        >
                          Reset Password Mail sent
                        </Typography>
                      </>
                    ) : (
                      <>
                        <TextField
                          autoFocus
                          fullWidth
                          id="userName"
                          label="Username or Email"
                          sx={{ marginBottom: 4 }}
                          onChange={handleChange("username")}
                        />

                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          sx={{ marginBottom: 7 }}
                          onClick={handleForgotPassword}
                        >
                          Reset Password
                        </Button>
                      </>
                    )}
                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <FormControlLabel control={<Checkbox onChange={handleChecked('rememberme')} />} label={t('rememberme')} /> */}
                      <Link>
                        <LinkStyled onClick={(e) => setForgotPassword(false)}>
                          Back to Login
                        </LinkStyled>
                      </Link>
                    </Box>
                  </>
                ) : (
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    {process.env.REACT_APP_ENABLE_GOOGLE_LOGIN && <><Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div id="googleloginDiv"></div>
                      {/* <button id="whatsapp-login"/> */}
                    </Box>
                      <Divider sx={{ my: 5 }}>{t("text.or")}</Divider>
                    </>}
                    <TextField
                      autoFocus
                      fullWidth
                      id="userName"
                      label={t("login:username")}
                      sx={{ marginBottom: 4 }}
                      onChange={handleChange("username")}
                    />
                    <FormControl fullWidth>
                      <InputLabel htmlFor="auth-login-password">
                        {t("login:password")}
                      </InputLabel>
                      <OutlinedInput
                        label="Password"
                        value={values.password}
                        id="auth-login-password"
                        onChange={handleChange("password")}
                        type={values.showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              aria-label="toggle password visibility"
                            >
                              {values.showPassword ? (
                                <EyeOutline />
                              ) : (
                                <EyeOffOutline />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <FormControlLabel control={<Checkbox onChange={handleChecked('rememberme')} />} label={t('rememberme')} /> */}
                      <Link>
                        <LinkStyled
                          onClick={(e) => {
                            setForgotPassword(true);
                            setForgotPasswordMailSent(false);
                          }}
                        >
                          {t("login:forgotpassword")}
                        </LinkStyled>
                      </Link>
                    </Box>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      sx={{ marginBottom: 7 }}
                      onClick={handleLogin}
                      type="submit"
                    >
                      {t("login:login")}
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body2" sx={{ marginRight: 2 }}>
                        {t("login:newonplatform_" + client)}
                      </Typography>
                      {client == 'MYRX' &&
                        <Typography variant="body2">
                          <Link to="/register">
                            <LinkStyled>{t("login:createanaccount")}</LinkStyled>
                          </Link>
                        </Typography>
                      }
                      {client == 'POWERMAP' && <>
                        <Typography variant="body2">
                          <Link to={MADHAVBAUG_PCE_SIGN_UP}>
                            <LinkStyled>{t("login:createanaccount")} for PCE</LinkStyled>
                          </Link>
                        </Typography>
                        <Typography>
                          <Link to={MADHAVBAUG_DOCTOR_SIGN_UP}>
                            <LinkStyled>{t("login:createanaccount")} for Doctors</LinkStyled>
                          </Link>
                        </Typography>
                      </>
                      }
                      {/* <Typography variant="body2">
                      Trouble logging in
                      <Mlink href="https://myrx.in/jsp/admin/common/login/login.jsp">
                        <LinkStyled> Go Back to older Login Page</LinkStyled>
                      </Mlink>
                    </Typography> */}
                    </Box>
                  </form>
                ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
