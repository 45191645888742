import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React from 'react'
import useNextPageRedirect from '../../../../util/AppUrls'

const StartPrescriptionWritingStep = (props) => {
    const redirect = useNextPageRedirect()
    const getPrescriptionUrl= async()=>{
        props.afterValid('MYRX_TOUR')
        redirect('MYRX_TOUR')
    }

    return (
        <>
            <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title='Congratulations! You are ready' titleTypographyProps={{ variant: 'h6' }} />
                        <CardContent>
                            <Grid container>
                                <Grid item md={12} sx={{ mb: 3 }}>
                                    <Typography variant='body2'>Write your first prescription</Typography>
                                </Grid>
                            </Grid>
                            <Button
                                variant='contained'
                                sx={{ marginBottom: 7 }}
                                onClick={getPrescriptionUrl}
                            >
                                Write my first Prescription
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default StartPrescriptionWritingStep