import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../../context/ShoppingCartProvider";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import { BROWSE_PLANS, SHOPPING_CHECKOUT } from "../../../util/AppUrls";

const ShoppingCart = () => {
  const axiosProtected = useAxiosProtected();
  const cart = useContext(CartContext);

  useEffect(() => {
    cart.getCartFromServer();
  }, []);

  const removePlanFromCart = (planId) => {
    cart.removePlanFromCart(planId);
    console.log(cart);
  };

  return (
    <>
      <Grid className="content-center">
        <Grid
          container
          marginBottom={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item direction={"column"}>
            <h2>Selected Plans</h2>
            <Typography>Review your Plans here</Typography>
          </Grid>
          <Grid item>
            <Link to={BROWSE_PLANS}>
              <Button variant="outlined" size="small" sx={{ mr: 5 }}>
                Browse More Plans
              </Button>
            </Link>
          </Grid>
        </Grid>
        {cart?.items?.length > 0 ? (
          <>
            <Grid container spacing={3}>
              {cart?.getCartProducts()?.map((plan) => {
                const planDetails = plan?.planDetails;
                console.log(plan);
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    key={plan.id}
                    marginBottom={5}
                  >
                    <Card variant="outlined">
                      <React.Fragment>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {planDetails?.moduleName == "PRESCRIPTION" &&
                              "Prescription Writer Top Up"}
                          </Typography>
                          <Typography gutterBottom variant="h6" component="div">
                            {planDetails?.planName}
                          </Typography>

                          <Typography variant="h5" component="div"></Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Credit Points : {planDetails?.creditPoints}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            textAlign={"right"}
                          >
                            Amount : &#8377; {plan.price}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => removePlanFromCart(plan?.itemId)}
                          >
                            Remove Plan from Cart
                          </Button>
                        </CardActions>
                      </React.Fragment>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container marginBottom={10} justifyContent="flex-end">
              <Grid item>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  textAlign={"right"}
                >
                  Total Amount : &#8377; {cart?.getTotalAmount()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container marginBottom={10} justifyContent="flex-end">
              <Grid item>
                <Link to={SHOPPING_CHECKOUT}>
                  <Button variant="contained">Proceed to Checkout</Button>
                </Link>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container direction={"column"} marginBottom={10}>
              <h3>No plans added to Cart.</h3>
              <Link to={BROWSE_PLANS}>
                <Button variant="contained" size="small">
                  Browse Plans
                </Button>
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ShoppingCart;
