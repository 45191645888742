import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  styled,
  CardMedia,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import { FETCH_ALL_SUBSCRIPTION_PLANS } from "../../../util/ApiUrls";
import Icon from "@mdi/react";
import { mdiCheckBold } from "@mdi/js";
import { mdiCloseThick } from "@mdi/js";
import { moduleDetails } from "../../../util/MyRxConstants";
import AddPlan from "../../../components/prescription/AddPlan";

const CardMediaHeader = styled(CardMedia)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const getModuleChip = (plan) => {
  const planModule = plan?.moduleName
    ? moduleDetails[plan?.moduleName]
    : moduleDetails.MIXED;
  return (
    <Chip
      label={planModule?.moduleName}
      color={planModule?.color}
      sx={{
        height: 30,
        fontSize: "1rem",
        textTransform: "capitalize",
        "& .MuiChip-label": { fontWeight: 500 },
        mb: 5,
      }}
    />
  );
};

const getMainSubscriptionPlanView = (plan) => {
  return (
    <Grid item lg={3} md={4} sm={6} xs={12} key={plan.planId}>
      <Card variant="outlined">
        <CardMediaHeader>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "white",
              padding: 2,
            }}
            gutterBottom
          >
            {plan.planName}
          </Typography>
        </CardMediaHeader>
        <React.Fragment>
          <CardContent>
            <Typography variant="h5" component="div"></Typography>
            {getModuleChip(plan)}
            <Typography variant="body2" color="text.secondary">
              Amount : &#8377; {plan.amount}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Points :{" "}
              {plan.creditPoints == -1 ? "Unlimited" : plan.creditPoints}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Bonus : {plan.bonusPoints}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Validity : {plan.validity} days
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="outlined" size="small">
              Disable Plan
            </Button>
          </CardActions>
        </React.Fragment>
      </Card>
    </Grid>
  );
};

const ConfigPlans = () => {
  const axiosProtected = useAxiosProtected();

  const [allPlans, setAllPlans] = useState([]);

  const reloadPlans = async () => {
    await axiosProtected
      .get(FETCH_ALL_SUBSCRIPTION_PLANS)
      .then((response) => {
        console.log(response);
        setAllPlans(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    reloadPlans();
  }, []);

  return (
    <>
      <Grid container>
        <AddPlan />
      </Grid>
      <Grid container>
        <Grid
          item
          md={12}
          sx={{ display: "flex", justifyContent: "end", mb: 5 }}
        >
          <Button variant="outlined" onClick={reloadPlans} sx={{ mr: 5 }}>
            Reload plans
          </Button>
          <Button variant="contained" onClick={reloadPlans}>
            Add plan
          </Button>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={10}>
            {allPlans?.map((plan, index) => {
              return getMainSubscriptionPlanView(plan);
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigPlans;
