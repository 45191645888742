import React, { createContext, useEffect, useState } from 'react'
import useAxiosProtected from '../hooks/useAxiosProtected'
import { ADD_PLAN_TO_CART, GET_SHOPPING_CART, PLAN_IN_CART_PLANID } from '../util/ApiUrls'


export const CartContext = createContext({
    items: [],
    addPlanToCart: () => { },
    removePlanFromCart: () => { },
    removePlanFromCartByPlan: () => { },
    getTotalAmount: () => { },
})

const ShoppingCartProvider = ({ children }) => {

    const [cartProducts, setCartProducts] = useState([])
    const [totalAmount, setTotalAmount] = useState([])
    const [loaded, setLoaded] = useState(false)
    const axiosProtected = useAxiosProtected()

    const getCartFromServer = async () => {
        try {
            await axiosProtected.get(
                GET_SHOPPING_CART,
            ).then(response => {
                console.log(response)
                const cardData = response?.data?.data
                setTotalAmount(cardData?.totalAmount)
                setCartProducts(cardData?.cartItemVOs.map(item => {
                    return {
                        itemId: item?.id,
                        planName: item?.planName,
                        planId: item?.masterPlanId,
                        module: item?.module,
                        price: item?.price,
                        productType: item?.productType,
                        planDetails: item?.planDetails
                    }
                }))
                console.log("Items in cart: ", cartProducts)
                setLoaded(true)
            }).catch(err => {
                console.log(err)

            })
        } catch (err) {
            console.log(err)
        }
    }

    const addPlanToCart = async (id) => {
        try {
            await axiosProtected.post(
                ADD_PLAN_TO_CART + id,
            ).then(response => {
                console.log(response)
                getCartFromServer()
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const removePlanFromCart = async (id) => {
        try {
            await axiosProtected.delete(
                ADD_PLAN_TO_CART + id,
            ).then(response => {
                console.log(response)
                getCartFromServer()
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const removePlanFromCartByPlan = async (id) => {
        try {
            await axiosProtected.delete(
                PLAN_IN_CART_PLANID + id,
            ).then(response => {
                console.log(response)
                getCartFromServer()
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getCartProducts = () => {
        return cartProducts;
    }

    const getTotalAmount = () => { return totalAmount }

    const contextValue = {
        items: cartProducts,
        getCartFromServer,
        getCartProducts,
        addPlanToCart,
        removePlanFromCart,
        removePlanFromCartByPlan,
        getTotalAmount,
    }

    // useEffect(() => {
    //     getCartFromServer()
    // }, [])
    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default ShoppingCartProvider