import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { REDIRECT_AND_LOGIN_USER_API } from "../../util/ApiUrls";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RedirectUser = () => {
  const navigate = useNavigate();
  const { setAuth, persist, setPersist } = useAuth();
  let query = useQuery();
  const redirectUserToAppPage = async () => {
    const redirectData = query.get("q");

    const response = await axios
      .get(REDIRECT_AND_LOGIN_USER_API + encodeURIComponent(redirectData))
      .then((response) => {
        console.log(response);
        const accessToken = response?.data?.data?.access_token;
        const roles = response?.data?.roles || ["enhance"];
        const user = response?.data?.data?.user;
        const redirecTo = response?.data?.data?.redirect_url;
        setAuth({ user, roles, accessToken });
        navigate(redirecTo, { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    redirectUserToAppPage();
  }, []);

  return (
    <Grid container direction={"column"}>
      <Typography variant="body2" color="text.secondary" marginBottom={5}>
        Please wait while the page is loading
        <br />
      </Typography>
      <LinearProgress />
    </Grid>
  );
};

export default RedirectUser;
