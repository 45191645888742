import React from "react";

const PrescriptionVitalsList = () => {
  return [
    {
      name: "Weight",
      value: "showVitalWeight",
      selected: false,
      type: "number",
      min: 0,
      max: 300,
      precision: 0.01,
      unit: {
        type: "label",
        value: "Kg",
      },
    },
    {
      name: "Height",
      value: "showVitalHeight",
      selected: false,
      type: "number",
      min: 0,
      max: 300,
      precision: 0.01,
      unit: {
        type: "dropdown",
        value: "cm",
        values: [
          { value: "cm", label: "centimeter" },
          { value: "m", label: "meter" },
          { value: "ft", label: "feet" },
          { value: "inches", label: "inches" },
        ],
      },
    },
    {
      name: "BMI",
      value: "showVitalBMI",
      selected: false,
      disabled: true,
      type: "number",
      min: 0,
      max: 300,
      precision: 0.01,
      unit: {
        type: "label",
        value: "Kg/m2",
      },
    },
    {
      name: "Temperature",
      value: "showVitalTemp",
      selected: false,
      type: "number",
      min: -100,
      max: 200,
      precision: 0.01,
      unit: {
        type: "dropdown",
        value: "f",
        values: [
          { value: "f", label: "℉" },
          { value: "c", label: "℃" },
        ],
      },
    },
    {
      name: "Pulse",
      value: "showVitalPulse",
      selected: false,
      type: "number",
      min: 0,
      max: 300,
      precision: 1,
      unit: {
        type: "label",
        value: "beats/min",
      },
    },
    {
      name: "Blood Pressure",
      value: "showVitalBP",
      selected: false,
      type: "predefined",
      unit: {},
    },
    {
      name: "Respiratory Rate",
      value: "showVitalRR",
      selected: false,
      type: "number",
      min: 0,
      max: 300,
      precision: 1,
      unit: {
        type: "label",
        value: "breath/min",
      },
    },
    {
      name: "SpO2",
      value: "showVitalSPO2",
      selected: false,
      type: "number",
      min: 0,
      max: 100,
      precision: 0.1,
      unit: {
        type: "label",
        value: "%",
      },
    },
    {
      name: "Pain",
      value: "showVitalPain",
      selected: false,
      type: "number",
      min: 0,
      max: 10,
      precision: 1,
      unit: {
        type: "label",
        value: "point",
      },
    },
    {
      name: "Level of Consciousness",
      value: "showVitalUncn",
      selected: false,
      type: "number",
      min: 0,
      max: 15,
      precision: 1,
      unit: {
        type: "label",
        value: "point",
      },
    },
    {
      name: "Urine Output",
      value: "showVitalUO",
      selected: false,
      type: "number",
      min: 0,
      max: 10,
      precision: 0.01,
      unit: {
        type: "label",
        value: "ml/Kg/hr",
      },
    },
  ];
};

export default PrescriptionVitalsList;
