import { Card, CardHeader, Box, Typography, CardContent, Grid, Avatar } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import Icon from '@mdi/react';
import { mdiPrescription } from '@mdi/js';

const CreditDetails = (props) => {

    const { creditAvailable } = props?.creditDetails

   

    return (
        <Card>
            <CardHeader
                title='Credits'
                // action={
                //   <IconButton size='small' aria-label='settings' className='card-more-options' sx={{ color: 'text.secondary' }}>
                //     <DotsVertical />
                //   </IconButton>
                // }
                subheader={
                    <Typography variant='body2'>
                        <Box component='span' sx={{ fontWeight: 600, color: 'text.primary' }}>

                        </Box>{' '}

                    </Typography>
                }
                titleTypographyProps={{
                    sx: {
                        mb: 2.5,
                        lineHeight: '2rem !important',
                        letterSpacing: '0.15px !important'
                    }
                }}
            />
            <CardContent sx={{ pt: theme => `${theme.spacing(3)} !important` }}>
                <Grid container spacing={[5, 0]}>
                    <Grid item xs={12} sm={3} >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                variant='rounded'
                                sx={{
                                    mr: 3,
                                    width: 44,
                                    height: 44,
                                    boxShadow: 3,
                                    color: 'common.white',
                                    backgroundColor: `primary.main`
                                }}
                            >
                                <Icon path={mdiPrescription} size={1} />
                            </Avatar>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='caption'>Prescription</Typography>
                                <Typography variant='h6'>{creditAvailable?.PRESCRIPTION >=0 ? creditAvailable?.PRESCRIPTION : 'Unlimited'}</Typography>
                                <Typography variant='caption'>Bonus: {creditAvailable?.PRESCRIPTION_BONUS}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CreditDetails