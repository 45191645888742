import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useNextPageRedirect from "../util/AppUrls";
import { Grid, LinearProgress, Typography } from "@mui/material";

const Home = () => {
  const redirect = useNextPageRedirect();
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const redirectUrl = redirect("REDIRECT_HOME");
    navigate(redirectUrl || from, { replace: true });
  }, []);
  return (
    <div>
      <Grid container direction={"column"}>
        <Typography variant="body2" color="text.secondary" marginBottom={5}>
          Please wait while we load your Dashboard
          <br />
        </Typography>
        <LinearProgress />
      </Grid>
    </div>
  );
};

export default Home;
