import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import MedicineAutoSuggest from "../../../components/autosuggest/MedicineAutoSuggest";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import {
  ADD_DOCTOR_MEDICINE_CONFIG,
  DELETE_DOCTOR_MEDICINE_CONFIG,
  FETCH_DOCTOR_MEDICINE_CONFIG,
  FETCH_MEDICINE_AUTOSUGGEST,
} from "../../../util/ApiUrls";
import PrescriptionParamAutoSuggest from "../../../components/autosuggest/PrescriptionParamAutoSuggest";
import { defaultDosagePreferenceMap } from "../../../util/MyRxConstants";
import { preferenceMap } from "../../../util/MyRxConstants";
import { frequencyMap } from "../../../util/MyRxConstants";
import { dosageMap } from "../../../util/MyRxConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-of-type td, &:last-of-type th": {
    border: 0,
  },
}));

const createData = (id, medicineName, dosage, days) => {
  return { id, medicineName, dosage, days };
};

const MedicineConfig = (props) => {
  const [autoSuggestValue, setAutoSuggestValue] = useState("");

  const [values, setValues] = useState();
  const [medName, setMedName] = useState("");
  const [medDosage, setMedDosage] = useState("");
  const [preference, setPreference] = useState("");
  const [medFreq, setMedFreq] = useState("");
  const [medDays, setMedDays] = useState("");

  const [medicines, setMedicines] = useState([]);

  const axiosProtected = useAxiosProtected();
  const medFormRef = useRef();

  const setMedicineName = async (event, value) => {
    setAutoSuggestValue(value?.value);
  };

  const handleChange = (prop) => (event) => {
    if (prop === "dosage") {
      setPreference(defaultDosagePreferenceMap[event.target.value]);
      setMedDosage(event.target.value);
    } else if (prop === "preference") {
      setPreference(event.target.value);
    } else if (prop === "frequency") {
      setMedFreq(event.target.value);
    } else if (prop === "days") {
      setMedDays(event.target.value);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const loadAddedMedicine = async () => {
    try {
      await axiosProtected
        .get(FETCH_DOCTOR_MEDICINE_CONFIG)
        .then((response) => {
          console.log(response);

          setMedicines((prev) => {
            console.log("prev {}", prev);
            return response?.data?.data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewMedicineName = (value) => {
    setValues({ ...values, medicine: value });
  };

  const deleteMedicine = async (medicineId) => {
    try {
      await axiosProtected
        .delete(DELETE_DOCTOR_MEDICINE_CONFIG + medicineId)
        .then((response) => {
          console.log(response);
          loadAddedMedicine();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewMedicineRow = async () => {
    console.log(values);
    try {
      const data = {
        medicineName: values?.medicine?.value,
        medicineId: values?.medicine?.itemVal,
        composition: values?.medicine?.composition,
        days: values?.days,
        preference: values?.preference || preference,
        dosage: values?.dosage,
        frequency: values?.frequency,
      };
      await axiosProtected
        .post(ADD_DOCTOR_MEDICINE_CONFIG, data)
        .then((response) => {
          console.log(response);
          loadAddedMedicine();
          resetAddMedicineForm();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetAddMedicineForm = () => {
    setMedName("");
    setMedDosage("");
    setMedFreq("");
    setPreference("");
    setMedDays("");
    setValues({});
  };

  useEffect(() => {
    loadAddedMedicine();
  }, []);

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item md={12} xs={12} sx={{ mb: 5 }}>
          <Typography variant="body2">Add new medicine :</Typography>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 1 }} justifyContent="center">
          <Grid item lg={4} md={5} sm={6} xs={12} sx={{ mb: 3 }}>
            {/* <MedicineAutoSuggest label="Search Medicine" onAddElement={setMedicineName} /> */}
            <PrescriptionParamAutoSuggest
              suggestValue={autoSuggestValue}
              label="Add new Medicine"
              resetVal={medName}
              onAddElement={addNewMedicineName}
              autoSuggestUrl={FETCH_MEDICINE_AUTOSUGGEST}
              resetAfterSelect={false}
            />
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="medicinedosage" size="small">
                Dosage
              </InputLabel>
              <Select
                labelId="medicinedosage"
                id="demo-simple-select"
                label="Dosage"
                size="small"
                value={medDosage}
                onChange={handleChange("dosage")}
                defaultValue={""}
              >
                {Object.keys(dosageMap).map(function (key) {
                  return (
                    <MenuItem value={key} key={key}>
                      {dosageMap[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="medicinepreference" size="small">
                Preference
              </InputLabel>
              <Select
                labelId="medicinepreference"
                id="select-preference"
                label="Preference"
                size="small"
                onChange={handleChange("preference")}
                value={preference}
                defaultValue={""}
              >
                {Object.keys(preferenceMap).map(function (key) {
                  return (
                    <MenuItem value={key} key={key}>
                      {preferenceMap[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="medicinefrquency" size="small">
                Frequency
              </InputLabel>
              <Select
                labelId="medicinefrquency"
                id="demo-simple-select"
                label="Frequency"
                size="small"
                value={medFreq}
                onChange={handleChange("frequency")}
                defaultValue={""}
              >
                {Object.keys(frequencyMap).map(function (key) {
                  return (
                    <MenuItem value={key} key={key}>
                      {frequencyMap[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={3} md={2} sm={6} xs={12} sx={{ mb: 3 }}>
            <TextField
              type="number"
              size="small"
              id="days"
              label="Days"
              value={medDays}
              onChange={handleChange("days")}
            />
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6} sx={{ mb: 3 }}>
            <Button variant="outlined" onClick={addNewMedicineRow}>
              Add Medicne
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <Divider sx={{ marginBottom: 0 }} />
        </Grid>
        <Grid item md={12} sx={{ mb: 3 }}>
          <TableContainer component={Paper}>
            <Table striped="true" responsive="true">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Medicine</StyledTableCell>
                  <StyledTableCell align="right">Dosage</StyledTableCell>
                  <StyledTableCell align="right">Preference</StyledTableCell>
                  <StyledTableCell align="right">Frequency</StyledTableCell>
                  <StyledTableCell align="right">Days</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {medicines?.map((row) => (
                  <StyledTableRow key={row.qid}>
                    <StyledTableCell component="th" scope="row">
                      {row.medicineName}{" "}
                      {row?.composition && <small>({row?.composition})</small>}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {dosageMap[row.dosage]}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {preferenceMap[row.preference]}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {frequencyMap[row.frequency]}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.days}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        size="small"
                        onClick={() => deleteMedicine(row.qid)}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default MedicineConfig;
