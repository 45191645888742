import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import IconButton from "@mui/material/IconButton";
import ChevronUp from "mdi-material-ui/ChevronUp";
import ChevronDown from "mdi-material-ui/ChevronDown";
import { GET_PAYMENT_REQUEST_BY_PROCESS_ID } from "../../util/ApiUrls";
import useAxiosProtected from "../../hooks/useAxiosProtected";

const orderStatusObj = {
  DRAFT: { color: "default", name: "Draft" },
  CREATED: { color: "warning", name: "Created" },
  ORDERED: { color: "warning", name: "Ordered" },
  PAYMENT_COMPLETED: { color: "info", name: "Payment Completed" },
  FINALIZED: { color: "success", name: "Completed" },
  FAILED: { color: "error", name: "Failed" },
};

const paymentStatusObj = {
  CREDIT: { color: "success", name: "Recieved" },
  FAILED: { color: "error", name: "Failed" },
  PENDING: { color: "warning", name: "Pending" },
  REFUNDED: { color: "info", name: "Refunded" },
};

const MODULES = {
  PRESCRIPTION: {
    name: "Prescription Writer",
  },
};

const Row = (props) => {
  const axiosProtected = useAxiosProtected();
  // ** Props
  const { row } = props;

  const [open, setOpen] = useState(false);

  const completePendingPayment = async (processId) => {
    console.log(processId);
    try {
      await axiosProtected
        .get(GET_PAYMENT_REQUEST_BY_PROCESS_ID + processId)
        .then((response) => {
          console.log(response);
          if (response?.data?.redirect) {
            window.location.href = response?.data?.redirect;
          }
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <TableRow
        hover
        key={row.name}
        sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ py: (theme) => `${theme.spacing(0.5)} !important` }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "0.875rem !important" }}
            >
              {row.orderId}
            </Typography>
            <Typography variant="caption">
              {row?.planType == "B" && "BONUS"}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Moment format="DD-MMM-YYYY">{row.createdDate}</Moment>
        </TableCell>
        <TableCell>
          <Chip
            label={paymentStatusObj[row?.paymentStatus]?.name}
            color={paymentStatusObj[row?.paymentStatus]?.color}
            sx={{
              height: 24,
              fontSize: "0.75rem",
              textTransform: "capitalize",
              "& .MuiChip-label": { fontWeight: 500 },
            }}
          />
        </TableCell>
        <TableCell>
          <Chip
            label={orderStatusObj[row?.orderStatus]?.name}
            color={orderStatusObj[row?.orderStatus]?.color}
            sx={{
              height: 24,
              fontSize: "0.75rem",
              textTransform: "capitalize",
              "& .MuiChip-label": { fontWeight: 500 },
            }}
          />
        </TableCell>
        <TableCell>
          {row?.orderStatus == "ORDERED" && row?.paymentStatus == "PENDING" && (
            <Button onClick={() => completePendingPayment(row?.processId)}>
              Continue Payment
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ py: "0 !important" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2 }}>
              <Typography variant="h7" gutterBottom component="div">
                Order Details
              </Typography>
              <Table size="small" aria-label="purchases" sx={{ mb: 10 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Order Details</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.orderLineVOs?.map((detailRow) => (
                    <TableRow key={detailRow.date}>
                      <TableCell component="th" scope="row">
                        {detailRow?.productType == "P" && "Plan"}
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.875rem !important",
                          }}
                        >
                          {MODULES?.[detailRow?.module]?.name}
                        </Typography>
                        <Typography variant="caption"></Typography>
                      </TableCell>
                      <TableCell>&#8377; {detailRow?.price}</TableCell>
                      <TableCell>
                        <Chip
                          label={orderStatusObj[detailRow?.orderStatus]?.name}
                          color={orderStatusObj[detailRow?.orderStatus]?.color}
                          sx={{
                            height: 24,
                            fontSize: "0.75rem",
                            textTransform: "capitalize",
                            "& .MuiChip-label": { fontWeight: 500 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const OrdersList = (props) => {
  const { orders } = props;

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  return (
    <Card>
      <TableContainer>
        <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Order ID</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Order Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders && orders.map((row) => <Row key={row.orderId} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OrdersList;
