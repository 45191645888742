import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ButtonClose from "../../../components/button/ButtonClose";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import {
  ADD_DOCTOR_PROCEDURES_CONFIG,
  DELETE_DOCTOR_PROCEDURES_CONFIG,
  FETCH_DOCTOR_PROCEDURES_CONFIG,
  FETCH_PROCEDURE_AUTOSUGGEST,
  FETCH_SPECIALIZATION_PARAMS_SUGGEST,
} from "../../../util/ApiUrls";
import PrescriptionParamAutoSuggest from "../../../components/autosuggest/PrescriptionParamAutoSuggest";
import { format } from "react-string-format";
import { useOutletContext } from "react-router-dom";

const ProcedureConfig = (props) => {
  const [addedProcedures, setAddedProceduress] = useState([]);
  const [suggestedProcedures, setSuggestedProcedures] = useState([]);
  const axiosProtected = useAxiosProtected();
  const [autoSuggestValue, setAutoSuggestValue] = useState("");

  const { user } = useOutletContext();

  const loadAddedProcedures = async () => {
    try {
      await axiosProtected
        .get(FETCH_DOCTOR_PROCEDURES_CONFIG)
        .then((response) => {
          console.log(response);

          setAddedProceduress((prev) => {
            console.log("prev {}", prev);
            return response?.data?.data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const loadSuggestedProcedures = async () => {
    try {
      await axiosProtected
        .get(
          format(
            FETCH_SPECIALIZATION_PARAMS_SUGGEST,
            user?.doctor?.specialization,
            "tests"
          )
        )
        .then((response) => {
          const autoConfig = response?.data?.data;
          console.log("list ", autoConfig);

          addedProcedures.forEach((element) => {
            const ind = autoConfig.findIndex(
              (a) => a.trim() == element?.name?.trim()
            );
            ind >= 0 && autoConfig.splice(ind, 1);
          });

          console.log("updated list ", autoConfig);

          setSuggestedProcedures((prev) => {
            console.log("prev {}", prev);
            return autoConfig;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const removeConfig = async (event) => {
    const id = event.target.id;
    const procId = id?.split("_")?.[1];
    try {
      await axiosProtected
        .delete(DELETE_DOCTOR_PROCEDURES_CONFIG + procId)
        .then((response) => {
          console.log(response);
          loadAddedProcedures();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewConfig = async (value) => {
    console.log(value);
    try {
      await axiosProtected
        .post(ADD_DOCTOR_PROCEDURES_CONFIG + "?procedure=" + value?.value)
        .then((response) => {
          console.log(response);
          setAutoSuggestValue("");
          loadAddedProcedures();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addConfig = (e, v) => {
    const newData = e.target.textContent?.trim();
    console.log(e.target.textContent?.trim());
    addNewConfig({ value: newData });
  };

  useEffect(() => {
    loadAddedProcedures();
    loadSuggestedProcedures();
  }, []);

  useEffect(() => {
    loadSuggestedProcedures();
  }, [addedProcedures]);

  return (
    <>
      <Grid container sx={{ mb: 3 }}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          justifyContent={"right"}
          sx={{ mb: 3, display: "flex", justifyContent: "right" }}
        >
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            sx={{ mb: 3, justifyContent: "right" }}
          >
            <PrescriptionParamAutoSuggest
              suggestValue={autoSuggestValue}
              label="Add new Procedure"
              onAddElement={addNewConfig}
              resetAfterSelect={true}
              autoSuggestUrl={FETCH_PROCEDURE_AUTOSUGGEST}
            />
          </Grid>
        </Grid>
        {suggestedProcedures.length > 0 && (
          <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
            Suggested Procedures
          </Grid>
        )}
        <Grid item lg={12} sx={{ mb: 3 }}>
          {suggestedProcedures &&
            suggestedProcedures.map((item, index) => {
              return (
                <Button
                  sx={{ mr: 5, mb: 2 }}
                  onClick={addConfig}
                  variant={props.selected ? "contained" : "outlined"}
                  size="small"
                  color="secondary"
                  id={"procedSuggestBtn_" + index}
                >
                  {item}
                </Button>
              );
            })}
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
          Selected Procedures
        </Grid>
        <Grid item lg={12} sx={{ mb: 3 }}>
          {addedProcedures ? (
            addedProcedures.map((item, index) => {
              return (
                <ButtonClose
                  label={item.name}
                  id={"proceBtn_" + item.value}
                  key={index}
                  onRemoveElement={removeConfig}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              No Procedures/Tests selected{" "}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProcedureConfig;
