import React, { useEffect, useState } from "react";
import UserLayoutDefault from "../../layouts/UserLayoutDefault";
import AutoConfig from "./firsttime/AutoConfig";
import CurtainAnimation from "./firsttime/CurtainAnimation";
import FirstTimeConfigMain from "./firsttime/FirstTimeConfigMain";

const GetStarted = (props) => {
  return (
    // <CurtainAnimation textOnCurtain={"Welcome to MyRx"}>
    <FirstTimeConfigMain {...props} />
    //  </CurtainAnimation>
  );
};

export default GetStarted;
