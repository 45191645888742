import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Footer from "../core/layouts/components/shared/footer/Footer";
import Logo from "../components/Logo";
import { useSettings } from "../core/hooks/useSettings";
import { useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import SessionLayout from "./SessionLayout";

const HeaderLayoutWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",

  "& .content-center": {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5),
  },
  "& .content-right": {
    display: "flex",
    minHeight: "100vh",
    overflowX: "hidden",
    position: "relative",
  },
}));

const HeaderLayout = (props) => {
  const { settings, checkLoading } = useSettings();
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const { children } = props;

  return (
    <>
      <SessionLayout>
        {checkLoading ? (
        <HeaderLayoutWrapper className="layout-wrapper">
          <Box
            className="app-content"
            sx={{
              minHeight: "100vh",
              overflowX: "hidden",
              position: "relative",
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              sx={{ px: 20, py: 5 }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Logo width="50" height="50" /> &nbsp;
                  <Typography
                    variant="h6"
                    sx={{
                      nl: 3,
                      lineHeight: 1,
                      fontWeight: 600,
                      fontSize: "1.5rem !important",
                    }}
                  >
                    {/* MyRx */}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                Just a few steps and you are ready to start your digital journey
              </Grid>
            </Grid>
            <Grid>
              <Outlet />
            </Grid>
          </Box>

          <Footer settings={settings} {...props} />
        </HeaderLayoutWrapper>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            checkLoading:{checkLoading}
            <CircularProgress />
          </div>
        )}
      </SessionLayout>
    </>
  );
};

export default HeaderLayout;
