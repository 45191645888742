import React from "react";
import HomeOutline from "mdi-material-ui/HomeOutline";
import useNextPageRedirect, {
  BROWSE_PLANS,
  PATIENT_QUERIES,
  PRESCRIPTION_CONFIG_USER,
  PRESCRIPTION_WRITE,
  SHOPPING_CART,
  VIEW_MY_ORDERS,
  VIEW_MY_PLANS,
} from "../util/AppUrls";

const Navigation = (props) => {
  const redirect = useNextPageRedirect();
  const redirectPrescriptionUrl = async () => {
    redirect("MYRX_PRESCRIPTION_WRITER");
  };

  return [
    {
      title: "Dashboard",
      icon: HomeOutline,
      path: undefined,
      method: () => redirect("REDIRECT_HOME"),
    },
    {
      sectionTitle: "Prescription",
    },
    {
      title: "Write Prescription",
      icon: HomeOutline,
      path: undefined,
      method: () => redirect("MYRX_PRESCRIPTION_WRITER"),
    },
    // {
    //   title: "Write Prescription New",
    //   icon: HomeOutline,
    //   path: PRESCRIPTION_WRITE,
    // },
    // {
    //   title: "Configuration",
    //   icon: HomeOutline,
    //   path: PRESCRIPTION_CONFIG_USER,
    // },
    {
      sectionTitle: "Orders",
    },
    {
      title: "Explore Plans",
      icon: HomeOutline,
      path: BROWSE_PLANS,
    },
    {
      title: "Cart",
      icon: HomeOutline,
      path: SHOPPING_CART,
    },
    {
      title: "My Plans",
      icon: HomeOutline,
      path: VIEW_MY_PLANS,
    },
    {
      title: "My Orders",
      icon: HomeOutline,
      path: VIEW_MY_ORDERS,
    },
    {
      sectionTitle: "Patient",
    },
    {
      title: "Queries",
      icon: HomeOutline,
      path: PATIENT_QUERIES,
    },
  ];
};

export default Navigation;
