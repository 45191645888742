import { Grid, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosProtected from '../../hooks/useAxiosProtected';
import { UPDATE_ORDER_PAYMENT_STATUS } from '../../util/ApiUrls';
import { VIEW_MY_PLANS } from '../../util/AppUrls';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OrderPaymentConfirm = () => {

    const axiosProtected = useAxiosProtected()
    const navigate = useNavigate();
    const [paymentCompleted, setPaymentCompleted] = useState(false)
    let query = useQuery();

    useEffect(() => {
        confirmOrderPayment()
    }, [])


    useEffect(() => {
        if (paymentCompleted) {
            setTimeout(() => { navigate(VIEW_MY_PLANS, { replace: true }) }, 2000)

        }
    }, [paymentCompleted])

    const confirmOrderPayment = async () => {

        const data = {
            payment_id: query.get("payment_id"),
            payment_status: query.get("payment_status"),
            payment_request_id: query.get("payment_request_id")
        }

        await axiosProtected.put(
            UPDATE_ORDER_PAYMENT_STATUS,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                console.log(response)
                setPaymentCompleted(true)

            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <div>
                {paymentCompleted ? (
                    <div>Order created please wait for a while till the credits are given</div>
                ) : (
                    <Grid container direction={'column'}>
                        <Typography variant="body2" color="text.secondary" marginBottom={5}>
                            Confirming your Plan<br />
                        </Typography>
                        <LinearProgress />
                    </Grid>
                )}
            </div>
        </div>
    )
}

export default OrderPaymentConfirm