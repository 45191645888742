import { Grid, Button } from "@mui/material";
import React, { useState } from "react";
import PatientDetails from "../../components/prescription/module/PatientDetails";
import VitalDetails from "../../components/prescription/module/VitalDetails";
import PrescriptionLayout from "../../components/prescription/PrescriptionLayout";
import PrescriptionModulesList from "../../components/prescription/PrescriptionModulesList";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import ArrowRight from "mdi-material-ui/ArrowRight";
import SymptomsDetails from "../../components/prescription/module/SymptomsDetails";
import MedicineDetails from "../../components/prescription/module/MedicineDetails";

const config = {
  tabs: [
    {
      tab: "PATIENT_DETAIL",
    },
    {
      tab: "VITALS",
      config: {
        vitals: [
          {
            value: "showVitalWeight",
          },
          {
            value: "showVitalHeight",
            unit: {
              value: "inches",
            },
          },
          {
            value: "showVitalBMI",
          },
          {
            value: "showVitalBP",
          },
          {
            value: "custom_1",
            name: "Custom Text",
            type: "text",
            precision: undefined,
            unit: {
              type: "label",
              value: "pointer",
            },
          },
          {
            value: "custom_2",
            name: "Custom Number",
            type: "number",
            precision: undefined,
            unit: {
              type: "label",
              value: "pointer",
            },
          },
          {
            value: "custom_3",
            name: "Custom Radio",
            type: "radio",
            options: ["Australia", "Bhutan", "Chile", "Denmark"],
            precision: undefined,
            unit: {
              type: "label",
              value: "pointer",
            },
          },
          {
            value: "custom_4",
            name: "Custom Dropdown",
            type: "dropdown",
            options: ["APPLE", "BANANA", "CARROT"],
            precision: undefined,
            unit: {
              type: "label",
              value: "pointer",
            },
          },
          {
            value: "custom_5",
            name: "Custom Checkbox",
            type: "checkbox",
            options: ["Australia", "Bhutan", "Chile"],
            precision: undefined,
            unit: {
              type: "label",
              value: "pointer",
            },
          },
        ],
      },
    },
    {
      tab: "SYMPTOMS",
    },
    {
      tab: "MEDICINE",
    },
    {
      tab: "SPECTACLE",
    },
  ],
};

const getModuleRender = (config) => {
  const tab = config?.tab;
  console.log(config);
  switch (tab) {
    case "PATIENT_DETAIL":
      return <PatientDetails {...config} />;
    case "VITALS":
      return <VitalDetails {...config} />;
    case "SYMPTOMS":
      return <SymptomsDetails {...config} />;
    case "DIAGNOSIS":
      return <div>No Screen Available for the selected module</div>;
    case "PROCEDURE":
      return <div>No Screen Available for the selected module</div>;
    case "ADVISE":
      return <div>No Screen Available for the selected module</div>;
    case "MEDICINE":
      return <MedicineDetails {...config} />;
    default:
      return <div>No Screen Available for the selected module</div>;
      break;
  }
};

const Prescription = (props) => {
  const [allModules, setAllModules] = useState(PrescriptionModulesList);
  const [currentStep, setCurrentStep] = useState(0);
  const _next = () => {
    const maxSteps = config?.tabs?.length - 1;
    if (currentStep >= maxSteps) {
      setCurrentStep(maxSteps);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const _prev = () => {
    if (currentStep <= 0) {
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <div>
      <PrescriptionLayout
        config={config}
        modules={allModules}
        currentStep={currentStep}
        changeStep={setCurrentStep}
      >
        {config?.tabs?.map((configModule, index) => {
          return allModules?.map((module, index) => {
            if (module.value == configModule.tab) {
              return (
                <Grid
                  container
                  key={index}
                  sx={{ display: currentStep !== index && "none" }}
                >
                  {getModuleRender(configModule)}
                </Grid>
              );
            }
          });
        })}
        <Grid container justifyContent="right" alignItems="center">
          {currentStep < config?.tabs?.length && (
            <Button onClick={_next}>
              <ArrowRight /> &nbsp; Continue to next step
            </Button>
          )}
          {/* {currentStep > 1 && (
            <Button onClick={_prev}>
              {" "}
              <ArrowLeft /> &nbsp; Back
            </Button>
          )} */}
        </Grid>
      </PrescriptionLayout>
    </div>
  );
};

export default Prescription;
