import {
  Alert,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useSessionStateValue } from "../../context/SessionContext";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import { EMAIL_VERIFY_OTP_VERIFY } from "../../util/ApiUrls";
import useNextPageRedirect, { HOME_PAGE } from "../../util/AppUrls";
import { secondsCountdown } from "../../util/CommonUtils";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const EmailVerificationRequest = () => {
  const [{ user }, dispatch] = useSessionStateValue();
  const [counter, setCounter] = useState();
  const [seconds, setSeconds] = useState(5);
  const [redirectUrl, setRedirectUrl] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const axiosProtected = useAxiosProtected();
  const redirect = useNextPageRedirect();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  let query = useQuery();

  const verifyEmailLink = async () => {
    setProcessing(true);
    try {
      const req = query.get("q");
      await axios
        .post(EMAIL_VERIFY_OTP_VERIFY + encodeURIComponent(req))
        .then((response) => {
          console.log(response);
          if (response?.status == 200) {
            setRedirectUrl(response?.data?.data?.redirect_url);
            setEmailVerified(true);
            setProcessing(false);
            counter && clearInterval(counter) && setCounter(undefined);
            setCounter(secondsCountdown(5, setSeconds));
          }
        })
        .catch((err) => {
          console.log(err?.response?.data?.error?.message);
          if (!err?.response) {
            setErrMsg("No server response");
          } else if (
            err.response?.status === 503 ||
            err.response?.status === 400
          ) {
            setErrMsg(err?.response?.data?.error?.message);
          } else {
            setErrMsg("Error occured While Verifying Email");
          }
          setProcessing(false);
          setToastOpen(true);
        });
    } catch (err) {
      console.log(err);
      setErrMsg("Exception occured While Verifying OTP");
      setProcessing(false);
      setToastOpen(true);
    }
  };

  const redirectTo = (redirect) => {
    redirectUrl
      ? navigate(redirectUrl || from, { replace: true })
      : redirect("REDIRECT_HOME");
  };

  useEffect(() => {
    verifyEmailLink();
  }, []);

  useEffect(() => {
    if (seconds < 1) redirectTo();
  }, [seconds]);

  return (
    <Grid>
      <Grid>
        <Grid container direction={"column"}>
          <Snackbar
            open={toastOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {errMsg}
            </Alert>
          </Snackbar>
          <Typography variant="body2" color="text.secondary" marginBottom={5}>
            Verifying your Email
            <br />
            Click here to go to <Link to={HOME_PAGE}>Home</Link>
          </Typography>
          {processing ? (
            <LinearProgress />
          ) : (
            emailVerified && (
              <Grid container justifyContent={"center"}>
                Your Email has been verified. Please wait {seconds}s while we
                redirect you.
                <br />
                or click here to go to Home <Link to={HOME_PAGE}>Home</Link>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationRequest;
