import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { FETCH_PATIENT_AUTOSUGGEST } from "../../util/ApiUrls";
import useAxiosProtected from "../../hooks/useAxiosProtected";

const PatientDetailsAutoSuggest = (props) => {
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState({ value: "" });
  const [errMsg, setErrMsg] = useState("");
  const previousController = useRef();
  const filter = createFilterOptions();
  const axiosProtected = useAxiosProtected();

  const getOptionsData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    axiosProtected
      .get(FETCH_PATIENT_AUTOSUGGEST + searchTerm)
      .then((response) => {
        console.log(response);
        const newOptions = response?.data?.data?.map(function (item) {
          return { value: item["name"], itemVal: item["value"] };
        });
        setOptions(newOptions || []);
      })
      .catch((err) => {
        console.log(err);
        if (!err?.response) {
          setErrMsg("No server response");
        } else if (err?.response?.status == 400) {
          setErrMsg(err?.response?.data?.error?.message);
        } else {
          setErrMsg("Failed");
        }
      });
  };

  const onSuggestInputChange = (event, value, reason) => {
    if (value && value.length > 2) {
      getOptionsData(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      size="small"
      disablePortal
      id={props.id}
      options={options}
      onChange={(e, v) => {
        console.log(v);
        if (v == undefined) {
          return;
        }
        if (typeof v === "string") {
          props.onAddElement({ value: v });
          props.resetAfterSelect
            ? setCurrentValue({ value: "" })
            : setCurrentValue({ value: v });
        } else {
          props.onAddElement(v);
          props.resetAfterSelect
            ? setCurrentValue({ value: "" })
            : setCurrentValue(v);
        }
      }}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      onInputChange={onSuggestInputChange}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.value) {
          return option.value;
        }
        return option.value;
      }}
      isOptionEqualToValue={(opt, val) => opt.value == val.value}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.value
        );
        // if (inputValue !== "" && !isExisting) {
        //   filtered.push({
        //     value: inputValue,
        //   });
        // }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      value={currentValue}
    />
  );
};

export default PatientDetailsAutoSuggest;
