import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import { UPDATE_DOCTOR_CITY } from "../../../util/ApiUrls";
import useNextPageRedirect from "../../../util/AppUrls";

const UpdateCity = (props) => {
  const t = (mesage) => mesage;
  const { setAuth, persist, setPersist } = useAuth();
  const [values, setValues] = useState();
  const [errMsg, setErrMsg] = useState("");
  const axiosProtected = useAxiosProtected();
  const navigate = useNavigate();
  const redirect = useNextPageRedirect();
  const { userProfile } = props;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const updateUserCity = async () => {
    try {
      await axiosProtected
        .put(
          UPDATE_DOCTOR_CITY + values?.city,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response?.data?.redirect);
          const redirectUrl = redirect(response?.data?.redirect);
          console.log(redirectUrl);
          navigate(redirectUrl || "/", { replace: true });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 1.5 }}>
          Hello! Dr. {userProfile?.firstName} {userProfile?.lastName}
        </Typography>
        <Typography variant="h6">
          From which city are you practicing?
        </Typography>
      </Box>
      {errMsg}
      <form
        noValidate
        autoComplete={"off"}
        onSubmit={(e) => e.preventDefault()}
      >
        <TextField
          autoFocus
          fullWidth
          autoComplete={"off"}
          id="city"
          label="City"
          onChange={handleChange("city")}
          sx={{ mb: 3 }}
        />
      </form>
      <Button
        fullWidth
        size="large"
        variant="contained"
        sx={{ marginBottom: 7 }}
        onClick={updateUserCity}
      >
        Continue
      </Button>
    </div>
  );
};

export default UpdateCity;
