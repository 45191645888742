import { Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import "./App.css";
import AuthLayout from "./layouts/auth/AuthLayout";
import Layout from "./layouts/Layouts";
import UserLayout from "./layouts/UserLayout";
import Login from "./views/login/Login";
import {
  SettingsConsumer,
  settingsContext,
  SettingsProvider,
} from "./core/context/settingsContext";
import { createEmotionCache } from "./core/utils/createEmotionCache";
import ThemeComponent from "./core/theme/ThemeComponent";
import { useContext } from "react";
import Register from "./views/login/Register";
import GetStarted from "./views/doctor/GetStarted";
import UpdateProfile from "./views/doctor/firsttime/UpdateProfile";
import HeaderLayout from "./layouts/HeaderLayout";
import {
  BROWSE_PLANS,
  CATALOG_ADD_PLANS,
  CATALOG_VIEW_PLANS,
  CONFIG_PLANS,
  CREATE_ABHA_NUMBER,
  CREATE_ABHA1_NUMBER,
  HOME_PAGE,
  LOGIN_URL,
  LOGOUT_URL,
  MADHAVBAUG_DOCTOR_SIGN_UP,
  MADHAVBAUG_PCE_SIGN_UP,
  ORDER_PAYMENT_CONFIRM,
  PATIENT_QUERIES,
  PRESCRIPTION_CONFIG_USER,
  PRESCRIPTION_WRITE,
  PROFILE_UPDATE,
  REDIRECT_USER,
  SHOPPING_CART,
  SHOPPING_CHECKOUT,
  USER_EMAIL_VERIFY_CONFIRM,
  USER_GET_STARTED,
  USER_PROFILE_UPDATE,
  USER_SIGN_UP,
  USER_VERIFY_EMAIL,
  USER_VERIFY_PHONE,
  VIEW_MY_ORDERS,
  VIEW_MY_PLANS,
} from "./util/AppUrls";
import BrowsePlans from "./views/shopping/plans/BrowsePlans";
import UserLayoutDefault from "./layouts/UserLayoutDefault";
import ShoppingCartProvider from "./context/ShoppingCartProvider";
import ShoppingCart from "./views/shopping/cart/ShoppingCart";
import CheckOut from "./views/shopping/checkout/CheckOut";
import RequireAuth from "./services/RequireAuth";
import Test from "./views/Test";
import OrderPaymentConfirm from "./views/shopping/OrderPaymentConfirm";
import MyPlans from "./views/doctor/plans/MyPlans";
import MyOrders from "./views/doctor/order/MyOrders";
import RedirectUser from "./views/login/RedirectUser";
import Logout from "./views/login/Logout";
import FirstTimeConfigMain from "./views/doctor/firsttime/FirstTimeConfigMain";
import UserProfile from "./views/user/UserProfile";
import PrescriptionConfig from "./views/config/prescription/PrescriptionConfig";
import CreateABHA from "./views/ndhm/CreateABHA";
import CreateABHANew from "./views/ndhm/CreateABHANew";
import Prescription from "./views/prescription/Prescription";
import VerifyEmail from "./views/user/VerifyEmail";
import VerifyPhone from "./views/user/VerifyPhone";
import Home from "./views/Home";
import EmailVerificationRequest from "./views/user/EmailVerificationRequest";
import PdfTest from "./views/PdfTest";
import PrescriptionPreview from "./views/PrescriptionPreview";
import { RxSessionProvider } from "./context/SessionContext";
import SessionLayout from "./layouts/SessionLayout";
import ConfigPlans from "./views/plans/config/ConfigPlans";
import MyQueries from "./views/patient/MyQueries";
import MyQueries2 from "./views/patient/MyQueries2";
import AddCatalog from "./views/catalog/AddCatalog";
import RegisterUser from "./views/login/RegisterUser";
import RegisterDoctor from "./views/login/RegisterDoctor";

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const { settings } = useContext(settingsContext);

  // console.log('settings ')
  // console.log(settings)

  // google-authenticate

  return (
    <>
      <CacheProvider value={emotionCache}>
        <SettingsProvider>
          <ThemeComponent settings={settings}>
            <ShoppingCartProvider>
              <RxSessionProvider>
                <Routes>
                  <Route path="/">
                    <Route path={REDIRECT_USER} element={<RedirectUser />} />
                    <Route path={LOGOUT_URL} element={<Logout />} />
                    <Route element={<UserLayout />}>
                      <Route path={USER_SIGN_UP} element={<Register />} />
                      <Route path={LOGIN_URL} element={<Login />} />
                      <Route path={MADHAVBAUG_PCE_SIGN_UP} element={<RegisterUser />} />
                      <Route path={MADHAVBAUG_DOCTOR_SIGN_UP} element={<RegisterDoctor />} />
                      <Route path={CREATE_ABHA_NUMBER} element={<CreateABHA />} />
                      <Route path={CREATE_ABHA1_NUMBER} element={<CreateABHANew />} />
                      <Route path="google-authenticate" element={<Login />} />
                    </Route>

                    <Route element={<HeaderLayout />}>
                      <Route
                        path={PROFILE_UPDATE}
                        element={<UpdateProfile />}
                      />
                      <Route path={SHOPPING_CHECKOUT} element={<CheckOut />} />
                      <Route
                        path={ORDER_PAYMENT_CONFIRM}
                        element={<OrderPaymentConfirm />}
                      />
                      <Route
                        path={USER_VERIFY_EMAIL}
                        element={<VerifyEmail />}
                      />
                      <Route
                        path={USER_VERIFY_PHONE}
                        element={<VerifyPhone />}
                      />
                      <Route
                        path={USER_EMAIL_VERIFY_CONFIRM}
                        element={<EmailVerificationRequest />}
                      />
                      <Route path={HOME_PAGE} element={<Home />} />
                    </Route>
                    {/* <Route element={}> */}
                    <Route element={<UserLayoutDefault />}>
                      <Route path={BROWSE_PLANS} element={<BrowsePlans />} />
                      <Route path={SHOPPING_CART} element={<ShoppingCart />} />
                      <Route path={VIEW_MY_ORDERS} element={<MyOrders />} />
                      <Route path={VIEW_MY_PLANS} element={<MyPlans />} />
                      <Route
                        path={CATALOG_VIEW_PLANS}
                        element={<AddCatalog />}
                      />
                      <Route
                        path={CATALOG_ADD_PLANS}
                        element={<AddCatalog />}
                      />
                      <Route path={PATIENT_QUERIES} element={<MyQueries2 />} />
                      <Route
                        path={USER_PROFILE_UPDATE}
                        element={<UserProfile />}
                      />
                      <Route
                        path={PRESCRIPTION_CONFIG_USER}
                        element={<PrescriptionConfig />}
                      />
                      <Route path={USER_GET_STARTED} element={<GetStarted />} />
                      <Route
                        path={PRESCRIPTION_WRITE}
                        element={<Prescription />}
                      />
                      <Route path={CONFIG_PLANS} element={<ConfigPlans />} />
                    </Route>
                    {/* </Route> */}
                    <Route
                      element={
                        <RequireAuth
                          allowedRoles={["ADMIN", "USER", "enhance"]}
                        />
                      }
                    >
                      <Route path="/test" element={<Test />} />
                    </Route>
                    <Route path="/pdftest" element={<PdfTest />} />
                    <Route
                      path="/prescriptionpreview"
                      element={<PrescriptionPreview />}
                    />
                  </Route>
                </Routes>
              </RxSessionProvider>
            </ShoppingCartProvider>
          </ThemeComponent>
        </SettingsProvider>
      </CacheProvider>
    </>
  );
};

export default App;
