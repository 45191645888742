import { Button, Grid, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import { createCountDown, matchIsNumeric } from "../../util/CommonUtils";

const OTPInput = (props) => {
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState("");
  const [countdownMinute, setCountdownMinute] = useState(1.3);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const { setLoading, user, sendOTP, setOTP, otpSent, handleOtpVerify } = props;

  const handleChange = (newValue) => {
    setOtp(newValue);
    setOTP(newValue);
  };

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const startCountdown = () => {
    counter && clearInterval(counter) && setCounter(undefined);
    const min = Math.floor(countdownMinute);
    const secs = Math.floor((countdownMinute % 1) * 100);
    setCounter(createCountDown(min, secs, setMinutes, setSeconds));
  };

  useEffect(() => {
    minutes === 0 && seconds === 0 && setCountdownCompleted(true);
  }, [minutes, seconds]);

  useEffect(() => {
    otpSent && startCountdown();
  }, [otpSent]);

  return (
    <Grid item lg={6} md={6} sm={8} xs={12} sx={{ m: 5 }}>
      {otpSent && (
        <>
          <Grid container sx={{ mb: 5 }}>
            <Typography variant="body">
              We have sent a verification OTP on mobile number{" "}
              <b>XXXXXX{user?.mobile?.substr(user?.mobile?.length - 4)}</b>
              <br />
            </Typography>
            <Typography variant="body" sx={{ mb: 5 }}>
              Please enter the OTP below
            </Typography>
          </Grid>

          <Grid container justifyContent={"center"}>
            <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mb: 5 }}>
              <MuiOtpInput
                value={otp}
                gap={2}
                onChange={handleChange}
                onComplete={handleOtpVerify}
                length={6}
                TextFieldsProps={{ disabled: false, placeholder: "" }}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} justifyContent={"center"}>
              {minutes === 0 && seconds === 0 ? (
                <Button onClick={sendOTP} fullWidth variant="contained">
                  Resend OTP
                </Button>
              ) : (
                <Button onClick={startCountdown} fullWidth variant="outlined">
                  Wait {minutes < 10 ? "0" + minutes : minutes}:
                  {seconds < 10 ? "0" + seconds : seconds} for resend otp
                </Button>
              )}
              {/* <Button onClick={sendOTP} fullWidth variant="outlined">
            SEND OTP
          </Button> */}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OTPInput;
