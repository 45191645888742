import React, { createContext, useState } from 'react'
import themeConfig from '../../configs/themeConfig'

const initialSettings = {
    themeColor: 'primary',
    mode: themeConfig.mode,
    contentWidth: themeConfig.contentWidth
}

export const settingsContext = createContext({
    saveSettings: () => null,
    settings: initialSettings,
    setLoading: () => null
})

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({ ...initialSettings })
    const [isLoading, setIsLoading] = useState(false);

    const checkLoading = () => isLoading;

    const saveSettings = updatedSettings => {
        setSettings(updatedSettings)
    }

    return <settingsContext.Provider value={{ settings, saveSettings, checkLoading, setIsLoading }} >{children}</settingsContext.Provider>

}

export const SettingsConsumer = settingsContext.Consumer
