import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import PrescriptionParamAutoSuggest from "../autosuggest/PrescriptionParamAutoSuggest";
import {
  CATEGORY_LIST_AUTOSUGGEST,
  FETCH_DIAGNOSIS_AUTOSUGGEST,
  FETCH_SYMPTOMS_AUTOSUGGEST,
} from "../../util/ApiUrls";
import CareDetailsPlanner from "./CareDetailsPlanner";

const AddCarePlans = () => {
  const [autoSuggestValue, setAutoSuggestValue] = useState("");

  const addNewConfig = async (value) => {};
  return (
    <Grid container fullWidth>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
        sx={{
          width: "100%",
          mb: 4,
        }}
      >
        <FormControl row>
          {/* <FormLabel id="care-plan-category-label">
            Care Plan Category
          </FormLabel> */}
          <PrescriptionParamAutoSuggest
            size="normal"
            suggestValue={autoSuggestValue}
            label="Search Category"
            onAddElement={addNewConfig}
            resetAfterSelect={false}
            autoSuggestUrl={CATEGORY_LIST_AUTOSUGGEST}
            sx={{ width: 300 }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
        sx={{
          width: "100%",
          mb: 4,
        }}
      >
        <FormControl>
          {/* <FormLabel id="care-plan-ref-name-label">
            Care Plan Reference Name
          </FormLabel> */}
          <TextField
            fullWidth
            label="Care Plan Reference Name"
            id="fullWidth"
            sx={{ width: 300 }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
        sx={{
          width: "100%",
          mb: 4,
        }}
      >
        <FormControl>
          {/* <FormLabel id="care-plan-name-label">Care Plan Name</FormLabel> */}
          <TextField
            fullWidth
            label="Care Plan Name"
            id="fullWidth"
            sx={{ width: 300 }}
          />
        </FormControl>
      </Grid>
      {/* <Box
        sx={{
          width: "100%",
        }}
      >
        <FormControl>
          <FormLabel id="care-plan-duration-type-label">
            Plan Duration Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="care-plan-duration-type-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="D" control={<Radio />} label="Daily" />
            <FormControlLabel value="W" control={<Radio />} label="Weekly" />
            <FormControlLabel value="M" control={<Radio />} label="Monthly" />
          </RadioGroup>
        </FormControl>
      </Box> */}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        sx={{
          width: "100%",
          mb: 4,
        }}
      >
        <CareDetailsPlanner />
      </Grid>
    </Grid>
  );
};

export default AddCarePlans;
