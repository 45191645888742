import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import {
  FETCH_DOCTOR_SYMPTOMS_CONFIG,
  FETCH_SYMPTOMS_AUTOSUGGEST,
} from "../../../util/ApiUrls";
import { Button, Grid, Typography, Paper, Divider, Chip } from "@mui/material";
import PrescriptionParamAutoSuggest from "../../autosuggest/PrescriptionParamAutoSuggest";

const SymptomsDetails = (props) => {
  const [selectedSymptoms, setSelectedymptoms] = useState([]);
  const [addedSymptoms, setAddedSymptoms] = useState([]);
  const [autoSuggestValue, setAutoSuggestValue] = useState("");

  const axiosProtected = useAxiosProtected();
  const { user } = useOutletContext();

  //APIs
  const loadAddedSymptoms = async () => {
    try {
      await axiosProtected
        .get(FETCH_DOCTOR_SYMPTOMS_CONFIG)
        .then((response) => {
          console.log(response);

          setAddedSymptoms((prev) => {
            console.log("prev {}", prev);
            return response?.data?.data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //UseEffects
  useEffect(() => {
    loadAddedSymptoms();
  }, []);

  //Methods
  const addNewSymptom = (value) => {
    console.log(value);
  };
  const addSelectedSymptom = (e) => {
    console.log(e);
  };
  return (
    <Paper elevation={3} sx={{ p: 5, width: "100%" }}>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Symptoms:
      </Typography>

      <Divider sx={{ mb: 5 }} />
      <Grid container>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={12}
          justifyContent={"left"}
          flexDirection="column"
          sx={{
            mb: 3,
            pr: 3,
            display: "flex",
            justifyContent: "right",
            borderRight: "2px solid lightgrey",
          }}
        >
          <Typography variant="h6" sx={{ mb: 3 }}>
            Quick Add
          </Typography>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PrescriptionParamAutoSuggest
              suggestValue={autoSuggestValue}
              label="Add new Symptom"
              onAddElement={addNewSymptom}
              resetAfterSelect={true}
              autoSuggestUrl={FETCH_SYMPTOMS_AUTOSUGGEST}
            />
          </Grid>
          {addedSymptoms.length > 0 && (
            <Grid item md={12} sm={12} xs={12} sx={{ mb: 1 }}>
              <Typography variant="body2">
                Select from the list of your quick Symptoms
              </Typography>
            </Grid>
          )}
          {addedSymptoms &&
            addedSymptoms.map((item, index) => {
              return (
                <Grid item md={12} sm={12} xs={12} sx={{ mb: 1 }}>
                  <Chip
                    label={item?.name}
                    variant="outlined"
                    onClick={addSelectedSymptom}
                    sx={{ mr: 5, mb: 2 }}
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Selected Symptoms
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid
        item
        md={12}
        sm={12}
        xs={12}
        justifyContent={"right"}
        sx={{ mb: 3, display: "flex", justifyContent: "right" }}
      >
        <Grid item lg={12} md={8} sm={12} xs={12}>
          <PrescriptionParamAutoSuggest
            suggestValue={autoSuggestValue}
            label="Add new Symptom"
            onAddElement={addNewSymptom}
            resetAfterSelect={true}
            autoSuggestUrl={FETCH_SYMPTOMS_AUTOSUGGEST}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 5 }} />
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        lg={12}
        sx={{ mb: 3, justifyContent: "left" }}
      >
        {addedSymptoms.length > 0 && (
          <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
            <Typography variant="body2">
              Select from the list of your quick Symptoms
            </Typography>
          </Grid>
        )}
        <Grid item lg={12} sx={{ mb: 3 }}>
          {addedSymptoms &&
            addedSymptoms.map((item, index) => {
              return (
                <Chip
                  label={item?.name}
                  variant="outlined"
                  onClick={addSelectedSymptom}
                  sx={{ mr: 5, mb: 2 }}
                />
              );
            })}
        </Grid>
      </Grid> */}
    </Paper>
  );
};

export default SymptomsDetails;
