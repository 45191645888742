import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Moment from "react-moment";

const MODULES = {
  PRESCRIPTION: {
    name: "Prescription Writer",
  },
  WEBSITE: {
    name: "Website",
  },
  BILLING: {
    name: "Billing and Invoice",
  },
  APPOINTMENT_BOOKING: {
    name: "Appointment Bookings",
  },
};

const SubscriptionsList = (props) => {
  const { data } = props?.subscriptions;

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Card>
      <TableContainer>
        <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
          <TableHead>
            <TableRow>
              <TableCell>Plan</TableCell>
              <TableCell>Activation Date</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>Points Available</TableCell>
              <TableCell>Points per Price</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  hover
                  key={row.name}
                  sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ py: (theme) => `${theme.spacing(0.5)} !important` }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "0.875rem !important",
                        }}
                      >
                        {MODULES?.[row?.module]?.name}
                      </Typography>
                      <Typography variant="caption">
                        {row?.planType == "B" && "BONUS"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Moment format="DD-MMM-YYYY">{row.createdDate}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="DD-MMM-YYYY">{row.validity}</Moment>
                  </TableCell>
                  <TableCell>
                    {row.pointsAvailable >= 0
                      ? row.pointsAvailable
                      : "Unlimited"}
                  </TableCell>
                  <TableCell>{row.pointsPerPrice}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.active ? "Active" : "Inactive"}
                      color={row.active ? "success" : "error"}
                      sx={{
                        height: 24,
                        fontSize: "0.75rem",
                        textTransform: "capitalize",
                        "& .MuiChip-label": { fontWeight: 500 },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SubscriptionsList;
