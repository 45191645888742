import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function AadhaarAuthentication({ mobileNumber, onSubmit }) {

    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const [timer, setTimer] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [phone, setPhone] = useState("");
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [phoneError, setPhoneError] = useState("");

    const handleOtpChange = (value) => {
        setOtp(value);
        setError("");
    };

    const handleVerifyOtp = () => {
        if (otp.length !== 6 && otp !== "123456") {
            setError("Please enter a valid 6-digit OTP.");
        } else {
            setError("");
            console.log(`OTP Entered: ${otp}`);
            onSubmit(true);
        }
    }

    const handleResendOtp = () => {
        setTimer(60);
        setResendDisabled(true);
        console.log("Resend OTP triggered");
    };

    const handlePhoneChange = (event) => {
        const phoneValue = event.target.value;
        setPhone(phoneValue);
        setPhoneError("");

        if (phoneValue.length === 10) {
            setIsPhoneVerified(true);
        } else {
            setIsPhoneVerified(false);
        }
    };

    const handleBlur = () => {
        if (phone.length !== 10) {
            setPhoneError("Please enter a valid mobile number.");
        }
    };

    useEffect(() => {
        let interval = null;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setResendDisabled(false);
        }
        return () => clearInterval(interval);
    }, [timer]);

    return (
        <Box sx={{ p: 6 }}>
            <Typography variant="h6" gutterBottom>
                Confirm OTP
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                OTP sent to mobile number ending with ****{mobileNumber}
            </Typography>
            <MuiOtpInput
                value={otp}
                onChange={handleOtpChange}
                length={6}
                // isError={!!error}
                // errorMessage={error}
                autoFocus
                sx={{
                    mb: 2,
                    "& .MuiInputBase-root": {
                        width: "35px",
                        margin: "0 2px",
                    },
                    "& .MuiInputBase-input": {
                        padding: "8px",
                        fontSize: "1rem",
                    },
                }}
            />
            {error && (
                <Typography
                    variant="caption"
                    color="error"
                    sx={{ mt: 1, mb: 2 }}
                    gutterBottom
                >
                    {error}
                </Typography>
            )}
            <Typography variant="body2" sx={{ display: "inline", mb: 2 }}>
                Didn't receive OTP?
            </Typography>
            <Button
                variant="text"
                onClick={handleResendOtp}
                disabled={resendDisabled}
                sx={{ textTransform: "none", fontWeight: "bold" }}
            >
                Resend OTP
            </Button>
            {resendDisabled && (
                <Typography variant="body2" color="textSecondary">
                    Resend available in {timer} seconds
                </Typography>
            )}

            <Typography variant="subtitle1" gutterBottom>
                Mobile Number
            </Typography>
            <TextField
                label="Mobile Number"
                variant="outlined"
                value={phone}
                onChange={handlePhoneChange}
                onBlur={handleBlur}
                sx={{ flexGrow: 1, mb: 5 }}
                inputProps={{
                    maxLength: 10,
                }}
                error={!!phoneError}
                helperText={phoneError || (isPhoneVerified ? "" : "")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            +91
                        </InputAdornment>
                    ),
                    endAdornment: isPhoneVerified && (
                        <InputAdornment position="end">
                            <CheckCircleIcon color="success" />
                        </InputAdornment>
                    ),
                }}
            />
            <Typography variant="caption" gutterBottom sx={{ display: 'flex', mb: 2 }}>
                <InfoOutlinedIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                It is preferable to use your Aadhaar-linked mobile number. If you choose to use a different mobile number,<br />
                it will need to be validated again and will be used for all communication related to ABDM.
            </Typography>

            <Box sx={{ marginTop: "auto" }}>
                <Button variant="contained" color="primary" onClick={handleVerifyOtp}>
                    Verify OTP
                </Button>
            </Box>
        </Box>
    )
}