import { Link } from '@mui/material'
import React from 'react'

const Logo = (props) => {
    return (
        <Link href={props.href}>
        <img loading="lazy" width={props.width || "20"} height={props.height || "20"} 
        src={process.env.REACT_APP_LOGO_PATH_SMALL} 
        alt="MyRx"></img>
        </Link>
    )
}

export default Logo