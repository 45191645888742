import React, { useEffect, useState } from "react";
import PrescriptionModulesList from "../../../components/prescription/PrescriptionModulesList";
import PrescriptionConfigLayout from "../../../components/config/prescription/PrescriptionConfigLayout";
import { reorder } from "react-reorder";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import VitalsConfig from "../../prescription/config/VitalsConfig";
import SymptomsConfig from "../../prescription/config/SymptomsConfig";
import DiagnosisConfig from "../../prescription/config/DiagnosisConfig";
import ProcedureConfig from "../../prescription/config/ProcedureConfig";
import AdviseConfig from "../../prescription/config/AdviseConfig";
import MedicineConfig from "../../prescription/config/MedicineConfig";
import OpthoConfig from "../../prescription/config/OpthoConfig";
const ModuleItem = styled("Grid")({
  border: "1px solid lightgrey",
  color: "grey",
  borderRadius: "5px",
  padding: 5,
  marginBottom: 5,
  minHeight: 50,
});

const getConfigRender = (config) => {
  switch (config) {
    case "VITALS":
      return <VitalsConfig {...config} />;
    case "SYMPTOMS":
      return <SymptomsConfig {...config} />;
    case "DIAGNOSIS":
      return <DiagnosisConfig {...config} />;
    case "PROCEDURE":
      return <ProcedureConfig {...config} />;
    case "ADVISE":
      return <AdviseConfig {...config} />;
    case "MEDICINE":
      return <MedicineConfig {...config} />;
    case "SPECTACLE":
      return <OpthoConfig {...config} />;
    default:
      return <div>No Config Available for the selected module</div>;
      break;
  }
};

const PrescriptionConfig = (props) => {
  const [allModules, setAllModules] = useState(PrescriptionModulesList);
  const [addModules, setAddModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [addModuleOpen, setAddModuleOpen] = useState(false);

  const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    setModules(reorder(modules, previousIndex, nextIndex));
    setCurrentStep(nextIndex);
  };

  const showAddModule = () => {
    const toAdd = [];
    allModules.map((item, index) => {
      let alreadyAdded = false;
      modules.map((addeditem, addedindex) => {
        alreadyAdded = addeditem.value == item.value || alreadyAdded;
      });
      if (!alreadyAdded) toAdd.push(item);
    });
    setAddModules(toAdd);
    setAddModuleOpen(true);
  };

  const addModuleToConfig = (moduleValue) => {
    allModules.map((item, index) => {
      if (item.value == moduleValue) {
        item.display = true;
        modules.push(item);
      }
    });
    setModules(modules);
    setAddModuleOpen(false);
  };

  const removeModuleFromConfig = (moduleVal) => {
    console.log("to remove", moduleVal);
    setModules(
      modules.filter((item) => {
        return item.value !== moduleVal;
      })
    );
  };

  //TODO replace with fetching for edit
  useEffect(() => {
    const selected = [];
    allModules.map((item) => {
      if (item.display) selected.push(item);
    });

    setModules(selected);
  }, [allModules]);

  return (
    <Grid container>
      <PrescriptionConfigLayout
        currentStep={currentStep}
        changeStep={setCurrentStep}
        selectedModules={modules}
        onReorder={onReorder}
        onAddModuleButton={showAddModule}
        onRemoveModule={removeModuleFromConfig}
      >
        {modules.map((module, index) => {
          return (
            <Grid
              container
              key={index}
              sx={{ display: currentStep !== index && "none" }}
            >
              <Typography variant="h5" sx={{ mb: 5 }}>
                {module.title}
              </Typography>
              {getConfigRender(module.value)}
            </Grid>
          );
        })}
      </PrescriptionConfigLayout>
      <Grid container>
        <Dialog open={addModuleOpen} onClose={() => setAddModuleOpen(false)}>
          <DialogTitle>Add Module</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select from the list of available modules to be added in your
              prescription
            </DialogContentText>
            <Divider sx={{ mb: 5 }} />
            <Grid container spacing={2} flexDirection="column">
              {addModules.map((module, index) => {
                return (
                  <ModuleItem
                    key={index}
                    item
                    onClick={() => addModuleToConfig(module.value)}
                  >
                    <Grid item md={12}>
                      <strong>
                        {module?.icon} {module?.title}
                      </strong>
                    </Grid>
                    <Grid item md={4}>
                      {module?.preview}
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body2">
                        {module?.description}
                      </Typography>
                    </Grid>
                  </ModuleItem>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddModuleOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default PrescriptionConfig;
