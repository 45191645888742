import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { BROWSE_PLANS } from '../../../util/AppUrls'
import { FETCH_MY_SUBSCRIBED_PLANS } from '../../../util/ApiUrls'
import useAxiosProtected from '../../../hooks/useAxiosProtected'
import CreditDetails from '../../../components/subscription/CreditDetails'
import SubscriptionsList from '../../../components/subscription/SubscriptionsList'

const MyPlans = () => {
  const [plans, setPlans] = useState([])
  const axiosProtected = useAxiosProtected()


  const loadSubscribedPlans = async () => {
    try {
      await axiosProtected.get(
        FETCH_MY_SUBSCRIBED_PLANS,
      ).then(response => {
        console.log(response)
        setPlans(response?.data)
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    loadSubscribedPlans()
  }, [])

  return (
    <>
      <Grid className='content-center'>
        <Grid container marginBottom={5} direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item direction={'column'}>
            <h2>Subscribed Plans</h2>
          </Grid>
          <Grid item>
            <Link to={BROWSE_PLANS} >
              <Button size="small" sx={{ mr: 5 }}>Browse More Plans</Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <CreditDetails creditDetails={plans} />
          </Grid>
          <Grid item md={12}>
            <Typography>Your purchased plans</Typography>
          </Grid>
          <Grid item xs={12}>
            <SubscriptionsList subscriptions={plans} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MyPlans