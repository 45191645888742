export const MYRX_SITE = "https://myrx.in";

export const configs = {
  'prod': {
    'MYRX': {
      url: {
        MYRX_SITE: "https://myrx.in",
        LOGO_PATH: "https://cdn.myrx.in/website/images/logo.png",
        LOGO_PATH_SMALL: "https://cdn.myrx.in/website/images/logo.png",
        MYRX_CONTEXT: "https://myrx.in/",
        ABHA_CONTEXT: "https://abha.myrx.in",
        MYRX_REDIRECT_MONOLITH: "https://myrx.in/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "328905101628-j35qkjlddr4occiggg9qjec11clo70ts.apps.googleusercontent.com",
          ENABLE_GOOGLE_LOGIN:true
      },
    },
    'POWERMAP': {
      url: {
        MYRX_SITE: "https://powermap.in",
        LOGO_PATH: "https://myrx.in/assets/img/clients/MadhavBaug.png",
        LOGO_PATH_SMALL: "https://myrx.in/assets/img/clients/MadhavBaug-mini.png",
        MYRX_CONTEXT: "https://powermap.in/",
        ABHA_CONTEXT: "https://abha.myrx.in",
        MYRX_REDIRECT_MONOLITH: "https://powermap.in/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "",
          ENABLE_GOOGLE_LOGIN:false
      },
    }
  },
  'development': {
    'MYRX': {
      url: {
        COMPANY_NAME: "MyRx",
        MYRX_SITE: "http://myrx.com:8088/myrx",
        LOGO_PATH: "http://myrx.com:8088/images/logo.png",
        LOGO_PATH_SMALL: "http://myrx.com:8088/images/logo.png",
        MYRX_CONTEXT: "http://myrx.com:8088/myrx/",
        ABHA_CONTEXT: "http://localhost:8081/myrx/api/",
        MYRX_REDIRECT_MONOLITH: "http://myrx.com:8088/myrx/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "328905101628-jgbnhophui3njtnvpko0p61cs0q7ms37.apps.googleusercontent.com",

          ENABLE_GOOGLE_LOGIN:true
      },
    },
    'DEV': {
      url: {
        COMPANY_NAME: "MyRx",
        MYRX_SITE: "https://myrx.com",
        LOGO_PATH: "https://cdn.myrx.in/website/images/logo.png",
        LOGO_PATH_SMALL: "https://cdn.myrx.in/website/images/logo.png",
        MYRX_CONTEXT: "https://myrx.com/",
        ABHA_CONTEXT: "http://localhost:8081/api/",
        MYRX_REDIRECT_MONOLITH: "https://myrx.com/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "328905101628-jgbnhophui3njtnvpko0p61cs0q7ms37.apps.googleusercontent.com",

          ENABLE_GOOGLE_LOGIN:true
      },
    },
    'POWERMAP': {
      url: {
        COMPANY_NAME: "Powermap",
        MYRX_SITE: "https://powermap.in:8088",
        LOGO_PATH: "https://myrx.in/assets/img/clients/MadhavBaug.png",
        LOGO_PATH_SMALL: "https://myrx.in/assets/img/clients/MadhavBaug-mini.png",
        MYRX_CONTEXT: "https://powermap.in:8088/",
        ABHA_CONTEXT: "https://abha.myrx.in",
        MYRX_REDIRECT_MONOLITH: "https://powermap.in:8088/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "",
          ENABLE_GOOGLE_LOGIN:false
      },

    }
  }
}


export const environment =
    process.env.NODE_ENV === "development" ? 'development' : 'prod';

 // export const client = process.env.REACT_APP_CLIENT === 'powermap' ? 'POWERMAP' : process.env.REACT_APP_CLIENT === 'dev'? 'DEV' : 'MYRX' ;
  export const client = process.env.REACT_APP_CLIENT;

  export const dosageMap = {
    "1-0-0": "1-0-0",
    "0-1-0": "0-1-0",
    "0-0-1": "0-0-1",
    "1-1-0": "1-1-0",
    "1-0-1": "1-0-1",
    "0-1-1": "0-1-1",
    "1-1-1": "1-1-1",
    "0-0-0": "SoS",
    "1/2 - 1/2 - 1/2": "1/2- 1/2 - 1/2",
    "1/2 - 1/2 - 1": "1/2 - 1/2 - 1",
    "1/2 - 0 - 1/2": "1/2 - 0 - 1/2",
  };

  export const frequencyMap = {
    d: "Daily",
    a: "Alternate",
    w: "Weekly",
    f: "Fortnight",
    sos: "SOS",
  };

  export const defaultDosagePreferenceMap = {
    "1-0-0": "ab",
    "0-1-0": "al",
    "0-0-1": "ad",
    "1-1-0": "abl",
    "1-0-1": "abd",
    "0-1-1": "ald",
    "1-1-1": "am",
    "0-0-0": "SOS",
    "1/2 - 1/2 - 1/2": "am",
    "1/2 - 1/2 - 1": "am",
    "1/2 - 0 - 1/2": "abd",
  };

  export const preferenceMap = {
    bm: "Before Meal",
    am: "After Meal",
    bb: "Before Breakfast",
    ab: "After Breakfast",
    bl: "Before Lunch",
    al: "After Lunch",
    bd: "Before Dinner",
    ad: "After Dinner",
    bbd: "Before Breakfast and Dinner",
    abd: "After Breakfast and Dinner",
    bbl: "Before Breakfast and Lunch",
    abl: "After Breakfast and Lunch",
    bld: "Before Lunch and Dinner",
    ald: "After Lunch and Dinner",
    SOS: "When required",
  };

  export const moduleDetails = {
    PRESCRIPTION: {
      moduleName: "Prescription Writer",
      color: "success",
    },
    BILLING: {
      moduleName: "Billing",
      color: "warning",
    },
    APPOINTMENT_BOOKING: {
      moduleName: "Appointment",
      color: "primary",
    },
    WEBSITE: {
      moduleName: "Website",
      color: "info",
    },
    MIXED: {
      moduleName: "Multi Module",
      color: "default",
    },
  };

  export const SHORT_FORM = {
    W: "Week",
    M: "Month",
    D: "Day",
    Y: "Year",
  };







  // Delete things after this line

  const prod= {
      url: {
        MYRX_SITE: "https://myrx.in",
        LOGO_PATH: "https://cdn.myrx.in/website/images/logo.png",
        MYRX_CONTEXT: "https://myrx.in/",
        ABHA_CONTEXT: "https://abha.myrx.in",
        MYRX_REDIRECT_MONOLITH: "https://myrx.in/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "328905101628-j35qkjlddr4occiggg9qjec11clo70ts.apps.googleusercontent.com",
      },
    
  };
  const development= {
      url: {
        MYRX_SITE: "https://myrx.com",
        MYRX_CONTEXT: "https://myrx.com/",
        ABHA_CONTEXT: "http://localhost:8081/api/",
        MYRX_REDIRECT_MONOLITH: "https://myrx.com/redirectUser.action?q=",
        MYRX_GOOGLE_CLIENT_ID:
          "328905101628-jgbnhophui3njtnvpko0p61cs0q7ms37.apps.googleusercontent.com",
      },
  }

export const config =
process.env.NODE_ENV === "development" ? development : prod;