import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { REFRESH_TOKEN_URL } from '../util/ApiUrls';
import { LOGIN_URL } from '../util/AppUrls';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation()

  const refresh = async () => {
    const code = await axios.get(REFRESH_TOKEN_URL, {
      withCredentials: true,
    }).then(response => {
      console.log(response)
      setAuth((prev) => {
        console.log(JSON.stringify(prev));
        console.log(response?.data);
        return {
          ...prev,
          accessToken: response?.data,
          roles: response?.data?.roles || ['enhance']
        }
      });
      return response?.data;
    }).catch(err => {
      if (err?.response?.status === 401) {
        const from = location.state?.from?.pathname || '/'
        navigate((LOGIN_URL || from), { replace: true })
      }
    })
    return code;

  };

  return refresh;
}

export default useRefreshToken