import React from "react";
import { Grid, Box } from "@mui/material";
import Logo from "../../components/Logo";
import { MYRX_SITE } from "../../util/MyRxConstants";
import { LOGIN_URL } from "../../util/AppUrls";
import { Link } from "react-router-dom";
import AbhaComponent from "../../components/abhaNew/AbhaComponent";

export default function CreateABHANew() {

    return (
        <Grid>
            <Grid container justifyContent={"space-between"} sx={{ px: 20, py: 3 }}>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Logo width="50" height="50" href={MYRX_SITE} /> &nbsp;
                    </Box>
                </Grid>
                <Grid item>
                    <Grid>
                        <Link to={LOGIN_URL}>
                            Login
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <AbhaComponent />
            </Grid>
        </Grid>
    );
}