import React from "react";
import PrescriptionConfigWizard from "../../prescription/config/firsttime/PrescriptionConfigWizard";
import WebsiteConfigWizard from "../../prescription/config/firsttime/WebsiteConfigWizard";

const FirstTimeConfigMain = (props) => {
  return (
    <>
      <PrescriptionConfigWizard {...props} />
      {/* <WebsiteConfigWizard {...props} /> */}
    </>
  );
};

export default FirstTimeConfigMain;
