import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AadhaarInput from "./AadhaarInput";
import AadhaarAuthentication from "./AadhaarAuthentication";
import AadhaarCommunication from "./AadhaarCommunication";
import AbhaAddressCreation from "./AbhaAddressCreation";

export default function AbhaComponent() {
  const [currentTab, setCurrentTab] = useState(0);
  const [completedTabs, setCompletedTabs] = useState([]);

  const handleNextTab = (isValid) => {
    if (isValid) {
      setCompletedTabs((prev) => [...new Set([...prev, currentTab])]);
      setCurrentTab((prevTab) => prevTab + 1);
    }
  };

  const handleTabChange = (event, newValue) => {
    if (completedTabs.includes(newValue) || newValue <= currentTab) {
      setCurrentTab(newValue);
    }
  };

  const getTabStyle = (index) => {
    return completedTabs.includes(index)
      ? { backgroundColor: "green", color: "white" }
      : {};
  };

  return (
    <Box sx={{ px: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Create ABHA Number Using Aadhaar
      </Typography>
      <Box
        sx={{
          borderBottom: "2px solid #ccc",
          boxShadow: 2,
          p: 2,
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {completedTabs.includes(0) && <CheckCircleIcon fontSize="small" />}
                Consent Collection
              </Box>
            }
            sx={getTabStyle(0)}
          />
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {completedTabs.includes(1) && <CheckCircleIcon fontSize="small" />}
                Aadhaar Authentication
              </Box>
            }
            sx={getTabStyle(1)}
          />
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {completedTabs.includes(2) && <CheckCircleIcon fontSize="small" />}
                Communication Detail
              </Box>
            }
            sx={getTabStyle(2)}
          />
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {completedTabs.includes(3) && <CheckCircleIcon fontSize="small" />}
                ABHA Address Creation
              </Box>
            }
            sx={getTabStyle(3)}
          />
        </Tabs>

        <Box
          sx={{
            borderBottom: "2px solid #ccc",
            marginTop: "-2px",
          }}
        />

        {currentTab === 0 && <AadhaarInput onSubmit={handleNextTab} />}
        {currentTab === 1 && (
          <AadhaarAuthentication mobileNumber="1234567890" onSubmit={handleNextTab} />
        )}
        {currentTab === 2 && <AadhaarCommunication onSubmit={handleNextTab} />}
        {currentTab === 3 && <AbhaAddressCreation onSubmit={handleNextTab} />}
      </Box>
    </Box>
  );
}
