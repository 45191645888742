import React from 'react'
import { FETCH_FIRST_TIME_PRESCRIPTION_TOUR, REDIRECT_USER_TO_MONOLITH } from '../util/ApiUrls'
import useAxiosProtected from './useAxiosProtected'

const useRedirectToMonolith = (path) => {
    const axiosProtected = useAxiosProtected()
    const redirect = async (path) => {
        try {
            await axiosProtected.get(
                REDIRECT_USER_TO_MONOLITH+path
            ).then(response => {
                console.log(response)
                window.location.replace(process.env.REACT_APP_MYRX_REDIRECT_MONOLITH + encodeURIComponent(response?.data?.data))
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    return redirect
}

export default useRedirectToMonolith