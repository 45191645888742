import { Box, Link, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const FooterContent = () => {
    const hidden = useMediaQuery(theme => theme.breakpoints.down('md'))
    return (
        <Box sx={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ mr: 2 }}>
                {`© ${new Date().getFullYear()}, `}
                <Link href='https://myrx.in/'>
                    Platinoid Technologies.
                </Link>
                {`  All Rights Reserved. `}
            </Typography>
            <Typography >
                <Link sx={{ mr: 2 }} href='https://www.myrx.in/privacypolicy'>
                    Privacy Policy
                </Link>
                &nbsp;
                <Link sx={{ mr: 2 }} href='https://www.myrx.in/termsofuse'>
                    Terms of Use
                </Link>
                &nbsp;
                <Link sx={{ mr: 2 }} href='https://myrx.in/'>
                    Cookie Policy
                </Link>
            </Typography>
        </Box>
    )
}

export default FooterContent