import React, { useEffect, useState } from "react";
import ProfileUpdateWizard from "../../../components/doctor/profileupdatewizard/ProfileUpdateWizard";
import { useSessionStateValue } from "../../../context/SessionContext";

const UpdateProfile = (props) => {
  // const [user, setUser] = useState({});
  // const { getUserProfile } = useUserProfile();

  // useEffect(() => {
  //   updateUserProfile();
  // }, []);

  // const updateUserProfile = () => {
  //   getUserProfile(setUser);
  //   let abcddd = 2;
  // };
  const [{ user }, dispatch] = useSessionStateValue();

  return (
    <>
      <ProfileUpdateWizard userProfile={user} {...props} />
    </>
  );
};

export default UpdateProfile;
