import { createContext, useContext, useReducer } from "react";

/**
 * The Following context stores the data of user and jwt token
 * It has to be refreshed at every new auth token fetch and tab visibility action
 */

const sessionReducer = (state, action) => {
  switch (action.type) {
    case "updateUser":
      return {
        ...state,
        user: action.userDetail,
      };
    case "clearSession":
      return {};
    default:
      return state;
  }
};

const initialSession = {};

export const SessionContext = createContext();
export const RxSessionProvider = ({ children }) => {
  // const [session, dispatch] = useReducer(sessionReducer, initialSession)
  return (
    <SessionContext.Provider value={useReducer(sessionReducer, initialSession)}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionStateValue = () => useContext(SessionContext);
