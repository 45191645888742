import UpdateSpecialization from './UpdateSpecialization'
import UpdateCity from './UpdateCity'
import React, { useState } from 'react'
import ProfileWizardStepLayout from '../../../layouts/profileWizardStepLayout/ProfileWizardStepLayout'

const ProfileUpdateWizard = (props) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [maxSteps, setMaxSteps] = useState(2)

    const _next = (data) => {
        if (currentStep >= maxSteps) {
            setCurrentStep(maxSteps);
        } else {
            setCurrentStep(currentStep + 1);
        }
        console.log(currentStep)
    }

    const _prev = (data) => {
        if (currentStep <= 1) {
            setCurrentStep(1);
        } else {
            setCurrentStep(currentStep - 1);
        }

        console.log(currentStep)
    }

    return (
        <div>
            {
                currentStep == 1 && (
                    <ProfileWizardStepLayout image={process.env.PUBLIC_URL +"/assets/images/updateSpeciality.png"} afterBack={_prev} currentStep={currentStep}>
                        <UpdateSpecialization currentStep={currentStep} afterValid={_next} {...props}  />
                    </ProfileWizardStepLayout>
                )
            }
            {
                currentStep == 2 && (
                    <ProfileWizardStepLayout image={process.env.PUBLIC_URL +"/assets/images/updateCity.png"} afterBack={_prev} currentStep={currentStep}>
                        <UpdateCity currentStep={currentStep} afterValid={_next} {...props} />
                    </ProfileWizardStepLayout>
                )
            }
        </div >
    )
}


export default ProfileUpdateWizard