var moment = require("moment");

export const getDateFromString = (stringDate, format) => {
  return moment(stringDate, format).toDate();
};

export const getYearAndMonthPassedFromDate = (dt) => {
  const today = new Date();
  let diffMilSec = today.valueOf() - dt.valueOf();
  let year_age = Math.floor(diffMilSec / 31536000000);
  let day_age = Math.floor((diffMilSec % 31536000000) / 86400000);

  let month_age = Math.floor(day_age / 30);
  day_age = day_age % 30;

  let tMnt = month_age + year_age * 12;
  let tDays = tMnt * 30 + day_age;
  return [year_age, month_age];
};
