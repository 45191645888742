import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import React, { useState } from "react";
import PrescriptionVitalsList from "../../../components/prescription/PrescriptionVitalsList";

import { mdiCloseThick } from "@mdi/js";
import Icon from "@mdi/react";
import VitalFieldAdd from "../../../components/form/VitalFieldAdd";

const VitalsConfig = (props) => {
  const [addVitalDialog, setAddVitalDialog] = useState(false);
  const [suggestedVitals, setSuggestedVitals] = useState(
    PrescriptionVitalsList
  );
  const [selectedVitals, setSelectedVitals] = useState([]);

  const addConfig = (e) => {
    suggestedVitals.map((item, index) => {
      if (item.value == e?.target?.id) {
        item.selected = true;
        console.log(item);
        setSelectedVitals((prev) => [...prev, item]);
      }
    });
  };
  const removeConfig = async (index) => {};

  const addEditCustomVital = (customVital) => {
    setSelectedVitals((prev) => [...prev, customVital]);
    setAddVitalDialog(false);
  };

  const renderUnit = (item) => {
    switch (item?.unit?.type) {
      case "label":
        return (
          <TextField
            size="small"
            readOnly={!item?.type?.custom}
            value={item?.unit?.value}
          />
        );
        break;
      case "dropdown":
        return (
          <Select defaultValue={item?.unit?.value}>
            {item?.unit?.values?.map((option, id) => {
              return (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      default:
        return <div>{item?.unit.value}</div>;
        break;
    }
  };

  return (
    <>
      <Grid container sx={{ mb: 3 }}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          justifyContent={"right"}
          sx={{ mb: 3 }}
        >
          <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
            Click to add vital input
          </Grid>
          <Button
            sx={{ mr: 5, mb: 2 }}
            onClick={() => {
              setAddVitalDialog(true);
            }}
            variant="contained"
            size="large"
            color="primary"
            id={"addcustom"}
          >
            Add New Vital
          </Button>
          <VitalFieldAdd
            dialogOpened={addVitalDialog}
            setDialogOpened={setAddVitalDialog}
            addFormFieldDetail={addEditCustomVital}
          />
          <Divider />
          {suggestedVitals.length > 0 && (
            <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
              Or Select some predefined vitals to be added in Your Prescription
              Writer
            </Grid>
          )}
          <Grid item lg={12} sx={{ mb: 3 }}>
            {suggestedVitals &&
              suggestedVitals?.map((item, index) => {
                if (!item?.selected) {
                  return (
                    <Button
                      sx={{ mr: 5, mb: 2 }}
                      onClick={addConfig}
                      variant={props.selected ? "contained" : "outlined"}
                      size="large"
                      color="secondary"
                      id={item?.value}
                      key={index}
                    >
                      {parse(item?.name)}
                    </Button>
                  );
                }
              })}
          </Grid>
          <Divider />
          <Grid item md={12} sm={12} xs={12} sx={{ mb: 3 }}>
            Selected Vitals
          </Grid>
          <Grid item lg={12} sx={{ mb: 3 }}>
            {selectedVitals ? (
              selectedVitals?.map((item, index) => {
                if (item?.selected) {
                  return (
                    <div key={index}>
                      <FormControl
                        sx={{ m: 1, minWidth: 200, mr: 6 }}
                        size="small"
                        key={index}
                      >
                        <TextField size="small" readOnly value={item.name} />
                      </FormControl>
                      <FormControl
                        sx={{ m: 1, minWidth: 200, mr: 6 }}
                        size="small"
                        key={index}
                      >
                        {renderUnit(item)}
                      </FormControl>
                      <FormControl
                        sx={{ m: 1, minWidth: 200, mr: 6 }}
                        size="small"
                        key={index}
                      >
                        <span>
                          <Tooltip title="Remove Vital">
                            <Icon
                              path={mdiCloseThick}
                              size={1.6}
                              color={"red"}
                              id={"selectedvital_" + index}
                              onClick={() => removeConfig(index)}
                            />
                          </Tooltip>
                        </span>
                      </FormControl>
                    </div>
                  );
                }
              })
            ) : (
              <Typography variant="body2">No Vitals selected </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VitalsConfig;
