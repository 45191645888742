const themeConfig = {
  templateName: "MyRx-Primary",
  mode: "light",
  contentWidth: "boxed",
  routingLoader: true,
  menuTextTruncate: true,
  navigationSize: 260,
  responsiveFontSizes: true,
  disableRipple: false,
};

export default themeConfig;
