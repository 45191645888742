import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  pdfviewer: {
    width: "80vw",
    height: 500,
  },
  document: {
    width: 400,
  },
  page: {
    width: 400,
  },
  headerSection: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const PrescriptionPreview = () => (
  <PDFViewer style={styles.pdfviewer}>
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerSection}>
          <View style={styles.section}>
            <Text>Prafull Pandey</Text>
            <Text>28 Yrs 11 Months</Text>
            <Text>Male</Text>
          </View>
          <View style={styles.section}>
            <Text>
              <Text
                style={{
                  fontFamily: "Open Sans",
                  border: "2px solid red",
                  color: "red",
                }}
              >
                Date:
              </Text>{" "}
              02-May-2023
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

export default PrescriptionPreview;
