import enIN from "date-fns/locale/en-IN";
import React, { Suspense } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import DatePickerWrapper from "./core/styles/libs/DatePickerWrapper";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
registerLocale("enIN", enIN);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense fallback="...is loading">
    <DatePickerWrapper>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          {/* <PerfectScrollbar> */}
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
          {/* </PerfectScrollbar> */}
        </AuthProvider>
      </BrowserRouter>
    </DatePickerWrapper>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
