import React from "react";
import { useEffect } from "react";
import { useAuthValue } from "../context/AuthProvider";
import useReloadSessionContext from "../hooks/useReloadSessionContext";
import usePrevious from "../hooks/usePrevious";

const SessionLayout = (props) => {
  const { children } = props;
  const { auth } = useAuthValue();
  const prevAuth = usePrevious(auth?.accessToken);
  const reloadSessionContext = useReloadSessionContext();

  useEffect(() => {
    if (prevAuth !== auth?.accessToken && auth?.accessToken) {
      //   reloadSessionContext()
      console.log("accessToken Changed");
    }
  }, [auth?.accessToken]);

  useEffect(() => {
    reloadSessionContext();
  }, []);

  return <>{children}</>;
};

export default SessionLayout;
