import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import SearchProduct from "./SearchProduct";
import SearchServices from "./SearchServices";

const productType = {
  P: { color: "warning" },
  S: { color: "info" },
};

const AddProductService = ({
  selectedServiceProduct,
  setSelectedServiceProduct,
}) => {
  const [serviceType, setServiceType] = useState("product");

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
  };

  const addNewServiceProduct = (record) => {
    setSelectedServiceProduct([...selectedServiceProduct, record]);
  };

  const removeServiceProduct = (index) => {
    setSelectedServiceProduct(
      selectedServiceProduct.filter((item, ind) => ind !== index)
    );
  };
  return (
    <>
      <Grid container sx={{ p: 10 }}>
        <Grid item md={12} sx={{ mb: 5 }}>
          <Typography variant="body2" sx={{ mb: 5 }}>
            Selected Product/Service
          </Typography>
          {selectedServiceProduct.map((item, index) => (
            <Chip
              key={index}
              label={item?.type == "P" ? item?.name : item?.serviceName}
              color={productType[item?.type]?.color}
              onDelete={() => removeServiceProduct(index)}
              sx={{
                height: 24,
                mr: 5,
                fontSize: "0.75rem",
                textTransform: "capitalize",
                "& .MuiChip-label": { fontWeight: 500 },
              }}
            />
          ))}
        </Grid>
        <Grid item md={12} sx={{ mb: 5 }}>
          <FormControl>
            <FormLabel sx={{ fontSize: "0.875rem" }}>Search by</FormLabel>
            <RadioGroup
              row
              defaultValue="product"
              aria-label="product/service"
              name="service-type-select-radio"
              value={serviceType}
              onChange={handleServiceTypeChange}
            >
              <FormControlLabel
                value="product"
                label="Product"
                control={<Radio />}
              />
              <FormControlLabel
                value="service"
                label="Service"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          {serviceType == "product" ? (
            <SearchProduct addProduct={addNewServiceProduct} />
          ) : (
            <SearchServices addService={addNewServiceProduct} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddProductService;
