import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { LOGOUT_USER_API, LOGOUT_USER_STRUTS2 } from "../util/ApiUrls";
import { LOGIN_URL } from "../util/AppUrls";
import useAuth from "./useAuth";
import useAxiosProtected from "./useAxiosProtected";
import { useSessionStateValue } from "../context/SessionContext";

const useLogOut = () => {
  const { setAuth } = useAuth();
  const axiosProtected = useAxiosProtected();
  const navigate = useNavigate();
  const [, dispatch] = useSessionStateValue();

  const logout = async () => {
    setAuth({});
    dispatch({
      type: "clearSession",
    });
    try {
      const response = await axiosProtected.post(LOGOUT_USER_API, {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    try {
        const response = await axiosProtected.post(LOGOUT_USER_STRUTS2, {
            withCredentials: true,
        });
    } catch (err) {
        console.log(err);
    }
    navigate(LOGIN_URL, { replace: true });
  };

  return logout;
};

export default useLogOut;
