import React from "react";
import {
  Link as MLink,
  Button,
  Alert,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useState } from "react";
import { validateAadhar } from "../../util/Verhoeff";
import { Link } from "react-router-dom";
import { checkIfNumeric } from "../../util/CommonUtils";
import { useEffect } from "react";

const ABHAMobileInput = () => {
  const [mobile, setMobile] = useState("");
  const [mobileValidated, setMobileValidated] = useState(true);
  const [formValidated, setFormValidated] = useState();

  const handleMobileInpChange = (event) => {
    const newValue = event.target.value;
    (checkIfNumeric(newValue) || !newValue) && setMobile(newValue);
    setMobileValidated(validateMobile(newValue));
  };

  const validateMobile = (number) => {};

  const sendAadharOtp = (event) => {
    console.log("validation to start");
  };

  useEffect(() => {
    setFormValidated(mobile && checkIfNumeric(mobile) && mobileValidated);
  }, [mobileValidated]);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="caption" sx={{ mb: 10 }}></Typography>
      <TextField
        error={!mobileValidated}
        value={mobile}
        onChange={handleMobileInpChange}
        // onBlur={(event) => validatemobileNumber(event.target.value)}
        label="Mobile Number"
        placeholder="Enter your Mobile"
        sx={{ mb: 4 }}
        helperText={!mobileValidated && "Please enter correct Mobile number"}
      />
      <Button variant="contained" disabled={!formValidated}>
        Create ABHA Number
      </Button>
    </Grid>
  );
};

export default ABHAMobileInput;
