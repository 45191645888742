import { Button, Grid } from "@mui/material";
import React from "react";
import ArrowLeft from "mdi-material-ui/ArrowLeft";

const ProfileWizardStepLayout = (props) => {
  const { children } = props;

  return (
    <>
      <Grid container justifyContent={"left"} sx={{ px: 20, py: 5 }}>
        {props.currentStep > 1 && (
          <Button onClick={props.afterBack}>
            {" "}
            <ArrowLeft /> &nbsp; Back
          </Button>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"space-evenly"}
        alignItems="center"
        paddingX={20}
      >
        <Grid item md={6} xs={12} container direction="column" paddingX={10}>
          {children}
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={props.image} height="350" width="350" />
          <span
            sx={{
              textAlign: "center",
            }}
          >
            {props.currentStep == 1
              ? "Your practice, Your management. Secured and fully customized application for easy practice"
              : "4000+ doctors across 370 cities trust MyRx"}
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileWizardStepLayout;
