import {
    Link as MLink,
    Button,
    Alert,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    Snackbar,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useRef, useState } from "react";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCard from "@mui/material/Card";
import "react-phone-number-input/style.css";

import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import Google from "mdi-material-ui/Google";
import themeConfig from "../../configs/themeConfig";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import axios from "../../api/axios";
import { FETCH_COMPANY_CLINICS_AUTOSUGGEST, LOGIN_URL_GOOGLE, SIGN_UP_PCE_MADHAVBAUG_URL, SIGN_UP_URL } from "../../util/ApiUrls";
import useAuth from "../../hooks/useAuth";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import PhoneNumber from "../../components/common/PhoneNumber";
import MuiPhone from "../../components/common/MuiPhone";
import { LOGIN_URL } from "../../util/AppUrls";
import PrescriptionParamAutoSuggest from "../../components/autosuggest/PrescriptionParamAutoSuggest";


const Card = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const LinkStyled = styled("span")(({ theme }) => ({
    fontSize: "0.875rem",
    textDecoration: "none",
    color: theme.palette.primary.main,
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    "& .MuiFormControlLabel-label": {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
    },
}));

const RegisterUser = () => {
    const userRef = useRef();
    const errRef = useRef();

    const { setAuth, persist, setPersist } = useAuth();
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [autoSuggestValue, setAutoSuggestValue] = useState("");


    const theme = useTheme();
    const { t } = useTranslation(["translation", "login"]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleChangeMobile = (value) => {
        setValues({ ...values, 'mobile': value });
    }
    const onSelectClinic = async (value) => {
        setValues({ ...values, 'clinic': value?.itemVal });
    }
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const resetForm = () => {
        setValues({
            password: "",
            showPassword: false, email: '', mobile: '', fullname: ''
        })
        setAutoSuggestValue("");

    }

    const handleSignUp = async () => {
        const fullname = values?.fullname;
        const nameArr = fullname.split(" ");

        const data = {
            firstName: nameArr[0],
            lastName: nameArr[1],
            email: values?.email?.trim(),
            mobile: values?.mobile,
            password: values?.password,
            assignedClinic: values?.clinic
        };

        try {
            const response = await axios
                .post(SIGN_UP_PCE_MADHAVBAUG_URL, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    //updateSignUpResponse(response);
                    setSuccMsg("User Sign up successful. Please wait while your signup gets approved.");
                    setErrMsg('')
                    setToastOpen(true);
                    resetForm()
                })
                .catch((err) => {
                    console.log(err);
                    setSuccMsg('')
                    if (!err?.response) {
                        setErrMsg("No server response");
                    } else if (
                        err.response?.status === 400 ||
                        err.response?.status === 401
                    ) {
                        setErrMsg(err?.response?.data?.error?.message);
                    } else {
                        setErrMsg("Error occured While Signing you up");
                    }
                    setToastOpen(true);
                });
        } catch (err) {
            console.log(err);
            setSuccMsg('')
            setErrMsg("Something unexpected occurred. Please try again later");
            setToastOpen(true);
        }
    }



    return (
        <Grid>
            <Grid container justifyContent={"space-between"} sx={{ px: 20, py: 5 }}>
                <Grid item>
                    {/* {JSON.stringify(values)} */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Logo width="50" height="50" href={process.env.REACT_APP_MYRX_SITE} /> &nbsp;
                        {/* <Typography
                            variant='h6'
                            sx={{ nl: 3, lineHeight: 1, fontWeight: 600, fontSize: '1.5rem !important' }}>
                            MyRx
                        </Typography> */}
                    </Box>
                </Grid>
                <Grid item>
                    {t("login:alreadyhaveaccount")} &nbsp;
                    <Link to={LOGIN_URL}>
                        <LinkStyled>{t("login:signin")}</LinkStyled>
                    </Link>
                </Grid>
            </Grid>
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity={succMsg ? "success" : "error"} sx={{ width: "100%" }}>
                    {succMsg || errMsg}
                </Alert>
            </Snackbar>
            <Grid container justifyContent={"center"}>
                <Grid
                    item
                    container
                    lg={10}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent={"space-evenly"}
                    alignItems="center"
                    paddingX={5}
                >
                    <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        container
                        direction="column"
                        paddingX={10}
                        sx={{ backgroundColor: "white", borderRadius: 5 }}
                    >
                        <Box sx={{ mb: 6 }}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 600, marginBottom: 1.5 }}
                            >
                                Hi! Please fill the below form to complete signup
                            </Typography>
                            <Typography variant="body2">{''}</Typography>
                        </Box>
                        <form
                            noValidate
                            autoComplete={"off"}
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <Grid
                                container
                                spacing={4}
                                justifyContent={"space-evenly"}
                                alignItems="center"
                                marginBottom={3}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <PrescriptionParamAutoSuggest
                                        suggestValue={autoSuggestValue}
                                        label="Select your Clinic"
                                        onAddElement={onSelectClinic}
                                        resetAfterSelect={false}
                                        autoSuggestUrl={FETCH_COMPANY_CLINICS_AUTOSUGGEST}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        autoComplete={"off"}
                                        id="name"
                                        label="Your Full Name"
                                        onChange={handleChange("fullname")}
                                        ref={userRef}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete={"off"}
                                        type="email"
                                        id="email"
                                        label="Your Email"
                                        onChange={handleChange("email")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <MuiPhone onChange={handleChangeMobile} fullWidth label="Your Mobile" defaultCountry="in" value={values?.mobile} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="auth-login-password">
                                            {t("password")}
                                        </InputLabel>
                                        <OutlinedInput
                                            label="Choose a Password"
                                            autoComplete={"new-password"}
                                            value={values.password}
                                            id="auth-login-password"
                                            onChange={handleChange("password")}
                                            type={values.showPassword ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        aria-label="toggle password visibility"
                                                    >
                                                        {values.showPassword ? (
                                                            <EyeOutline />
                                                        ) : (
                                                            <EyeOffOutline />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="form-layouts-basic-password-helper">
                                            Use 8 or more characters with a mix of letters, numbers &
                                            symbols
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box marginBottom={3}>
                                        <span>
                                            By clicking on <b>Next</b> you agree to <b>MyRx</b>{" "}
                                        </span>
                                        <MLink href="https://www.myrx.in/privacypolicy">
                                            <LinkStyled onClick={(e) => e.preventDefault()}>
                                                Privacy Policy & Terms
                                            </LinkStyled>
                                        </MLink>
                                    </Box>
                                </Grid>

                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    sx={{ marginBottom: 7 }}
                                    onClick={handleSignUp}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textShadow: "4px 2px 9px rgba(256,256,256,0.76)",
                        }}
                    ></Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default RegisterUser