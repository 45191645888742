import { TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import React, { forwardRef } from 'react'

const useStyles = makeStyles(theme => ({
    input: {
      backgroundColor: '#fff'
    }
  }))

const PhoneNumber = (props, ref) => {
  const classes = useStyles()

  return (

    <TextField
      {...props}
      InputProps={{
        className: classes.input
      }}
      inputRef={ref}
      fullWidth
      size={props?.size || 'large'}
      label={props?.label || 'Phone Number'}
      variant='outlined'
      
    />
  )
}

export default forwardRef(PhoneNumber)