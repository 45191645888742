import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import themeConfig from "../../../../configs/themeConfig";
import { Link, useNavigate } from "react-router-dom";

const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: "100%",
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  padding: theme.spacing(2.25, 3.5),
  transition: "opacity .25s ease-in-out",
  "&.active, &.active:hover": {
    boxShadow: theme.shadows[3],
    backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.primary.main} 94%)`,
  },
  "&.active .MuiTypography-root, &.active .MuiSvgIcon-root": {
    color: `${theme.palette.common.white} !important`,
  },
}));

const MenuItemTextMetaWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
});

const NavLink = ({ item, navVisible, toggleNavVisibility }) => {
  const navigate = useNavigate();
  const IconTag = item.icon;

  //navigate(from, { replace: true })

  const isNavLinkActive = () => {
    // TODO check if current path == item path return true
    return false;
  };

  return (
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: "0 !important" }}
    >
      <Link to={item.path === undefined ? "/" : `${item.path}`}>
        <MenuNavLink
          component={"span"}
          className={isNavLinkActive() ? "active" : ""}
          {...(item.openInNewTab ? { target: "_blank" } : null)}
          onClick={(e) => {
            if (item.path === undefined) {
              e.preventDefault();
              e.stopPropagation();
              if (item.method) {
                item.method();
              }
            }
            if (navVisible) {
              toggleNavVisibility();
            }
          }}
          sx={{
            pl: 5.5,
            ...(item.disabled
              ? { pointerEvents: "none" }
              : { cursor: "pointer" }),
          }}
        >
          <ListItemIcon
            sx={{
              mr: 2.5,
              color: "text.primary",
              transition: "margin .25s ease-in-out",
            }}
          >
            {/* <UserIcon icon={IconTag} /> */}
          </ListItemIcon>

          <MenuItemTextMetaWrapper>
            <Typography {...(themeConfig.menuTextTruncate && { noWrap: true })}>
              {item.title}
            </Typography>
            {item.badgeContent ? (
              <Chip
                label={item.badgeContent}
                color={item.badgeColor || "primary"}
                sx={{
                  height: 20,
                  fontWeight: 500,
                  marginLeft: 1.25,
                  "& .MuiChip-label": { px: 1.5, textTransform: "capitalize" },
                }}
              />
            ) : null}
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </Link>
    </ListItem>
  );
};

export default NavLink;
