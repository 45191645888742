import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  Chip,
  FormControl,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import { FETCH_DOCTOR_MEDICINE_CONFIG } from "../../../util/ApiUrls";
import Icon from "@mdi/react";
import {
  mdiChevronDownCircleOutline,
  mdiChevronUpCircleOutline,
} from "@mdi/js";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { defaultDosagePreferenceMap } from "../../../util/MyRxConstants";
import { preferenceMap } from "../../../util/MyRxConstants";
import { frequencyMap } from "../../../util/MyRxConstants";
import { dosageMap } from "../../../util/MyRxConstants";
import { Controller, useFieldArray, useForm } from "react-hook-form";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-of-type td, &:last-of-type th": {
    border: 0,
  },
}));

const MedicineRow = styled("div")({
  border: "1px solid lightgrey",
  color: "grey",
  borderRadius: "5px",
  padding: 5,
  marginBottom: 5,
  minHeight: 50,
});

const MedicineDetails = () => {
  const [quickMedicines, setQuickMedicines] = useState([]);
  const [quickMedicineOpen, setQuickMedicineOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const axiosProtected = useAxiosProtected();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicines",
  });

  const appendQuestion = () => {
    append({ text: "" });
  };

  const onSubmit = (data) => console.log(data);

  //APIs
  const loadAddedMedicine = async () => {
    try {
      await axiosProtected
        .get(FETCH_DOCTOR_MEDICINE_CONFIG)
        .then((response) => {
          console.log(response);

          setQuickMedicines((prev) => {
            //console.log("prev {}", prev);
            return response?.data?.data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //use Effects
  useEffect(() => {
    loadAddedMedicine();
  }, [quickMedicineOpen]);

  //Methods
  const addSelectedMedicine = (medicineId) => {};

  const addNewBlankMedicineRow = () => {
    addNewMedicineRow({});
  };

  const addNewMedicineRow = (obj) => {
    var record = {};
    record["id"] = obj?.id || "";
    record["preference"] = obj?.preference || "";
    record["frequency"] = obj?.frequency || "";
    record["dosage"] = obj?.dosage || "";
    record["medicineName"] = obj?.medicineName || "";
    record["composition"] = obj?.composition || "";
    record["medicineId"] = obj?.medicineId || "";
    record["comments"] = obj?.comments || "";
    record["days"] = obj?.days || "0";
    append(record);
  };

  const deleteMedicineRow = (index) => {};
  return (
    <Paper elevation={3} sx={{ p: 5, width: "100%" }}>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Medicine:
      </Typography>
      <Divider sx={{ mb: 5 }} />

      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} justifyContent="right">
          <Button
            onClick={() => {
              setQuickMedicineOpen((prev) => !prev);
            }}
            sx={{ mb: 5 }}
          >
            {quickMedicineOpen
              ? "Close Quick Medicine  "
              : "Open Quick Medicine  "}
            &nbsp;
            {quickMedicineOpen ? (
              <Icon path={mdiChevronUpCircleOutline} size={1} />
            ) : (
              <Icon path={mdiChevronDownCircleOutline} size={1} />
            )}
          </Button>
        </Grid>
        <Grid container sx={{ display: !quickMedicineOpen && "none", mb: 3 }}>
          {quickMedicines?.map((item, index) => {
            return (
              <Chip
                label={item?.medicineName}
                variant="outlined"
                onClick={addSelectedMedicine}
                sx={{ mr: 5, mb: 2 }}
              />
            );
          })}
        </Grid>
        <Divider sx={{ mb: 5 }} />
        <Grid container>
          <Typography variant="body" sx={{ mb: 3 }}>
            Add Medicines
          </Typography>
          <Button onClick={addNewBlankMedicineRow}>Add Medicine</Button>
          <Button onClick={handleSubmit(onSubmit)}>submit</Button>
          <Grid item md={12}>
            {fields?.map((medicine, index) => {
              return (
                <Grid item md={12} sx={{ mb: 3 }} key={medicine.id}>
                  {index + 1}.{" "}
                  <MedicineRow>
                    <Grid container>
                      <Grid item lg={3} md={3} sm={4} xs={12} sx={{ mb: 3 }}>
                        <FormControl>
                          <Controller
                            name={`medicines[${index}].medicineName`}
                            control={control}
                            rules={{
                              required: () => {
                                return index < fields?.length - 1;
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Medicine Name"
                                fullWidth
                                disabled={disabled}
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl>
                          <Controller
                            name={`medicines[${index}].composition`}
                            control={control}
                            rules={{
                              required: () => {
                                return index < fields?.length - 1;
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Composition"
                                size="small"
                                fullWidth
                                disabled={disabled}
                                variant="standard"
                              />
                            )}
                          />

                          {/* {errors.medicines?.[index]?.medicineName && (
                            <p>This can't be empty</p>
                          )} */}
                        </FormControl>
                      </Grid>

                      <Grid item lg={3} md={3} sm={4} xs={12} sx={{ mb: 3 }}>
                        <Controller
                          name={`medicines[${index}].dosage`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              error={errors.gender ? true : false}
                            >
                              <InputLabel
                                id={`medicine-dosage-select-label-${index}`}
                                disabled={disabled}
                              >
                                Dosage
                              </InputLabel>
                              <Select
                                {...field}
                                labelId={`medicine-dosage-select-label-${index}`}
                                id={`medicine-dosage-select-${index}`}
                                label="Dosage"
                                disabled={disabled}
                                variant="standard"
                              >
                                {Object.keys(dosageMap).map(function (key) {
                                  return (
                                    <MenuItem value={key} key={key}>
                                      {dosageMap[key]}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        />
                        <Controller
                          name={`medicines[${index}].preference`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              error={errors.gender ? true : false}
                            >
                              <InputLabel
                                id={`medicine-preference-select-label-${index}`}
                                disabled={disabled}
                              >
                                Preference
                              </InputLabel>
                              <Select
                                {...field}
                                labelId={`medicine-preference-select-label-${index}`}
                                id={`medicine-preference-select-${index}`}
                                label="Preference"
                                size="small"
                                disabled={disabled}
                                variant="standard"
                              >
                                {Object.keys(preferenceMap).map(function (key) {
                                  return (
                                    <MenuItem value={key} key={key}>
                                      {preferenceMap[key]}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {/* <Grid item lg={3} md={3} sm={4} xs={12} sx={{ mb: 3 }}>
                        
                      </Grid> */}
                      <Grid item lg={3} md={3} sm={4} xs={12} sx={{ mb: 3 }}>
                        <Controller
                          name={`medicines[${index}].preference`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              error={errors.gender ? true : false}
                            >
                              <InputLabel
                                id={`medicine-frequency-select-label-${index}`}
                                disabled={disabled}
                                variant="standard"
                              >
                                Frequency
                              </InputLabel>
                              <Select
                                {...field}
                                labelId={`medicine-frequency-select-label-${index}`}
                                id={`medicine-frequency-select-${index}`}
                                label="Frequency"
                                disabled={disabled}
                                variant="standard"
                              >
                                {Object.keys(frequencyMap).map(function (key) {
                                  return (
                                    <MenuItem value={key} key={key}>
                                      {frequencyMap[key]}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item lg={3} xs={12} sx={{ mb: 3 }}>
                        <FormControl>
                          <Controller
                            name={`medicines[${index}].days`}
                            control={control}
                            rules={{
                              required: () => {
                                return index < fields?.length - 1;
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                label="Days"
                                fullWidth
                                disabled={disabled}
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </MedicineRow>
                </Grid>
              );
            })}
          </Grid>
          {/* <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table striped="true" responsive="true">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Medicine</StyledTableCell>
                    <StyledTableCell align="right">Dosage</StyledTableCell>
                    <StyledTableCell align="right">Preference</StyledTableCell>
                    <StyledTableCell align="right">Frequency</StyledTableCell>
                    <StyledTableCell align="right">Days</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicines?.map((row) => (
                    <StyledTableRow key={row.qid}>
                      <StyledTableCell component="th" scope="row">
                        {row.medicineName}{" "}
                        {row?.composition && (
                          <small>({row?.composition})</small>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {dosageMap[row.dosage]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {preferenceMap[row.preference]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {frequencyMap[row.frequency]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.days}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          size="small"
                          onClick={() => deleteMedicineRow(row.qid)}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MedicineDetails;
