import React, { useState } from "react";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  Fade,
  TablePagination,
  Button,
  CircularProgress,
} from "@mui/material";
import Moment from "react-moment";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import {
  PRODUCT_LIST_SEARCH,
  SERVICES_PRODUCT_LIST_SEARCH,
} from "../../util/ApiUrls";
import { useEffect } from "react";
const Row = (props) => {
  // ** Props
  const { row, addSelectedService } = props;

  return (
    <>
      <TableRow
        hover
        key={row.name}
        sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
      >
        <TableCell></TableCell>
        <TableCell sx={{ py: (theme) => `${theme.spacing(0.5)} !important` }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "0.875rem !important" }}
            >
              {row.serviceName}
            </Typography>
            {row.description && (
              <Typography variant="caption">({row.description})</Typography>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {/* <Moment format="DD-MMM-YYYY">{row.createdDate}</Moment> */}
          {row.companyName}
        </TableCell>
        <TableCell>{row?.price}</TableCell>
        <TableCell>
          {/* <Chip
                label={orderStatusObj[row?.orderStatus]?.name}
                color={orderStatusObj[row?.orderStatus]?.color}
                sx={{
                  height: 24,
                  fontSize: "0.75rem",
                  textTransform: "capitalize",
                  "& .MuiChip-label": { fontWeight: 500 },
                }}
              /> */}
        </TableCell>
        <TableCell>
          <Button variant="outlined" onClick={() => addSelectedService(row)}>
            Add
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const SearchServices = ({ addService }) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const axiosProtected = useAxiosProtected();
  const [searchParams, setSearchParams] = useState({
    serviceName: "",
  });

  const handleUpdateSearchParam = (prop) => (event) => {
    setSearchParams({ ...searchParams, [prop]: event.target.value });
  };

  const loadProductsInTable = async () => {
    setLoading(true);
    await axiosProtected
      .post(SERVICES_PRODUCT_LIST_SEARCH, {
        length: rowsPerPage,
        start: rowsPerPage * page,
        ...searchParams,
      })
      .then((response) => {
        console.debug(response);
        setRecords(response?.data?.data);
        setTotalRecords(response?.data?.recordsTotal);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    loadProductsInTable();
  }, [page]);

  const addSelectedService = (record) => {
    record["type"] = "S";
    addService(record);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size={"small"}
            fullWidth
            label="Service Name"
            placeholder="Enter Service Name"
            defaultValue=""
            onChange={handleUpdateSearchParam("serviceName")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button variant="contained" onClick={() => loadProductsInTable()}>
            Search
          </Button>
        </Grid>
        <Grid item sx={12} md={12}>
          <TableContainer>
            <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Service Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Publish Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell align={"center"} colSpan={5}>
                      <Grid container sx={{ width: "100%" }}>
                        <Grid
                          item
                          sx={{
                            height: 100,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Fade
                            in={loading}
                            style={{
                              transitionDelay: loading ? "800ms" : "0ms",
                            }}
                            unmountOnExit
                          >
                            <CircularProgress />
                          </Fade>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : records && records.length > 0 ? (
                  records.map((row) => (
                    <Row
                      key={row.id}
                      row={row}
                      addSelectedService={addSelectedService}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ height: 40 }}>No Services Found</Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            disabled={loading}
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SearchServices;
