import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import parse from "html-react-parser";

const ButtonClose = (props) => {
  return (
    <ButtonGroup sx={{ mr: 5, mb: 2 }}>
      <Button
        variant={props.selected ? "contained" : "outlined"}
        size="small"
        color="secondary"
      >
        {parse(props.label)}
      </Button>
      <Button
        variant="contained"
        size={props?.size || "small"}
        id={props.id}
        onClick={props.onRemoveElement}
      >
        X
      </Button>
    </ButtonGroup>
  );
};

export default ButtonClose;
