import { Button, Grid } from "@mui/material";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import ArrowRight from "mdi-material-ui/ArrowRight";
import React, { useState } from "react";
import useAxiosProtected from "../../../../hooks/useAxiosProtected";
import ConfigWizardUpdateLayout from "../../../../layouts/ConfigUpdateWizard/ConfigWizardUpdateLayout";
import { UPDATE_SIGN_UP_STEP_STATUS } from "../../../../util/ApiUrls";
import AdviseConfigStep from "./AdviseConfigStep";
import AutoPrescriptionConfig from "./AutoPrescriptionConfig";
import DiagnosisConfigStep from "./DiagnosisConfigStep";
import MedicineConfigStep from "./MedicineConfigStep";
import ProcedureConfigStep from "./ProcedureConfigStep";
import StartPrescriptionWritingStep from "./StartPrescriptionWritingStep";
import SymptomsConfigStep from "./SymptomsConfigStep";

const PrescriptionConfigWizard = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [maxSteps, setMaxSteps] = useState(7);
  const axiosProtected = useAxiosProtected();

  const _next = (data) => {
    if (data) {
      updateCurrentStatus(data);
    }
    if (currentStep >= maxSteps) {
      setCurrentStep(maxSteps);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const _prev = (data) => {
    if (currentStep <= 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateCurrentStatus = async (step) => {
    try {
      await axiosProtected
        .put(
          UPDATE_SIGN_UP_STEP_STATUS + step,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <ConfigWizardUpdateLayout
        currentStep={currentStep}
        changeStep={setCurrentStep}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          {currentStep < 7 && (
            <Button onClick={_next}>
              {" "}
              <ArrowRight /> &nbsp; Continue to next step
            </Button>
          )}
          {currentStep > 1 && (
            <Button onClick={_prev}>
              {" "}
              <ArrowLeft /> &nbsp; Back
            </Button>
          )}
        </Grid>
        {currentStep == 1 && (
          <AutoPrescriptionConfig
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 2 && (
          <SymptomsConfigStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 3 && (
          <DiagnosisConfigStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 4 && (
          <ProcedureConfigStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 5 && (
          <AdviseConfigStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 6 && (
          <MedicineConfigStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
        {currentStep == 7 && (
          <StartPrescriptionWritingStep
            afterBack={_prev}
            currentStep={currentStep}
            afterValid={_next}
            {...props}
          />
        )}
      </ConfigWizardUpdateLayout>
    </div>
  );
};

export default PrescriptionConfigWizard;
