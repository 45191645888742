import React from 'react'
import NavLink from './NavLink'
import NavSectionTitle from './NavSectionTitle'


const resolveNavItemComponent = item => {
    if(item.sectionTitle) return NavSectionTitle

    return NavLink
}
const NavItem = (props) => {

    const {navItems} = props
    console.log('navitems', navItems())
    const renderMenuItems = navItems()?.map((item, index) => {
        const TagName = resolveNavItemComponent(item)
        return <TagName {...props} key={index} item={item}/>
    })

  return (
    <>{renderMenuItems}</>
  )
}

export default NavItem