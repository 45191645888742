import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useState } from "react";
import { useSessionStateValue } from "../../../../context/SessionContext";
import useReloadSessionContext from "../../../../hooks/useReloadSessionContext";

const AutoPrescriptionConfig = (props) => {
  const [symptomOptions, setSymptomOptions] = useState([]);
  const reloadSession = useReloadSessionContext();
  const [{ user }, dispatch] = useSessionStateValue();

  const _validate = () => {
    props.afterValid();
  };

  const callUpdateProfile = () => {
    reloadSession();
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader
              title="We have already done some configurations for you"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Grid container></Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AutoPrescriptionConfig;
