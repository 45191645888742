import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import themeConfig from '../../configs/themeConfig'
import Footer from './components/shared/footer/Footer'

//** Styling

const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',

    '& .content-center': {
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5)
    },
    '& .content-right': {
        display: 'flex',
        minHeight: '100vh',
        overflowX: 'hidden',
        position: 'relative'
    }
}))

const ContentWrapper = styled('main')(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(6),
    transition: 'padding .25s ease-in-out',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

const BlankLayout = (props) => {
    const { children } = props
    return (
        <>
            <BlankLayoutWrapper className='layout-wrapper'>
                <ContentWrapper className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
                    {children}
                </ContentWrapper>
                <Footer {...props} />
            </BlankLayoutWrapper>
        </>
    )
}

export default BlankLayout