import React from "react";
import Icon from "@mdi/react";
import {
  mdiPrescription,
  mdiEmoticonSickOutline,
  mdiStethoscope,
  mdiDoctor,
  mdiPillMultiple,
  mdiAccountInjuryOutline,
  mdiNeedle,
  mdiGlasses,
  mdiFoodAppleOutline,
} from "@mdi/js";
import VitalsConfig from "../../views/prescription/config/VitalsConfig";
import SymptomsConfig from "../../views/prescription/config/SymptomsConfig";
import DiagnosisConfig from "../../views/prescription/config/DiagnosisConfig";
import ProcedureConfig from "../../views/prescription/config/ProcedureConfig";
import AdviseConfig from "../../views/prescription/config/AdviseConfig";
import MedicineConfig from "../../views/prescription/config/MedicineConfig";
import OpthoConfig from "../../views/prescription/config/OpthoConfig";
import PatientDetails from "./module/PatientDetails";
import VitalDetails from "./module/VitalDetails";

const PrescriptionModulesList = () => {
  return [
    {
      value: "PATIENT_DETAIL",
      title: "Patient Detail",
      description: "",
      icon: <Icon path={mdiAccountInjuryOutline} size={1.2} />,
      display: true,
      preview: "",
      removable: false,
    },
    {
      value: "VITALS",
      title: "Vitals",
      description: "",
      icon: <Icon path={mdiPrescription} size={1.2} />,
      display: true,
      preview: "",
      removable: true,
    },
    {
      value: "SYMPTOMS",
      title: "Symptoms",
      description: "",
      icon: <Icon path={mdiEmoticonSickOutline} size={1.2} />,
      display: true,
      preview: "",
      removable: true,
    },
    {
      value: "DIAGNOSIS",
      title: "Diagnosis",
      description: "",
      icon: <Icon path={mdiStethoscope} size={1.2} />,
      display: false,
      preview: "",
      removable: true,
    },
    {
      value: "PROCEDURE",
      title: "Procedure/Tests",
      description: "",
      icon: <Icon path={mdiNeedle} size={1.2} />,
      display: false,
      preview: "",
      removable: true,
    },
    {
      value: "ADVISE",
      title: "Advise",
      description: "",
      icon: <Icon path={mdiDoctor} size={1.2} />,
      display: false,
      preview: "",
      removable: true,
    },
    {
      value: "MEDICINE",
      title: "Medicine",
      description: "",
      icon: <Icon path={mdiPillMultiple} size={1.2} />,
      display: true,
      preview: "",
      removable: true,
    },
    {
      value: "SPECTACLE",
      title: "Spectacles",
      description: "Module to add spectacles data into prescription",
      icon: <Icon path={mdiGlasses} size={1.2} />,
      display: false,
      preview: "",
      removable: true,
    },
    {
      value: "DIETICIAN",
      title: "Diet",
      description: "",
      icon: <Icon path={mdiFoodAppleOutline} size={1.2} />,
      display: false,
      preview: "",
      removable: true,
    },
  ];
};

export default PrescriptionModulesList;
