import React, { forwardRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Paper,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import PatientDetailsAutoSuggest from "../../autosuggest/PatientDetailsAutoSuggest";
import DatePicker from "react-datepicker";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import { PATIENT_DETAIL_URL } from "../../../util/ApiUrls";
import Moment from "react-moment";
import {
  getDateFromString,
  getYearAndMonthPassedFromDate,
} from "../../../util/DateTimeUtil";

const CustomDOBInput = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      label="Date of Birth"
      size="small"
      fullWidth
      autoComplete="off"
    />
  );
});

const PatientDetails = () => {
  const [autoSuggestValue, setAutoSuggestValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      ageYear: "",
      ageMonth: "",
      email: "",
      mobile: "",
      abhaNumber: "",
    },
  });
  const axiosProtected = useAxiosProtected();

  const autoFillPatientById = async (value) => {
    console.log(value);
    try {
      await axiosProtected
        .get(PATIENT_DETAIL_URL + value?.itemVal)
        .then((response) => {
          console.log(response);
          const patient = response?.data?.data;
          setValue("patientId", patient?.id, { shouldValidate: true });
          setValue("firstName", patient?.firstName, { shouldValidate: true });
          setValue("lastName", patient?.lastName, { shouldValidate: true });
          setValue("gender", patient?.gender, { shouldValidate: true });

          if (patient?.dob) {
            try {
              const myDate = getDateFromString(patient?.dob, "DD-MMM-YYYY");
              setValue("dob", myDate, { shouldValidate: true });
              updateAgeFromDOB(myDate);
            } catch (err) {
              console.log(err);
            }
          } else {
            setValue("ageYear", patient?.age, { shouldValidate: true });
            setValue("ageMonth", patient?.ageMonth, { shouldValidate: true });
          }
          setValue("email", patient?.email, { shouldValidate: true });
          setValue("mobile", patient?.mobile, { shouldValidate: true });
          setDisabled(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const updateAgeFromDOB = (dt) => {
    if (dt < new Date()) {
      const [year, month] = getYearAndMonthPassedFromDate(dt);
      setValue("ageYear", year, { shouldValidate: true });
      setValue("ageMonth", month, { shouldValidate: true });
    } else {
      setValue("ageYear", "", { shouldValidate: true });
      setValue("ageMonth", "", { shouldValidate: true });
    }
  };

  const clearForm = () => {
    reset();
    setDisabled(false);
  };
  const onSubmit = (data) => console.log(data);
  return (
    <Paper elevation={3} sx={{ p: 5 }}>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Patient Detail:
      </Typography>

      <Divider sx={{ mb: 5 }} />
      <Grid container spacing={5} sx={{ mb: 5 }}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <PatientDetailsAutoSuggest
            suggestValue={autoSuggestValue}
            label="Search patient by name"
            onAddElement={autoFillPatientById}
            resetAfterSelect={true}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Button onClick={clearForm}>Clear</Button>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12} justifyContent="right">
          <Button variant="outlined" onClick={() => {}}>
            Start with ABHA
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 5 }} />
      <Button onClick={handleSubmit(onSubmit)}>button</Button>
      <Grid container spacing={5} sx={{ mb: 5 }}>
        <input type="hidden" defaultValue="" {...register("patientId")} />
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <FormControl fullWidth>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true, maxLength: 30, pattern: /^[A-Za-z]+$/i }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  size="small"
                  fullWidth
                  disabled={disabled}
                  error={errors.firstName ? true : false}
                  helperText={
                    (errors.firstName?.type === "required" &&
                      "First name is required") ||
                    (errors.firstName?.type === "pattern" &&
                      "First name can consist only alphabets") ||
                    (errors.firstName?.type === "maxLength" &&
                      "Max 30 characters allowed")
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <FormControl fullWidth>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true, maxLength: 30, pattern: /^[A-Za-z]+$/i }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.lastName ? true : false}
                  label="Last Name"
                  size="small"
                  disabled={disabled}
                  fullWidth
                  helperText={
                    (errors.lastName?.type === "required" &&
                      "Last name is required") ||
                    (errors.lastName?.type === "pattern" &&
                      "Last name can consist only alphabets") ||
                    (errors.lastName?.type === "maxLength" &&
                      "Max 30 characters allowed")
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Controller
            name="gender"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth error={errors.gender ? true : false}>
                <InputLabel
                  id="patient-gender-select-label"
                  disabled={disabled}
                >
                  Gender
                </InputLabel>
                <Select
                  {...field}
                  labelId="patient-gender-select-label"
                  id="patient-gender-select"
                  label="Gender"
                  size="small"
                  disabled={disabled}
                >
                  <MenuItem value={"M"}>Male</MenuItem>
                  <MenuItem value={"F"}>Female</MenuItem>
                  <MenuItem value={"O"}>Other</MenuItem>
                  <MenuItem value={"D"}>Do not want to mention</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.gender?.type === "required" &&
                    "This field is required"}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Controller
            name="dob"
            control={control}
            rules={{ max: new Date() }}
            render={({ field }) => (
              <FormControl fullWidth error={errors.dob ? true : false}>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  placeholderText="DD-MMM-YYYY"
                  closeOnScroll={true}
                  maxDate={new Date()}
                  customInput={<CustomDOBInput />}
                  id="patient-dob-date"
                  dateFormat="dd-MMM-yyyy"
                  onChange={(date) => {
                    field.onChange(date);
                    updateAgeFromDOB(date);
                  }}
                  selected={field.value}
                />
                <FormHelperText>
                  {errors.dob?.type === "max" &&
                    "Date of birth cannot be greater than todays date"}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          <Grid item lg={6}>
            <Controller
              name="ageYear"
              control={control}
              rules={{ required: true, min: 0, max: 200 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Age(Yrs)"
                  size="small"
                  disabled={disabled}
                  error={errors.ageYear ? true : false}
                  helperText={
                    (errors.ageYear?.type === "required" &&
                      "This field is required") ||
                    (errors.ageYear?.type === "min" &&
                      "Age(years) cannot be less than 0") ||
                    (errors.ageYear?.type === "max" && "Age(years) not correct")
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={6}>
            <Controller
              name="ageMonth"
              control={control}
              rules={{ required: true, min: 0, max: 11 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Age(Month)"
                  size="small"
                  sx={{ ml: 2 }}
                  disabled={disabled}
                  error={errors.ageMonth ? true : false}
                  helperText={
                    (errors.ageMonth?.type === "required" &&
                      "This field is required") ||
                    (errors.ageMonth?.type === "min" &&
                      "Age(month) cannot be less than 0") ||
                    (errors.ageMonth?.type === "max" &&
                      "Age(month) cannot be greater than 11 months")
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <FormControl fullWidth>
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: 100,
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  size="small"
                  type="email"
                  disabled={disabled}
                  fullWidth
                  error={errors.email ? true : false}
                  helperText={
                    (errors.email?.type === "pattern" && "Invalid Email ID") ||
                    (errors.email?.type === "maxLength" && "Email Id too long")
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <FormControl fullWidth>
            <Controller
              name="mobile"
              control={control}
              rules={{ maxLength: 12, pattern: /^\d{10,12}$/i }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Mobile"
                  size="small"
                  disabled={disabled}
                  fullWidth
                  error={errors.mobile ? true : false}
                  helperText={
                    (errors.mobile?.type === "pattern" &&
                      "Invalid phone number") ||
                    (errors.mobile?.type === "maxLength" &&
                      "Phone number too long")
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 5 }} />
      <Grid container spacing={5} justifyContent="right">
        <Button>View Prescription History</Button>
        <Button>View Vitals History</Button>
      </Grid>
    </Paper>
  );
};

export default PatientDetails;
