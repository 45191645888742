import i18next from "i18next";

import { initReactI18next } from "react-i18next";

import HttpBackend from "i18next-http-backend";
// "Inline" English and Arabic translations.

// We can localize to any language and any number of languages.

// const resources = {
//   en: {
//     translation: {
//       app_name: "MyRx",
//     },
//   },

//   ar: {
//     translation: {
//       app_name: "جروتباسكت",
//     },
//   },
// };

i18next

  .use(initReactI18next)
  .use(HttpBackend) // Registering the back-end plugin
  .init({
    // resources,
    ns: ["translation", "common", "login", "abha"],
    defaultNS: "translation",
    lng: "en",
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    interpolation: {
      escapeValue: false,
    },
    //debug: true,
    debug: process.env.NODE_ENV === "development",
  });

export default i18next;
