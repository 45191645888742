import {
  Link as MLink,
  Button,
  Alert,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { MYRX_SITE } from "../../util/MyRxConstants";
import { LOGIN_URL } from "../../util/AppUrls";

import { useForm, Controller } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import ABHACreateComponent from "../../components/abha/abhaCreateComponent";
import LanguageSelector from "../../components/i8n/LanguageSelector";
import { useTranslation } from "react-i18next";

const LinkStyled = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

const CreateABHA = (props) => {
  const [errMsg, setErrMsg] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [aadharValidated, setAadharValidated] = useState(true);
  const [consent, setConsent] = useState(true);
  const { t } = useTranslation(["translation", "abha"]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });
  const onSubmit = (data) => console.log(data);

  return (
    <Grid>
      <Grid container justifyContent={"space-between"} sx={{ px: 20, py: 5 }}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logo width="50" height="50" href={MYRX_SITE} /> &nbsp;
            {/* <Typography
                            variant='h6'
                            sx={{ nl: 3, lineHeight: 1, fontWeight: 600, fontSize: '1.5rem !important' }}>
                            MyRx
                        </Typography> */}
          </Box>
        </Grid>
        <Grid item>
          <Grid>
            <Link to={LOGIN_URL} sx={{ mr: 10 }}>
              <LinkStyled>Login</LinkStyled>
            </Link>
            <LanguageSelector />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errMsg}
        </Alert>
      </Snackbar>
      <Grid container justifyContent={"center"}>
        <Grid container justifyContent={"right"} sx={{ px: 20, py: 5 }}></Grid>
        <Grid
          item
          container
          lg={10}
          md={12}
          sm={12}
          xs={12}
          justifyContent={"space-evenly"}
          alignItems="center"
          paddingX={20}
        >
          <Grid item md={6} xs={12} container direction="column" paddingX={10}>
            <Typography variant="h3">{t("abha:what_is_abha")}</Typography>
            <Typography variant="body">{t("abha:abha_description")}</Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            container
            direction="column"
            paddingX={10}
            alignItems={"top"}
          >
            <ABHACreateComponent />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              mt: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            About ABHA with Image and Text
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          md={6}
          xs={10}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography variant="body2">More explanation on ABHA</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateABHA;
