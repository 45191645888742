import React, { useState } from "react";
import { Box, TextField, Typography, Button, Alert, InputAdornment } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function AbhaAddressCreation({ onSubmit }) {
  const [abhaAddress, setAbhaAddress] = useState("");
  const [error, setError] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const handleChange = (event) => {
    setAbhaAddress(event.target.value.trim());
    setError("");
    setIsVerified(false);
  };

  const validateAbhaAddress = (address) => {
    const addressRegex = /^[a-zA-Z0-9._-]{4,20}$/;
    return addressRegex.test(address);
  };

  const handleVerify = () => {
    if (!validateAbhaAddress(abhaAddress)) {
      setError(
        "ABHA Address must be 4-20 characters long and contain only letters, numbers, dots, underscores, or dashes."
      );
    } else {
      setError("");
      setIsVerified(true);
    }
  };

  const handleSubmit = () => {
    if (isVerified) {
      console.log(`Final ABHA Address: ${abhaAddress}`);
      onSubmit(true);
    } else {
      setError("Please verify the ABHA Address before proceeding.");
    }
  };

  return (
    <Box sx={{ p: 6, display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h6" gutterBottom>
        Create Your Unique ABHA Address
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: "text.secondary" }}>
        ABHA (Ayushman Bharat Health Account) address is a unique username that allows you to share and
        access your health record digitally. It is similar to an email address, but it is only used for health records.<br />
        To create ABHA address, it should have Min - 8 characters, Max - 18 characters, specail character allowed = 1 dot(.)
        and/or 1 underscore(_). 
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <TextField
          label="ABHA Address"
          variant="outlined"
          value={abhaAddress}
          onChange={handleChange}
          error={!!error}
          helperText={error}
          sx={{ flexBasis: "50%" }}
          InputProps={{
            endAdornment: isVerified && (
              <InputAdornment position="end">
                <CheckCircleIcon color="success" />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleVerify}
          sx={{ height: "100%", textTransform: "none" }}
        >
          Verify
        </Button>
      </Box>

      {isVerified && (
        <Alert severity="success" sx={{ mt: 2 }}>
          ABHA Address is verified and available for use.
        </Alert>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!isVerified}
        sx={{ alignSelf: "flex-start", textTransform: "none" }}
      >
        Submit
      </Button>
    </Box>
  );
}
