import { Avatar, Badge } from '@mui/material'
import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { SHOPPING_CART } from '../../../../util/AppUrls'
import Icon from '@mdi/react';
import { mdiCart } from '@mdi/js'

const BadgeContentSpan = styled('span')(({ theme }) => ({
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor:theme.palette.primary.main,
    color: 'white',
    fontWeight:'bold',
    justifyContent:'center',
    textAlign:'center'
  }))

const AppCart = (props) => {

    const [productCount, setProductCount] = useState(0)
 
    useEffect(()=>{
        props?.cart?.getCartFromServer()
    },[])
    useEffect(()=>{
        setProductCount(props?.cart?.items?.reduce((sum, product) => sum + 1, 0))
    },[props?.cart?.items])

    return (
        <Fragment>
            <Link to={SHOPPING_CART}>
                    {/* Cart ({productCount} Plans) */}
                
            <Badge
                overlap='circular'
                sx={{ ml: 2, cursor: 'pointer' }}
             badgeContent={<BadgeContentSpan >{productCount || 0}</BadgeContentSpan>}
             anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Icon path={mdiCart} size={1} />
            </Badge>
            </Link>
        </Fragment>
    )
}

export default AppCart