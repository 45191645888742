export const TEST_URL = "/api/user-auth/test";
export const LOGIN_URL = "/api/user-auth/login";
export const FORGOT_PASSWORD_URL = "/api/user-auth/forgotpassword";
export const LOGIN_URL_GOOGLE = "/api/user-auth/googlelogin";
export const SIGN_UP_URL = "/api/user-auth/signup";
export const SIGN_UP_PCE_MADHAVBAUG_URL = "/api/user-auth/signup/MadhavBaug/PCE";
export const SIGN_UP_DOCTOR_MADHAVBAUG_URL = "/api/user-auth/doctor/MadhavBaug";
export const REFRESH_TOKEN_URL = "/api/user-auth/refresh";
export const LOGOUT_USER_API = "/api/user-auth/logout";
export const REDIRECT_AND_LOGIN_USER_API = "/api/user-auth/redirect?q=";
export const MOBILE_VERIFY_OTP_SEND = "/api/user-auth/mobile/otp/send";
export const MOBILE_VERIFY_OTP_VERIFY = "/api/user-auth/mobile/otp/verify";
export const EMAIL_VERIFY_OTP_SEND = "/api/user-auth/email/otp/send";
export const EMAIL_VERIFY_OTP_VERIFY = "/api/user-auth/email/otp/verify?q=";
export const LOGOUT_USER_STRUTS2 = process.env.REACT_APP_MYRX_SITE + "/logout.action";
export const GET_USER_PROFILE = "/api/users";
export const UPDATE_SIGN_UP_STEP_STATUS = "/api/users/status/";
export const REDIRECT_USER_TO_MONOLITH = "/api/users/redirect/";
export const ROLE_PRIVELEGES_SINGLE = "/api/statics/roles/{0}/privilege";
export const FETCH_ONE_TIME_AUTOSUGGEST = "/api/statics/onetime/suggest/";
export const FETCH_SPECIALIZATION_LIST = "/api/statics/specialization/list/";
export const FETCH_SPECIALIZATION_PARAMS_SUGGEST =
  "/api/statics/specialization/{0}/detail/{1}";
export const UPDATE_FIRST_TIME_PROFILE = "/api/doctor/firsttime/updateprofile";
export const UPDATE_DOCTOR_CITY = "/api/doctor/city/";
export const FETCH_SYMPTOMS_AUTOSUGGEST = "/api/prescription/suggest/symptom/";
export const FETCH_PROCEDURE_AUTOSUGGEST =
  "/api/prescription/suggest/procedure/";
export const FETCH_ADVISE_AUTOSUGGEST = "/api/prescription/suggest/advise/";
export const FETCH_DIAGNOSIS_AUTOSUGGEST =
  "/api/prescription/suggest/diagnosis/";
export const FETCH_MEDICINE_AUTOSUGGEST = "/api/prescription/suggest/medicine/";
export const FETCH_DOCTOR_SYMPTOMS_CONFIG = "/api/prescription/symptom/quick";
export const ADD_DOCTOR_SYMPTOMS_CONFIG = "/api/prescription/symptom/add";
export const DELETE_DOCTOR_SYMPTOMS_CONFIG = "/api/prescription/symptom/";
export const FETCH_DOCTOR_PROCEDURES_CONFIG =
  "/api/prescription/procedure/quick";
export const ADD_DOCTOR_PROCEDURES_CONFIG = "/api/prescription/procedure/add";
export const DELETE_DOCTOR_PROCEDURES_CONFIG = "/api/prescription/procedure/";
export const FETCH_DOCTOR_ADVISE_CONFIG = "/api/prescription/advise/quick";
export const ADD_DOCTOR_ADVISE_CONFIG = "/api/prescription/advise/add";
export const DELETE_DOCTOR_ADVISE_CONFIG = "/api/prescription/advise/";
export const FETCH_DOCTOR_DIAGNOSIS_CONFIG =
  "/api/prescription/diagnosis/quick";
export const ADD_DOCTOR_DIAGNOSIS_CONFIG = "/api/prescription/diagnosis/add";
export const DELETE_DOCTOR_DIAGNOSIS_CONFIG = "/api/prescription/diagnosis/";
export const FETCH_DOCTOR_MEDICINE_CONFIG = "/api/prescription/medicine/quick";
export const ADD_DOCTOR_MEDICINE_CONFIG = "/api/prescription/medicine/add";
export const DELETE_DOCTOR_MEDICINE_CONFIG = "/api/prescription/medicine/";
export const PRESCRIPTION_VITAL_CONFIG = "/api/prescription/config/vitals";
export const FETCH_FIRST_TIME_PRESCRIPTION_TOUR =
  "/api/prescription/redirect/firstprescription";
export const FETCH_ALL_SUBSCRIPTION_PLANS = "/api/subscription/plans/list";
export const FETCH_SUBSCRIPTION_PLANS =
  "/api/subscription/plans/modulename/list";
export const FETCH_MY_SUBSCRIBED_PLANS =
  "/api/subscription/plan/subscribed/list";
export const GET_SHOPPING_CART = "/api/shopping/cart";
export const ADD_PLAN_TO_CART = "/api/shopping/cart/plan/";
export const PLAN_IN_CART_PLANID = "/api/shopping/cart/plan/planId/";
export const CREATE_NEW_ORDER_FROM_CART = "/api/order/create";
export const GET_ORDER_STATUS_BY_PROCESS_ID = "/api/order/status/process/";
export const GET_PAYMENT_REQUEST_BY_PROCESS_ID = "/api/order/payment/order/";
export const UPDATE_ORDER_PAYMENT_STATUS = "/api/order/payment/confirm";
export const FETCH_ORDERS_LIST = "/api/order/placed";

export const FETCH_PATIENT_AUTOSUGGEST = "/api/patients/suggest/";
export const PATIENT_DETAIL_URL = "/api/patients/";
export const RECENT_CHATS_LIST = "/api/chats/fetch-chats";
export const GET_CHAT_HISTORY_MESSAGES = "/api/chats/fetch-messages?to={0}";
export const SEND_CHAT_MESSAGE = "/api/chats/send-message";

//Catalog Based APIs
export const CATEGORY_LIST_AUTOSUGGEST = "/api/category/suggest/";

//Product Based APIs
export const PRODUCT_LIST_SEARCH = "/api/product/list/";
export const SERVICES_PRODUCT_LIST_SEARCH = "/api/services/list/";

//ABHA related APIs
export const ABHA_AADHAR_VERIFY = "/abha/aadhar/mobile/request";

//Clinic related apis
export const FETCH_COMPANY_CLINICS_AUTOSUGGEST = "/api/clinic/suggest/MadhavBaug/clinic/";