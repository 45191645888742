import useRedirectToMonolith from "../hooks/useRedirectToMonolith";

export const HOME_PAGE = "/";
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const USER_SIGN_UP = "/register";
export const MADHAVBAUG_PCE_SIGN_UP = "/pce/register";
export const MADHAVBAUG_DOCTOR_SIGN_UP = "/doctor/register";
export const USER_VERIFY_EMAIL = "/verify/email";
export const USER_VERIFY_PHONE = "/verify/phone";
export const USER_EMAIL_VERIFY_CONFIRM = "/verify/email/confirm";
export const PROFILE_UPDATE = "/updateprofile";
export const USER_PROFILE_UPDATE = "/profile";
export const USER_GET_STARTED = "/get-started";
export const BROWSE_PLANS = "/browse-plans";
export const SHOPPING_CART = "/cart";
export const SHOPPING_CHECKOUT = "/checkout";
export const ORDER_PAYMENT_CONFIRM = "/orderconfirmation";
export const VIEW_MY_PLANS = "/my-plans";
export const VIEW_MY_ORDERS = "/my-orders";
export const REDIRECT_USER = "/redirectuser";
export const PRESCRIPTION_CONFIG_USER = "/config/prescription";
export const CREATE_ABHA_NUMBER = "/ABHA";
export const CREATE_ABHA1_NUMBER = "/ABHA1";
export const PRESCRIPTION_WRITE = "/prescription/write";
export const CONFIG_PLANS = "/config-plans";
export const PATIENT_QUERIES = "/queries";
export const CATALOG_VIEW_PLANS = "/viewcareplans";
export const CATALOG_ADD_PLANS = "/addcareplans";

const useNextPageRedirect = () => {
  const redirect = useRedirectToMonolith();
  const redirectTo = (path) => {
    if (path === "REDIRECT_HOME") {
      redirect(path);
    } else if (path === "UPDATE_PROFILE") {
      return PROFILE_UPDATE;
    } else if (path === "UPDATE_PATIENT") {
      return USER_GET_STARTED;
    } else if (path === "CONFIG_SYMPTOMS") {
      return USER_GET_STARTED;
    } else if (path === "CONFIG_DIAGNOSIS") {
      return USER_GET_STARTED;
    } else if (path === "CONFIG_PROCEDURES") {
      return USER_GET_STARTED;
    } else if (path === "CONFIG_ADVISE") {
      return USER_GET_STARTED;
    } else if (path === "CONFIG_PRESC") {
      return USER_GET_STARTED;
    } else if (path === "MYRX_TOUR") {
      redirect(path);
    } else {
      redirect(path);
    }
  };
  return redirectTo;
};

export default useNextPageRedirect;
