import { Button, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../../../context/ShoppingCartProvider'
import useAxiosProtected from '../../../hooks/useAxiosProtected'
import { CREATE_NEW_ORDER_FROM_CART, GET_ORDER_STATUS_BY_PROCESS_ID, GET_PAYMENT_REQUEST_BY_PROCESS_ID } from '../../../util/ApiUrls'
import { BROWSE_PLANS } from '../../../util/AppUrls'

const CheckOut = () => {

    const axiosProtected = useAxiosProtected()
    const cart = useContext(CartContext)
    const [inProgress, setInProgress] = useState(false)
    const [orderDetails, setOrderDetails] = useState()

    let intervalCheck;


    const checkForOrderStatus = async () => {
        try {
            await axiosProtected.get(
                GET_ORDER_STATUS_BY_PROCESS_ID + orderDetails?.processId,
            ).then(response => {
                console.log(response);
                if (response?.data === 'ORDERED' || response?.data === 'FINALIZED') {
                    clearOrderChecK()
                    redirectToPaymentPage()
                }
            }).catch(err => {
                console.log(err)
                setInProgress(false)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const redirectToPaymentPage = async () => {
        try {
            await axiosProtected.get(
                GET_PAYMENT_REQUEST_BY_PROCESS_ID + orderDetails?.processId,
            ).then(response => {
                console.log(response);
                if (response?.data?.redirect) {
                    window.location.href = response?.data?.redirect
                }
            }).catch(err => {
            })
        } catch (err) {
            console.log(err)
        }
    }

    const clearOrderChecK = () => {
        clearInterval(intervalCheck)
    }

    useEffect(() => {
        cart.getCartFromServer()
    }, [])

    useEffect(() => {
        if (inProgress && orderDetails?.processId) {
            intervalCheck = setInterval(checkForOrderStatus, 1000)
            setTimeout(clearOrderChecK, 30000)
        }
    }, [orderDetails])


    const startOrderCreation = async () => {
        setInProgress(true)
        try {
            await axiosProtected.post(
                CREATE_NEW_ORDER_FROM_CART,
            ).then(response => {
                console.log(response)
                setOrderDetails(response?.data)
            }).catch(err => {
                console.log(err)
                setInProgress(false)
            })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {!inProgress ? (
                <Grid container padding={5}>
                    {cart.getTotalAmount() > 0 ?
                        <Grid container marginBottom={10}>
                            <ul class="price-confirm">
                                <li> Payment of &#8377; <b>{cart.getTotalAmount()}</b> now required to complete the purchase.
                                </li>
                                <li> To complete the payment click on the <b>Make Payment</b> button.
                                </li>
                                <li> We will send you an payment invoice once payment has been received.</li>
                            </ul>
                        </Grid>
                        :
                        <>
                            <Grid container marginBottom={10}>
                                <Typography variant="body2" color="text.secondary" marginBottom={5}>
                                    To complete click on the <b>Make Payment</b> button.
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid container spacing={4} marginBottom={10} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Link to={BROWSE_PLANS}>
                                <Button variant='outlined'>Back to plans</Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' onClick={startOrderCreation}>{cart.getTotalAmount() > 0 ? 'Make Payment' : 'Continue'}</Button>
                        </Grid>
                    </Grid>
                </Grid>) : (
                <Grid container direction={'column'}>
                    <Typography variant="body2" color="text.secondary" marginBottom={5}>
                        Your order is being created please wait while we redirect you to payment page<br />
                    </Typography>
                    <LinearProgress />
                </Grid>

            )}

        </>
    )
}

export default CheckOut